import React from 'react';
import { Heading as PostmasterHeader } from '@auspost/postmaster-react';

interface Props {
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'hxl' | 'hl';
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  tabIndex?: number;
  id?: string;
}

const Heading: React.FC<Props> = ({ level, as, id, children }): React.ReactElement => {
  return (
    <PostmasterHeader level={level} as={as} id={id}>
      {children}
    </PostmasterHeader>
  );
};

export default Heading;
