import { FeatureToggle } from '../../../../pvoi-components/src/models/featureToggle.model';
import featureToggleService from '../services/featureToggleService/featureToggleService';
import { ProfileOrganisationType } from '../services/profileService/types';
import {
  AppStateModel,
  CLM_ROLE,
  DateRangeValue,
  SelectedClmType,
  InvitationDetails,
  Status,
  tableConfig
} from '@auspost/pvoi-components';
import { OutageContent } from '@auspost/pvoi-components/src/models/appState.model';
import { AppType } from '../constants/type.enum';

export class AppState implements AppStateModel {
  readonly maxRecordLimit = 200;
  featureToggles: Array<FeatureToggle> = [];

  // Search
  searchTerm = '';
  isSearchApplied = false;
  searchDateRange: DateRangeValue = {
    from: null,
    to: null
  };
  searchStatus: Status = null;
  numOfDisplayedReports = tableConfig.options.pageSize;
  numOfSearchMatches = 0;

  // Company Dropdown
  organisations: ProfileOrganisationType[] = [];
  selectedClm: SelectedClmType = {
    id: '',
    clmName: '',
    userName: '',
    isAdmin: false,
    isIDV: false,
    defaultClientId: ''
  };

  invitationDetails: InvitationDetails = {
    surname: '',
    firstName: '',
    mobileNumber: '',
    email: ''
  };

  error: OutageContent = {
    header: '',
    content: ''
  };

  isTablet = false;

  type: AppType;
  isIDV: boolean;

  async initFeatureToggle(): Promise<void> {
    this.featureToggles = await featureToggleService();
  }

  isFeatureToggleActive(id: string): boolean {
    return !!this.featureToggles.find((feature: FeatureToggle) => {
      return feature.id === id && feature.active;
    });
  }

  onClmSelect(clmItem: string, callBack?: () => void): void {
    const clmId = clmItem.split(' - ')[1];

    if (this.selectedClm.id !== clmId) {
      this.setSelectedClm(clmId);
      this.resetSearch();
      callBack && callBack();
    }
  }

  setSelectedClm(id: string): void {
    const clm = this.organisations.find(item => item.clmId === id);
    this.selectedClm = {
      id: id,
      clmName: clm.organisationName,
      userName: !clm.givenName || !clm.givenName.trim() ? clm.lastName : clm.givenName,
      isAdmin: clm.role === CLM_ROLE.ADMIN,
      isIDV: clm.isIDV,
      defaultClientId: clm.defaultClientId
    };
    this.type = this.selectedClm.isIDV ? AppType.IDV : AppType.PVOI;
    this.isIDV = this.type == AppType.IDV;
  }

  resetSelectedClm(): void {
    this.selectedClm = {
      id: '',
      clmName: '',
      userName: '',
      isAdmin: false,
      isIDV: false,
      defaultClientId: ''
    };
    this.type = this.selectedClm.isIDV ? AppType.IDV : AppType.PVOI;
  }

  setSearch(
    searchTerm: string,
    searchStatus: Status,
    searchDateRange: DateRangeValue,
    isSearchApplied: boolean,
    numOfDisplayedReports: number,
    numOfSearchMatches: number
  ): void {
    this.searchTerm = searchTerm;
    this.searchStatus = searchStatus;
    this.searchDateRange = searchDateRange;
    this.isSearchApplied = isSearchApplied;
    this.numOfDisplayedReports = numOfDisplayedReports;
    this.numOfSearchMatches = numOfSearchMatches;
  }

  resetSearch(): void {
    this.setSearch('', null, { from: null, to: null }, false, tableConfig.options.pageSize, 0);
  }

  reset(): void {
    this.resetSearch();
    this.resetSelectedClm();
  }
}
