import React from 'react';
import { Page } from '../../components';
import { HOME_MENU_ITEMS } from '../Home/constants';
import * as Styled from './styled';
import { Heading, Card } from '@auspost/pvoi-components';
import { UserGuides } from './Cards/UserGuides';
import { FAQs } from './Cards/FAQs';
import { Locations } from './Cards/Locations';
import { ContactUs } from './Cards/ContactUs';
import { analyticsService, windowService } from '../../services';
import { PAGE_LOAD_ANALYTICS } from '../../constants/analytics';

const Help: React.FC = (): React.ReactElement => {
  React.useEffect(() => {
    windowService.scrollToTop(true);
    analyticsService.trackPage(PAGE_LOAD_ANALYTICS.HELP_CENTRE.CATEGORY, PAGE_LOAD_ANALYTICS.HELP_CENTRE.DESCRIPTION);
  }, []);

  return (
    <Page menuItems={HOME_MENU_ITEMS()} showMenu={true}>
      <Styled.Help>
        <Styled.Cards>
          <Heading level="h1">Help Centre</Heading>

          <Card paddingVertical="large">
            <UserGuides />
          </Card>
          <Card paddingVertical="large">
            <FAQs />
          </Card>
          <Card paddingVertical="large">
            <Locations />
          </Card>
          <Card paddingVertical="large">
            <ContactUs />
          </Card>
        </Styled.Cards>
      </Styled.Help>
    </Page>
  );
};

export default Help;
