import React from 'react';
import { DomService, Dropdown, HeaderDropdownButton, LinkNoStyles } from '@auspost/pvoi-components';
import { analyticsService } from '../../../services';
import * as Styled from '../styled';
import { envConfig } from '../../../config/env.config';
import { CLICK_ANALYTICS } from '../../../constants/analytics';
import { ClmListItem } from '../types';

interface Props {
  id: string;
  clmListItem: ClmListItem;
}

const CompanyDetailsDropdown: React.FC<Props> = ({ id, clmListItem }): React.ReactElement => {
  return (
    <Styled.Dropdown>
      <Dropdown
        id={id}
        hasTriangleTip={true}
        renderButton={(props, isClicked) => {
          return (
            <HeaderDropdownButton {...props} isClicked={isClicked}>
              <Styled.Span>{`${DomService.truncateText(clmListItem.clmName, 42)} - ${clmListItem.clmId}`}</Styled.Span>
            </HeaderDropdownButton>
          );
        }}
        renderListItems={onListItemClick => [
          {
            id: 'manageUsersLink',
            element: (
              <LinkNoStyles
                id="manageUsers-link"
                isExternal={true}
                href={envConfig.ui.merchantPortal}
                target="_blank"
                onClick={() => {
                  analyticsService.trackClick(
                    CLICK_ANALYTICS.CATEGORY.HEADER,
                    CLICK_ANALYTICS.INTERACTION.HEADER.MANAGE_USERS
                  );

                  onListItemClick();
                }}
              >
                Manage users
              </LinkNoStyles>
            )
          }
        ]}
      />
    </Styled.Dropdown>
  );
};

export default CompanyDetailsDropdown;
