import React from 'react';

const ErrorIcon: React.FC = (): React.ReactElement => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99967 10C8.99967 10.553 8.55267 11 7.99967 11C7.44667 11 6.99967 10.553 6.99967 10V6C6.99967 5.447 7.44667 5 7.99967 5C8.55267 5 8.99967 5.447 8.99967 6V10ZM6.97267 13C6.97267 12.447 7.41967 12 7.97267 12C8.52467 12 8.97267 12.447 8.97267 13C8.97267 13.553 8.52467 14 7.97267 14C7.41967 14 6.97267 13.553 6.97267 13ZM8.97267 1C8.97267 1 8.53467 0 7.97267 0C7.40967 0 6.97267 1 6.97267 1L0.222667 14.5C-0.215333 15.375 -0.0283332 16 0.972667 16H14.9727C15.9727 16 16.1907 15.422 15.7227 14.5C15.3137 13.697 8.97267 1 8.97267 1Z"
        fill="#BD152E"
      />
    </svg>
  );
};

export default ErrorIcon;
