import React from 'react';
import * as Styled from './styled';
import { CaretIcon } from '../icons/CaretIcon';
import { Button } from '../Button/Button';

interface Props {
  id: string;
  label: string | React.ReactNode;
  icon?: React.ReactElement;
  onClick?: (event) => void;
  isOpen: boolean;
  hasValue: boolean;
  ariaLabel?: string;
  ariaHasPopup?: boolean | 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';
  ref?: React.MutableRefObject<HTMLButtonElement>;
}

const ButtonCustom: React.FC<Props> = ({
  id,
  label,
  icon,
  onClick,
  isOpen,
  hasValue,
  ariaLabel,
  ariaHasPopup
}): React.ReactElement => {
  return (
    <Button
      id={id}
      variant="secondary"
      size="xsmall"
      onClick={onClick}
      style={Styled.getButtonCustomStyle(isOpen || hasValue)}
      aria-expanded={isOpen}
      aria-haspopup={ariaHasPopup}
      type="button"
      aria-label={ariaLabel}
    >
      <Styled.Label selected={!isOpen && !hasValue}>
        <span>
          {icon}
          <span>{label}</span>
        </span>
        <CaretIcon transform={isOpen ? 'rotate(180deg)' : ''} />
      </Styled.Label>
    </Button>
  );
};

export default ButtonCustom;
