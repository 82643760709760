import React from 'react';
import * as Styled from './styled';
import Header from '../Header/Header';
import { Menu, Sidebar, MenuItem } from '../../components';
import { Body } from '@auspost/pvoi-components';
import { useAppContext } from '../../context';

interface Props {
  onClmSelectCallBack?: () => void;
  menuItems?: MenuItem[];
  showMenu: boolean;
  showProfile?: boolean;
  showProfileLogoutOnly?: boolean;
  showHeaderMenuItems?: boolean;
}

const Page: React.FC<Props> = ({
  children,
  onClmSelectCallBack,
  showProfile = true,
  showProfileLogoutOnly,
  menuItems,
  showMenu,
  showHeaderMenuItems = true
}): React.ReactElement => {
  const appState = useAppContext();
  return (
    <Styled.Container>
      <Header
        onClmSelectCallBack={onClmSelectCallBack}
        showProfile={showProfile}
        showProfileLogoutOnly={showProfileLogoutOnly}
        showMenuItems={showHeaderMenuItems}
      />
      <Body>
        <Styled.Content>
          {showMenu && (
            <Styled.Menu>
              <Sidebar>
                <Menu items={menuItems} appState={appState} />
              </Sidebar>
            </Styled.Menu>
          )}
          {children}
        </Styled.Content>
      </Body>
    </Styled.Container>
  );
};

export default Page;
