import React from 'react';
import { theme } from '../../theme/theme';

interface Props {
  transform?: string;
  fill?: string;
}

const SendIcon: React.FC<Props> = ({ transform, fill = theme.color.ash }): React.ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: transform }}
      focusable="false"
      aria-hidden="true"
    >
      <path
        d="M0.00417387 9.71359C-0.0329919 10.1279 0.180152 10.517 0.559403 10.7417L8.80222 15.189L13.2392 23.4303C13.4456 23.8012 13.8603 24.0331 14.2719 23.9961C14.5641 23.9553 14.772 23.8629 14.946 23.6887C15.0091 23.6255 15.0586 23.5577 15.102 23.4773C15.1164 23.4506 15.1299 23.4231 15.1624 23.3555L23.9241 1.4739C24.0809 1.07701 23.9908 0.624052 23.6908 0.323826C23.3881 0.0207252 22.9129 -0.0842661 22.5222 0.07166L0.681206 8.80621C0.301966 8.95603 0.0408505 9.30477 0.00417387 9.71359ZM9.40514 13.0651L3.62699 9.9486L20.9787 2.99994L14.0381 20.3687L10.924 14.5854L15.5757 9.92884C15.9941 9.51002 15.994 8.82727 15.5757 8.40849C15.1573 7.98972 14.4753 7.98967 14.0569 8.40849L9.40514 13.0651Z"
        fill={fill}
      />
    </svg>
  );
};

export default SendIcon;
