import { EnvConfig } from './typed';

export const localhostEnvConfig: EnvConfig = {
  baseUrl: '/',
  api: {
    apiBaseUrl: 'http://localhost:4000',
    digitalApiBaseUrl: 'http://localhost:4000'
  },
  ui: {
    loginUrl: '/auth/login',
    featureTogglesUrl: 'http://localhost:4000/featuretoggles/identity-services/features.json',
    glamUrl: 'http://localhost:4000',
    merchantPortal: 'http://localhost:4000'
  },
  pdf: {
    userGuide: 'https://pvoi-document-pdev.s3.ap-southeast-2.amazonaws.com/Property+VOI+Report+User+Guide.pdf',
    diyUserGuide: 'https://pvoi-document-pdev.s3.ap-southeast-2.amazonaws.com/Do+It+Yourself+VOI+Tool+User+Guide.pdf',
    searchTips: 'https://pvoi-document-pdev.s3.ap-southeast-2.amazonaws.com/Viewing+and+Searching+for+VOI+Reports.pdf'
  }
};
