import styled from '@emotion/styled';
import { theme } from '../../theme/theme';

const triangleTip = {
  position: 'absolute',
  right: '30px',
  top: '-10px',
  width: 0,
  height: 0,
  content: '""',
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  borderBottom: `10px solid ${theme.color.white}`
};

export const Container = styled.div({
  position: 'relative',
  display: 'inline-block',
  color: theme.color.ink
});

export const ListContainer = styled.ul<{ width: string; hasTriangleTip: boolean }>(
  ({ width, hasTriangleTip = false }) => ({
    width: width,
    padding: '8px 0',
    boxShadow: theme.shadow.shadow5,
    backgroundColor: theme.color.white,

    position: 'absolute',
    marginTop: hasTriangleTip ? '16px' : '4px',
    listStyleType: 'none',
    zIndex: 1,
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    right: hasTriangleTip ? '-10px' : 0,

    ':after': hasTriangleTip ? triangleTip : {}
  })
);

export const ListItem = styled.li<{ isSelected?: boolean }>(({ isSelected }) => ({
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.small,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  listStyleType: 'none',
  padding: '6px 16px',
  cursor: 'pointer',
  fontWeight: isSelected ? theme.fontWeight.medium : theme.fontWeight.regular,
  lineHeight: '20px',

  '&:hover': {
    backgroundColor: theme.color.shell
  },

  svg: {
    marginLeft: '12px',
    paddingBottom: '3px'
  },

  '&:focus-visible': {
    outline: `1px dotted ${theme.color.slate}`,
    outlineOffset: '2px'
  }
}));

export const OtherSection = styled.li({
  hr: {
    marginTop: '8px',
    marginBottom: '8px'
  },
  a: {
    '&:hover, &:focus': {
      backgroundColor: theme.color.shell
    }
  }
});
