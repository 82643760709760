import React from 'react';

const CloseIcon: React.FC = (): React.ReactElement => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5321 0L7.99891 6.5322L1.46671 0L0 1.46775L6.53116 8.00099L0 14.5321L1.46671 15.9999L7.99891 9.46873L14.5321 15.9999L15.9999 14.5321L9.4677 8.00099L15.9999 1.46775L14.5321 0Z"
      fill="#31313D"
    />
  </svg>
);

export default CloseIcon;
