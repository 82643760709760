import React from 'react';
import * as Styled from './styled';
import CloseIcon from '../../icons/CloseIcon';
import { analyticsService } from 'pvoi-ui/src/services';
import { useAppContext } from 'pvoi-ui/src/context';
import { CLICK_ANALYTICS } from 'pvoi-ui/src/constants/analytics';
import Modal from '../../Modal/Modal';
import Heading from '../../Heading/Heading';
import ButtonNoStyles from '../../ButtonNoStyles/ButtonNoStyles';
import Divider from '../../Divider/Divider';
import { Button } from '../../Button/Button';
import PostmasterInput from '../../PostmasterInput/PostmasterInput';
import Notification from '../../Notification/Notification';
import { ReportsService } from '../../../services/reportsService/reportsService';

interface Props {
  isModalOpen: boolean;
  onCloseModal: () => void;
  numberOfDisplayedReports: number;
  numberOfSearchMatches: number;
  reportsService: ReportsService;
}

enum RadioOptions {
  searchMatches = 'searchMatches',
  shown = 'shown'
}

const ExportModal: React.FC<Props> = ({
  isModalOpen,
  onCloseModal,
  numberOfSearchMatches,
  numberOfDisplayedReports,
  reportsService
}): React.ReactElement => {
  const appState = useAppContext();

  const radioButtonConfig = {
    shown: {
      option: RadioOptions.shown,
      limitParam: numberOfDisplayedReports
    },
    searchMatches: {
      option: RadioOptions.searchMatches,
      limitParam: numberOfSearchMatches
    }
  };

  const [hasExportError, setExportError] = React.useState<boolean>(false);
  const [isExportButtonLoading, setExportButtonLoading] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = React.useState<{ option: RadioOptions; limitParam: number }>(
    radioButtonConfig.searchMatches
  );

  React.useEffect(() => {
    return () => {
      setExportError(false);
      setExportButtonLoading(false);
      setSelectedOption(radioButtonConfig.searchMatches);
    };
  }, []);

  // TODO unable to override Postmaster modal styling - to be reviewed later
  React.useLayoutEffect(() => {
    if (isModalOpen) {
      (document.getElementsByClassName('exportModal')[0] as HTMLDivElement).style.minWidth = '512px';
    }
  }, [isModalOpen]);

  React.useEffect(() => {
    setExportError(false);
    setExportButtonLoading(false);
    setSelectedOption(radioButtonConfig.searchMatches);
  }, [numberOfDisplayedReports, numberOfSearchMatches]);

  const onRadioButtonClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const radioButtonValue = event.currentTarget.value as RadioOptions;
    setSelectedOption(radioButtonConfig[radioButtonValue]);
  };

  const onModalClose = (): void => {
    onCloseModal();
    setExportError(false);
  };

  const onCancelButtonClick = (): void => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.EXPORT_MODAL, CLICK_ANALYTICS.INTERACTION.EXPORT_MODAL.CANCEL);
    onModalClose();
  };

  const onCloseButtonClick = (): void => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.EXPORT_MODAL, CLICK_ANALYTICS.INTERACTION.EXPORT_MODAL.CLOSE);
    onModalClose();
  };

  const onExportButtonClick = (): void => {
    if (isExportButtonLoading) {
      return;
    }

    setExportError(false);
    setExportButtonLoading(true);
    reportsService
      .exportReports(
        appState.selectedClm.id,
        appState.searchTerm,
        appState.searchDateRange.from,
        appState.searchDateRange.to,
        appState.searchStatus,
        selectedOption.limitParam
      )
      .then(onModalClose)
      .catch(() => {
        setExportError(true);
      })
      .finally(() => {
        setExportButtonLoading(false);
        analyticsService.trackClick(
          CLICK_ANALYTICS.CATEGORY.EXPORT_MODAL,
          CLICK_ANALYTICS.INTERACTION.EXPORT_MODAL.EXPORT
        );
      });
  };

  return (
    <Modal
      isModalShowing={isModalOpen}
      ariaDialogLabel="export reports pop up"
      onHide={onModalClose}
      modalClassName="exportModal"
    >
      <Styled.ModalContent>
        <Styled.TopContent>
          <Heading level="h4">Export VOI report list</Heading>
          <ButtonNoStyles id="closeModal" onClick={onCloseButtonClick} aria-label="Close modal">
            <CloseIcon />
          </ButtonNoStyles>
        </Styled.TopContent>
        <Divider />
        {hasExportError ? (
          <>
            <Notification
              type="alert"
              status="error"
              message={
                <Styled.NotificationText>Unable to export report list. Please try again later.</Styled.NotificationText>
              }
              isDismissible={false}
            />
            <Divider />
            <Styled.ButtonGroup>
              <Button id="modalCancel" variant="secondary" size="xsmall" type="button" onClick={onCancelButtonClick}>
                Cancel
              </Button>
            </Styled.ButtonGroup>
          </>
        ) : (
          <>
            <Notification
              type="notice"
              status="info"
              message={
                <Styled.NotificationText>
                  This will export a list of VOI reports as a <span>.xlsx</span> file
                </Styled.NotificationText>
              }
              isDismissible={false}
            />
            <Styled.RadioGroup role="radiogroup">
              <Styled.RadioTitle>Choose which reports to export</Styled.RadioTitle>
              <Styled.RadioButton>
                <PostmasterInput
                  id="searchMatchRadio"
                  name="exportRadio"
                  type="radio"
                  value={RadioOptions.searchMatches}
                  checked={selectedOption.option === RadioOptions.searchMatches}
                  onChange={onRadioButtonClick}
                />
                <Styled.RadioButtonLabel htmlFor="searchMatchRadio">
                  Search matches ({numberOfSearchMatches})
                </Styled.RadioButtonLabel>
              </Styled.RadioButton>
              <Styled.RadioButton>
                <PostmasterInput
                  id="shownRadio"
                  name="exportRadio"
                  type="radio"
                  value={RadioOptions.shown}
                  checked={selectedOption.option === RadioOptions.shown}
                  onChange={onRadioButtonClick}
                />
                <Styled.RadioButtonLabel htmlFor="shownRadio">
                  Shown ({numberOfDisplayedReports})
                </Styled.RadioButtonLabel>
              </Styled.RadioButton>
            </Styled.RadioGroup>
            <Divider />
            <Styled.ButtonGroup>
              <Button id="modalCancel" variant="secondary" size="xsmall" type="button" onClick={onCancelButtonClick}>
                Cancel
              </Button>
              <Button
                id="modalExport"
                variant="primary"
                size="xsmall"
                type="button"
                onClick={onExportButtonClick}
                isLoading={isExportButtonLoading}
              >
                Export
              </Button>
            </Styled.ButtonGroup>
          </>
        )}
      </Styled.ModalContent>
    </Modal>
  );
};

export default ExportModal;
