import React from 'react';
import * as Styled from './styled';
import {
  Modal,
  CloseIcon,
  Heading,
  ButtonNoStyles,
  Notification,
  Divider,
  Button,
  PostmasterInputField as InputField,
  Stack
} from '@auspost/pvoi-components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FORM_VALIDATION, ERRORS, LABELS } from './constants';
import { MetadataItem } from '../ReportDetails/types';

type Inputs = {
  surname: string;
  givenNames: string;
  phoneNumber: string;
};

interface Props {
  isModalOpen: boolean;
  onCloseModal: () => void;
  onSave: (data) => Promise<MetadataItem[]>;
  items: MetadataItem[];
  onCancel: () => void;
  onCloseButtonClick: () => void;
}

const EditMetaData: React.FC<Props> = ({
  isModalOpen,
  onCloseModal,
  onSave,
  items,
  onCancel,
  onCloseButtonClick
}): React.ReactElement => {
  const getMetaData = (items: MetadataItem[]) => {
    const [surname, givenNames] = items[0].value.split(', ');
    const phoneNumber = items[1].value.replace(/\s/g, '');

    return {
      surname: surname,
      givenNames: givenNames,
      phoneNumber: phoneNumber
    };
  };

  const [hasMetaDataError, setMetaDataError] = React.useState<boolean>(false);
  const [isSaveButtonLoading, setSaveButtonLoading] = React.useState<boolean>(false);
  const nameMaxLength = 120;

  const { surname, givenNames, phoneNumber } = getMetaData(items);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<Inputs>({
    mode: 'onBlur',
    defaultValues: { givenNames, surname, phoneNumber }
  });

  React.useEffect(() => {
    return () => {
      setMetaDataError(false);
      setSaveButtonLoading(false);
    };
  }, []);

  React.useLayoutEffect(() => {
    if (isModalOpen) {
      (document.getElementsByClassName('editMetaModal')[0] as HTMLDivElement).style.minWidth = '512px';
    }
  }, [isModalOpen]);

  React.useEffect(() => {
    setMetaDataError(false);
    setSaveButtonLoading(false);
  }, []);

  const onModalClose = (): void => {
    reset();
    onCloseModal();
    setMetaDataError(false);
  };

  const onCancelButtonClick = (): void => {
    onCancel();
    onModalClose();
  };

  const onCloseIconClick = (): void => {
    onCloseButtonClick();
    onModalClose();
  };

  const mapPatchReportData = data => {
    return {
      given_name: data.givenNames,
      last_name: data.surname,
      phone_number: data.phoneNumber
    };
  };

  const onSubmit: SubmitHandler<Inputs> = data => {
    setMetaDataError(false);
    setSaveButtonLoading(true);
    onSave(mapPatchReportData(data))
      .then((response: MetadataItem[]) => {
        onModalClose();
        const { surname, givenNames, phoneNumber } = getMetaData(response);
        reset({ givenNames, surname, phoneNumber });
      })
      .catch(() => setMetaDataError(true))
      .finally(() => setSaveButtonLoading(false));
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    //Mask non numbers
    event.target.value = value.replace(/[^a-zA-Z '-]/g, '');
  };

  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    //Mask numbers
    event.target.value = value.replace(/[^+0-9]/g, '');
  };

  return (
    <Modal
      isModalShowing={isModalOpen}
      ariaDialogLabel="edit metadata modal pop up"
      onHide={onModalClose}
      modalClassName="editMetaModal"
    >
      <Styled.ModalContent>
        <Styled.TopContent>
          <Heading level="h4">{LABELS.HEADING}</Heading>
          <ButtonNoStyles id="closeModal" onClick={onCloseIconClick} aria-label="Close modal">
            <CloseIcon />
          </ButtonNoStyles>
        </Styled.TopContent>
        <Divider />
        {hasMetaDataError ? (
          <>
            <Notification
              type="alert"
              status="error"
              message={<Styled.NotificationText>{ERRORS.SAVE}</Styled.NotificationText>}
              isDismissible={false}
            />
            <Divider />
            <Styled.ButtonGroup>
              <Button id="modalCancel" variant="secondary" size="xsmall" type="button" onClick={onCancelButtonClick}>
                Cancel
              </Button>
            </Styled.ButtonGroup>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack space="large">
                <InputField
                  required
                  label="Given names"
                  name="givenNames"
                  id="givenNamesInput-input"
                  placeholder="Given names"
                  autoComplete="off"
                  error={errors.givenNames?.message}
                  {...register('givenNames', {
                    onChange: onNameChange,
                    required: FORM_VALIDATION.GIVEN_NAME.REQUIRED,
                    maxLength: {
                      value: nameMaxLength,
                      message: FORM_VALIDATION.GIVEN_NAME.MAX_LENGTH
                    }
                  })}
                />

                <InputField
                  required
                  label="Surname"
                  name="surname"
                  id="surnameInput-input"
                  placeholder="Surname"
                  autoComplete="off"
                  error={errors.surname?.message}
                  {...register('surname', {
                    onChange: onNameChange,
                    required: FORM_VALIDATION.SURNAME.REQUIRED,
                    maxLength: {
                      value: nameMaxLength,
                      message: FORM_VALIDATION.SURNAME.MAX_LENGTH
                    }
                  })}
                />

                <InputField
                  required
                  label="Phone number"
                  name="phoneNumber"
                  id="phoneNumberInput-input"
                  placeholder="Phone number"
                  autoComplete="off"
                  error={errors.phoneNumber?.message}
                  {...register('phoneNumber', {
                    onChange: onPhoneNumberChange,
                    required: FORM_VALIDATION.PHONE_NUMBER.REQUIRED,
                    minLength: {
                      value: 8,
                      message: FORM_VALIDATION.PHONE_NUMBER.MIN_LENGTH
                    },
                    maxLength: {
                      value: 20,
                      message: FORM_VALIDATION.PHONE_NUMBER.MAX_LENGTH
                    }
                  })}
                />
              </Stack>
              <Divider />
              <Styled.ButtonGroup>
                <Button
                  id="modalCancel-button"
                  variant="secondary"
                  size="xsmall"
                  type="button"
                  onClick={onCancelButtonClick}
                >
                  Cancel
                </Button>
                <Button
                  id="modalSave-button"
                  variant="primary"
                  size="xsmall"
                  type="submit"
                  isLoading={isSaveButtonLoading}
                >
                  Save
                </Button>
              </Styled.ButtonGroup>
            </form>
          </>
        )}
      </Styled.ModalContent>
    </Modal>
  );
};

export default EditMetaData;
