import { format } from 'date-fns';
import { CheckType, Scope } from '@auspost/pvoi-components/src/services/reportsBaseService/types';

export class UtilsService {
  static compareString(current: string, next: string): number {
    return current.localeCompare(next);
  }

  static isNextDay(dateInMilliSeconds: number): boolean {
    const nextDate = new Date(dateInMilliSeconds);
    nextDate.setDate(nextDate.getDate() + 1);

    return nextDate.getTime() <= new Date().getTime();
  }

  static truncateText(text: string, maxAmount: number): string {
    if (text.length <= maxAmount) {
      return text;
    } else {
      // Given a upper bound, find the last Space before it reaches the limit
      const value = text.substring(0, maxAmount);
      // Replace overflow text with ellipsis
      return value.lastIndexOf(' ') === -1 || text[maxAmount] === ' '
        ? value + '...'
        : value.substring(0, value.lastIndexOf(' ')) + '...';
    }
  }

  static getCheckTypes(scopes: string): CheckType[] {
    const scopesList = scopes.split(',');
    const checkTypes: CheckType[] = [];
    scopesList.forEach(scope => {
      if (scope === Scope.STAR1_SCOPE) {
        checkTypes.push(CheckType.STAR1);
      } else if (scope === Scope.STAR2_SCOPE) {
        checkTypes.push(CheckType.STAR2);
      } else if (scope === Scope.WATCHLIST) {
        checkTypes.push(CheckType.WATCHLIST);
      } else if (scope === Scope.KYC_PREMIUM) {
        checkTypes.push(CheckType.AML_PREMIUM);
      } else if (scope === Scope.PROOF_OF_AGE) {
        checkTypes.push(CheckType.PROOF_OF_AGE);
      }
    });
    return checkTypes;
  }

  static getEnumPropertyName(enumType: Record<string, unknown>, value: string): string {
    return Object.keys(enumType)[Object.values(enumType).findIndex(x => x === value)];
  }

  static formatTimestamp(timestamp: string): string {
    return format(new Date(timestamp), 'dd/MM/yyyy');
  }
}
