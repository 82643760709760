import { MetadataItem } from './types';

export const METADATA_ITEMS = (name, phone, date, reference): MetadataItem[] => {
  return [
    {
      id: 'name',
      label: 'Name',
      value: name
    },
    {
      id: 'phone',
      label: 'Phone number',
      value: phone || '-',
      valueForScreenReader: phone && phone.replaceAll('', ' ').trim()
    },
    {
      id: 'date',
      label: 'Date',
      value: date
    },
    {
      id: 'reference',
      label: 'Reference number',
      value: reference || '-',
      valueForScreenReader: reference && reference.replaceAll('', ' ').trim()
    }
  ];
};

export const DETAILS_HEADING = 'VOI report details';
export const BACK = 'BACK';
export const BACK_ARIA_LABEL = 'back to V O I reports list';
