import React from 'react';

export const SpeechIcon: React.FC = (): React.ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.13524 14.7194L0.000244141 19.5999V2.38872C0.000244141 1.29248 0.934411 0.399902 2.08358 0.399902H20.0002V12.7305C20.0002 13.8268 19.0661 14.7194 17.9169 14.7194H6.13524ZM18.3336 12.7305V1.99095H2.08358C1.85441 1.99095 1.66691 2.16915 1.66691 2.38872V16.203L5.53191 13.1283H17.9169C18.1461 13.1283 18.3336 12.9501 18.3336 12.7305Z"
        fill="#31313D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.8584 6.54931C9.8584 7.54558 9.68304 8.30654 9.8584 8.7211C8.98214 9.60469 8.40609 10.2502 7.73074 10.8929L6.66691 9.80699C7.08791 9.44711 7.43168 9.12257 7.73074 8.7211C7.9665 8.36453 8.10064 7.94334 7.73074 7.6352H6.66691V4.37753H9.8584V6.54931ZM14.1136 6.54931C14.1136 7.54558 13.9371 8.30654 14.1136 8.7211C13.2323 9.60469 12.6526 10.2502 11.986 10.8929L10.9221 9.80699C11.3318 9.44711 11.6713 9.12257 11.986 8.7211C12.2102 8.36453 12.3446 7.94334 11.986 7.6352H10.9221V4.37753H14.1136V6.54931Z"
        fill="#31313D"
      />
    </svg>
  );
};
