import React from 'react';
import * as Styled from './styled';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import CalendarIcon from '../icons/CalendarIcon';
import { format } from 'date-fns';
import { useOnPopupClose } from '../../hooks/useOnPopupClose';
import DateRange from '../DateRange/DateRange';

interface Props {
  id: string;
  dateFrom?: Date;
  dateTo?: Date;
  minDateFrom?: Date;
  maxDateFrom?: Date;
  minDateTo?: Date;
  maxDateTo?: Date;
  onCancel?: () => void;
  onApply: (dateFrom: Date, dateTo: Date) => void;
}

const DateRangeWithCustomButton: React.FC<Props> = ({
  id,
  dateFrom,
  dateTo,
  minDateFrom,
  maxDateFrom,
  minDateTo,
  maxDateTo,
  onCancel,
  onApply
}): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);
  const rootRef = React.useRef<HTMLDivElement>(null);

  const onButtonDropdownClick = (): void => {
    setOpen(!open);
  };

  const getDateRangeValue = (dateFrom: Date, dateTo: Date): string => {
    if (!hasValueSet()) {
      return 'Dates';
    }

    return `${format(dateFrom, 'dd/MM/yy')} - ${format(dateTo, 'dd/MM/yy')}`;
  };

  const onDateFilterApply = (dateFrom: Date, dateTo: Date): void => {
    onApply(dateFrom, dateTo);
    setOpen(false);

    setTimeout(() => {
      rootRef.current.getElementsByTagName('button')[0].focus();
    });
  };

  const onDateFilterCancel = (): void => {
    onCancel();
    setOpen(false);
  };

  const hasValueSet = (): boolean => {
    return !!dateFrom && !!dateTo;
  };

  const getAriaLabel = (): string => {
    return hasValueSet()
      ? `Date range filter set with value ${getDateRangeValue(dateFrom, dateTo)}`
      : 'Date range filter unset';
  };

  React.useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  useOnPopupClose(rootRef, (): void => setOpen(false));

  return (
    <Styled.Container ref={rootRef}>
      <ButtonCustom
        id={`${id}-dateDropdown`}
        label={getDateRangeValue(dateFrom, dateTo)}
        icon={<CalendarIcon />}
        onClick={onButtonDropdownClick}
        isOpen={open}
        hasValue={hasValueSet()}
        ariaLabel={getAriaLabel()}
        ariaHasPopup={true}
      />
      {open && (
        <Styled.Popup>
          <DateRange
            id={`${id}-dateRange`}
            dateFromValue={dateFrom}
            dateToValue={dateTo}
            maxDateFrom={maxDateFrom}
            maxDateTo={maxDateTo}
            minDateFrom={minDateFrom}
            minDateTo={minDateTo}
            onApply={onDateFilterApply}
            onCancel={onDateFilterCancel}
          />
        </Styled.Popup>
      )}
    </Styled.Container>
  );
};

export default DateRangeWithCustomButton;
