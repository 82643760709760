import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

const APLogoSquare: React.FC<Props> = ({ width = 80, height = 80 }): React.ReactElement => {
  return (
    <svg
      aria-hidden
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
    >
      <path
        d="M30 28.8545C30 29.4545 29.4545 30 28.8545 30H1.14545C0.545455 30 0 29.5091 0 28.8545V1.14545C0 0.490909 0.545455 0 1.14545 0H28.8C29.4545 0 29.9455 0.490909 30 1.14545V28.8545Z"
        fill="#DC1928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2912 5.56431C14.5093 5.50977 14.7821 5.50977 15.0548 5.50977C20.3457 5.50977 24.7093 9.76431 24.7639 14.9461C24.7639 20.1825 20.4002 24.3825 15.0548 24.3825H14.2912V18.3825C17.9457 18.3825 20.9457 15.6552 21.0002 12.3279C21.0002 9.00068 18.0548 6.2734 14.3457 6.21886H14.2912V5.56431ZM11.6184 23.7825C7.96388 22.4189 5.3457 18.9825 5.3457 14.9461C5.3457 10.9643 7.90934 7.4734 11.6184 6.10977V23.7825Z"
        fill="white"
      />
    </svg>
  );
};

export default APLogoSquare;
