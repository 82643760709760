import React from 'react';
import ReactDOM from 'react-dom';
import { AppStateModel } from '../../../pvoi-components/src/models/appState.model';
import { AppState } from './state/appState';
import { AppContext } from './context';
import AppRouter from './routing/AppRouter';
import './theme/main.scss';

const appState: AppStateModel = new AppState();

ReactDOM.render(
  <AppContext.Provider value={appState}>
    <AppRouter />
  </AppContext.Provider>,
  document.getElementById('root')
);
