import React from 'react';
import { Label as PostmasterLabel } from '@auspost/postmaster-react';

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  variant?: 'standard' | 'inline';
  size?: 'small' | 'large';
  htmlFor?: string;

  helpTooltip?: React.ReactNode;
  helpTooltipSize?: 'small' | 'large';
  helpTooltipPlacement?: 'left' | 'middle' | 'right';

  optionalText?: string;
  isOptional?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
}
const Label: React.FC<Props> = (props): React.ReactElement => {
  return <PostmasterLabel {...props} />;
};

export default Label;
