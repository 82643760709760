import React from 'react';
import { Page } from '../../components';
import * as Styled from './styled';
import { Heading, ReportsList, SearchBar } from '@auspost/pvoi-components';
import { HOME_MENU_ITEMS } from './constants';
import { analyticsService, reportDetailsService, reportsService, windowService } from '../../services';
import { useAppContext } from '../../context';
import { PAGE_LOAD_ANALYTICS } from '../../constants/analytics';

const Home: React.FC = (): React.ReactElement => {
  const appState = useAppContext();
  const [searchId, setSearchId] = React.useState<number>(Date.now());
  const isIDV = appState.isIDV;

  React.useEffect(() => {
    analyticsService.trackPage(PAGE_LOAD_ANALYTICS.HOME.CATEGORY, PAGE_LOAD_ANALYTICS.HOME.DESCRIPTION);
    windowService.scrollToTop(true);
    return () => {
      setSearchId(Date.now());
    };
  }, []);

  const onSearch = () => {
    setSearchId(Date.now());
  };

  return (
    /*TODO consider using MobX or Redux to avoid passing methods between components for state monitor */
    <Page onClmSelectCallBack={onSearch} menuItems={HOME_MENU_ITEMS()} showMenu={true}>
      <Styled.Home>
        <Styled.ReportsList>
          <Heading level="h1">{isIDV ? 'Results' : 'VOI reports'}</Heading>
          <SearchBar
            analyticsService={analyticsService}
            onSearch={onSearch}
            appState={appState}
            placeHolder={`Search by name${!isIDV ? ', phone or' : ' or'} reference number`}
          />
          <ReportsList
            appState={appState}
            searchId={searchId.toString()}
            // TODO: should pass in methods instead of the whole service like below
            reportsService={reportsService}
            reportDetailsService={reportDetailsService}
            analyticsService={analyticsService}
          />
        </Styled.ReportsList>
      </Styled.Home>
    </Page>
  );
};

export default Home;
