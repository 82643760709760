import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';
import Label from '../../Label/Label';

export const TopContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

export const ButtonGroup = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  'button:first-of-type': {
    marginRight: '8px'
  }
});

export const RadioButton = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '8px',

  'input[type="radio"]': {
    margin: 0
  }
});

export const ModalContent = styled.div({
  hr: {
    // note: negative margin - can't override postmaster padding
    margin: '24px -32px'
  }
});

export const NotificationText = styled.div({
  fontSize: theme.fontSize.small,
  fontWeight: theme.fontWeight.regular,
  span: {
    fontWeight: theme.fontWeight.medium
  }
});

export const RadioGroup = styled.form({
  marginTop: '24px',
  marginBottom: '24px',
  color: theme.color.ink
});

export const RadioTitle = styled.div({
  fontWeight: theme.fontWeight.bold,
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.default,
  marginBottom: '12px'
});

export const RadioButtonLabel = styled(Label)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.small,
  fontFamily: theme.fontFamily.default,
  paddingLeft: '12px'
});
