import React from 'react';
import { TableHeader } from '../types';

interface Props {
  headerList: TableHeader[];
}

const TableHeader: React.FC<Props> = ({ headerList }): React.ReactElement => {
  return (
    <thead className="MuiTableHead-root">
      <tr className="MuiTableRow-root MuiTableRow-head">
        {headerList.map(({ title, style, screenReaderText }) => (
          <th
            key={title}
            scope="col"
            style={style}
            aria-label={screenReaderText ?? title}
            className="MuiTableCell-root MuiTableCell-head MTableHeader-header-6 MuiTableCell-alignLeft"
          >
            <span aria-hidden="true">{title}</span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
