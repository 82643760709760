export type MetadataItem = {
  id: string;
  label: string;
  value: string;
  valueForScreenReader?: string;
};

export enum ReportStatus {
  NEW = 'NEW',
  VIEWED = 'VIEWED'
}
