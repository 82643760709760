import React from 'react';
import { Page } from '../../components';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../constants/routes.enum';
import { analyticsService, reportDetailsService } from '../../services';
import { CLICK_ANALYTICS, PAGE_LOAD_ANALYTICS } from '../../constants/analytics';
import { ReportsDetails as ReportDetailsBase } from '@auspost/pvoi-components';
import { useAppContext } from '../../context';
import * as Styled from './styled';

const ReportDetails: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const appState = useAppContext();
  const reportId = useLocation().pathname.substring(AppRoute.ReportDetails.lastIndexOf('/') + 1);

  React.useEffect(() => {
    analyticsService.trackPage(
      PAGE_LOAD_ANALYTICS.REPORT_DETAILS.CATEGORY,
      PAGE_LOAD_ANALYTICS.REPORT_DETAILS.DESCRIPTION
    );
  }, []);

  const onBackButtonCallback = (): void => {
    analyticsService.trackClick(
      CLICK_ANALYTICS.CATEGORY.REPORT_DETAILS,
      CLICK_ANALYTICS.INTERACTION.REPORT_DETAILS.BACK
    );
    navigate(AppRoute.Home);
  };

  const onOpenInNewTabClick = (): void => {
    analyticsService.trackClick(
      CLICK_ANALYTICS.CATEGORY.REPORT_DETAILS,
      CLICK_ANALYTICS.INTERACTION.REPORT_DETAILS.OPEN_NEW_TAB
    );
  };

  const onError = (): void => {
    navigate(AppRoute.Outage);
  };

  const onReportPatchError = (): void => {
    analyticsService.trackPageError(
      PAGE_LOAD_ANALYTICS.REPORT_DETAILS.CATEGORY,
      PAGE_LOAD_ANALYTICS.REPORT_DETAILS.DESCRIPTION,
      PAGE_LOAD_ANALYTICS.REPORT_DETAILS.STAGE.REPORT_PATCH_ERROR
    );
  };

  return (
    <Page showMenu={false}>
      <Styled.ReportDetails>
        <ReportDetailsBase
          reportDetailsService={reportDetailsService}
          appState={appState}
          reportId={reportId}
          onError={onError}
          onReportPatchError={onReportPatchError}
          onBackButtonCallback={onBackButtonCallback}
          onOpenInNewTabCallback={onOpenInNewTabClick}
        />
      </Styled.ReportDetails>
    </Page>
  );
};

export default ReportDetails;
