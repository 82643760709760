import styled from '@emotion/styled';
import ButtonNoStyles from '../ButtonNoStyles/ButtonNoStyles';
import { theme } from '../../theme/theme';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '24px 0'
});

export const ResetButton = styled(ButtonNoStyles)({
  color: theme.color.ink,
  padding: '8px 16px',
  fontSize: theme.fontSize.small,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '8px',

  svg: {
    marginRight: '10px'
  },

  '&:hover': {
    textDecoration: 'underline'
  },

  '&:focus:not(:focus-visible)': {
    outline: 'none'
  }
});
