import { AppState, AuthWebService, GetTokenConfig, LoginConfig, UserInfo } from '@auspost/auth0-uiim-types';
import { envConfig } from '../../config/env.config';

declare global {
  interface Window {
    __AUSPOST__: {
      createAuthWebService: () => AuthWebService;
    };
  }
}

class AuthenticationService {
  private authWebService: AuthWebService;

  initialise(): Promise<AppState> {
    return this.instance.initialise(envConfig.ui.authWebConfig);
  }

  login(config?: LoginConfig): Promise<void> {
    return this.instance.login(config);
  }

  logout(url = envConfig.ui.loginUrl): Promise<void> {
    return this.instance.logout(url);
  }

  getAccessTokenSilently(config?: GetTokenConfig): Promise<string> {
    return this.instance.getAccessTokenSilently(config);
  }

  isAuthenticated(): Promise<boolean> {
    return this.instance.isAuthenticated();
  }

  getUser(): Promise<UserInfo | undefined> {
    return this.instance.getUser();
  }

  private get instance() {
    this.authWebService = this.authWebService ?? window.__AUSPOST__?.createAuthWebService();
    return this.authWebService;
  }
}

export const authenticationService = new AuthenticationService();
