import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '32px',
  margin: '0 auto',
  gap: '8px',
  width: '55%',

  '@media (max-width:1199px)': {
    padding: '24px',
    width: `calc( 100% - 48px)`
  }
});

export const DetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: '24px',
  marginBottom: '40px',
  padding: '32px',
  background: `${theme.color.white}`,
  boxShadow: theme.shadow.shadow1,
  borderRadius: '3px',
  flex: 'none',
  order: '2',
  flexGrow: 0
});

export const NoteContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  order: '3',
  marginBottom: '40px',

  li: {
    textIndent: 0
  }
});

export const HeadingContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
});

export const Divider = styled.div({
  margin: '10px 0px 24px 0px',
  width: '100%'
});

export const formStyle = styled.form({
  width: '100%'
});

export const DetailsRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',

  p: {
    minWidth: '178px'
  }
});

export const ButtonGroup = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginTop: '24px',
  flex: 'none',
  gap: '16px',
  order: '3',
  flexGrow: 0
});

export const EditButton = styled.button({
  width: '40px',
  height: '40px',
  background: `${theme.color.white}`,
  border: '0px',
  cursor: 'pointer',

  '&:hover': {
    background: `${theme.color.cloudy}`,
    borderRadius: '3px'
  }
});
