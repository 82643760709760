import styled from '@emotion/styled';
import ButtonNoStyles from '../ButtonNoStyles/ButtonNoStyles';
import { theme } from '../../theme/theme';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: '410px',
  width: '100%',
  position: 'relative',

  input: {
    paddingRight: '42px'
  }
});

export const SearchButton = styled(ButtonNoStyles)<{ isButtonActive: boolean }>(({ isButtonActive }) => ({
  background: isButtonActive ? theme.color.primary : 'none',
  borderRadius: '3px',
  padding: '9px',
  marginLeft: '-39px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    backgroundColor: isButtonActive ? theme.color.feedbackAa04 : 'none',
    cursor: isButtonActive ? 'pointer' : 'unset'
  },

  '&:focus:not(:focus-visible)': {
    outline: isButtonActive ? 'inherit' : 'none !important'
  }
}));
