import styled from '@emotion/styled';
import { theme } from '../../theme/theme';

export const Dropdown = styled.div({
  a: {
    width: '140px'
  }
});

export const Span = styled.span({
  fontSize: theme.fontSize.xsmall,
  fontFamily: theme.fontFamily.default,
  color: theme.color.ink,
  paddingLeft: '8px',
  paddingRight: '8px'
});
