import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const Dropdown = styled.div({
  a: {
    width: '120px'
  }
});

export const Text = styled.span({
  fontSize: theme.fontSize.xsmall,
  color: theme.color.ink
});

export const Span = styled.span({
  paddingLeft: '8px',
  paddingRight: '8px'
});
