import React from 'react';
import { theme } from '../../theme/theme';

interface Props {
  transform?: string;
  fill?: string;
}

const ArrowIcon: React.FC<Props> = ({ transform, fill = theme.color.ash }): React.ReactElement => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: transform }}
      focusable="false"
      aria-hidden="true"
    >
      <path
        d="M10.7071 0.292893C10.3166 -0.0976311 9.68342 -0.0976311 9.29289 0.292893C8.90237 0.683417 8.90237 1.31658 9.29289 1.70711L14.5858 7H1C0.447716 7 0 7.44772 0 8C0 8.55229 0.447716 9 1 9H14.5858L9.29289 14.2929C8.90237 14.6834 8.90237 15.3166 9.29289 15.7071C9.68342 16.0976 10.3166 16.0976 10.7071 15.7071L17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289L10.7071 0.292893Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowIcon;
