// TODO: merge this analytics with Agency Ops analytics

export const PAGE_LOAD_ANALYTICS = {
  HOME: {
    CATEGORY: 'voi reports',
    DESCRIPTION: 'most recent reports',
    FORM: {
      NAME: 'search',
      STEP: 'filter',
      STAGE: {
        NO_REPORTS: 'no reports',
        NO_MATCHING_REPORTS: 'no matching reports',
        ERROR: 'unable to load voi reports'
      }
    }
  },
  REPORT_DETAILS: {
    CATEGORY: 'voi report details',
    DESCRIPTION: 'pdf viewer',
    STAGE: {
      REPORT_PATCH_ERROR: 'error in patching voi report'
    }
  },
  CHECK_DETAILS: {
    CATEGORY: 'voi check details',
    DESCRIPTION: 'single result for voi check details'
  },
  HELP_CENTRE: {
    CATEGORY: 'help centre',
    DESCRIPTION: 'instructions and information'
  },
  OUTAGE: {
    CATEGORY: 'outage',
    DESCRIPTION: 'error'
  },
  INVITATION: {
    CATEGORY: 'invitation',
    DESCRIPTION: 'invitation form'
  },
  INVITATION_CONFIRMATION: {
    CATEGORY: 'invitation',
    DESCRIPTION: 'invitation confirmation'
  },
  AUTH_2FA: {
    CATEGORY: 'auth',
    DESCRIPTION: '2fa',
    STAGE: {
      FINAL_ATTEMPT: 'final attempt',
      CODE_INCORRECT: 'code incorrect',
      CODE_EXP: 'code expired',
      ONE_MORE_ATTEMPT: 'one more attempt',
      AC_LOCKED_TOO_MANY_RESENT: 'account locked:too many requests',
      AC_LOCKED_CODE_INCORRECT: 'account locked:code incorrect',
      AC_LOCKED_WHEN_LOG_IN: 'account locked:login',
      OUTAGE: 'outage'
    }
  }
};

export const CLICK_ANALYTICS = {
  CATEGORY: {
    HEADER: `header`,
    SIDE_MENU: `side menu`,
    SEARCH: `voi reports:search`,
    REPORTS: `voi reports:table`,
    HELP_CENTRE: `help centre`,
    REPORT_DETAILS: `voi report details`,
    EXPORT_MODAL: `voi reports:export modal`,
    AUTH_2FA: `auth 2fa`
  },
  INTERACTION: {
    HEADER: {
      LOGO: `logo`,
      COMPANY_DETAILS_DROPDOWN: 'company details',
      MANAGE_USERS: 'manage users',
      ACCOUNT_SETTINGS: `account settings`,
      LOGOUT: 'logout',
      HAMBURGER: 'hamburger menu'
    },
    SIDE_MENU: {
      VOI_REPORTS: 'voi reports',
      HELP_CENTRE: 'help centre',
      DID_INVITATION: 'did new invitation'
    },

    SEARCH_FILTER: {
      SEARCH_BUTTON: 'search',
      DATE_RANGE_CANCEL: 'date range cancel',
      DATE_RANGE_APPLY: 'date range apply',
      STATUS: {
        ANY: 'status:any',
        NEW: 'status:new',
        VIEWED: 'status:viewed'
      },
      RESET: 'reset'
    },

    PAGINATION: {
      SHOW_MORE: 'show more reports',
      EXPORT: 'export reports list'
    },

    EXPORT_MODAL: {
      EXPORT: 'export',
      CANCEL: 'cancel',
      CLOSE: 'close'
    },

    TABLE: {
      DOWNLOAD: 'download',
      VIEW: 'view',
      ROW_CLICK: 'row'
    },

    REPORT_DETAILS: {
      BACK: 'back',
      OPEN_NEW_TAB: 'open report in a new tab'
    },

    AUTH_2FA: {
      SEND_A_CODE: 'send a code',
      SUBMIT: 'submit'
    },

    HELP_CENTRE: {
      USER_GUIDE: {
        VOI_USER_GUIDE: 'voi user guide',
        DIY_USER_GUIDE: 'diy voi user guide',
        CLIENT_VOI: 'client voi',
        SEARCH_TIPS: 'search tips',
        EMAIL_US: 'email us'
      },
      FAQ: {
        VOI_USER_GUIDE_1: 'manage team user guide',
        VOI_USER_GUIDE_2: 'find report user guide',
        LAND_TITLE_EMAIL_1: 'edit client details email',
        LAND_TITLE_EMAIL_2: 'unsubscribe email'
      },
      LOCATIONS: 'post office locations',
      CONTACT_US: {
        PHONE: 'phone',
        EMAIL: 'email'
      }
    }
  }
};
