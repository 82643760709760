import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const TopContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

export const ButtonGroup = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  'button:first-of-type': {
    marginRight: '8px'
  }
});

export const ModalContent = styled.div({
  hr: {
    // note: negative margin - can't override postmaster padding
    margin: '24px -32px'
  }
});

export const NotificationText = styled.div({
  fontSize: theme.fontSize.small,
  fontWeight: theme.fontWeight.regular,
  span: {
    fontWeight: theme.fontWeight.medium
  }
});
