export type SelectedClmType = {
  id: string;
  clmName: string;
  userName: string;
  isAdmin: boolean;
  isIDV?: boolean;
  defaultClientId?: string;
};

export enum CLM_ROLE {
  ADMIN = 'Admin',
  USER = 'User'
}
