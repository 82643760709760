import styled from '@emotion/styled';
import { theme } from '../../theme/theme';
import ButtonNoStyles from '../ButtonNoStyles/ButtonNoStyles';
import { Button } from '../Button/Button';

export const Container = styled.div({
  backgroundColor: theme.color.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: theme.spacing.spaceM,
  borderRadius: '0 0 8px 8px',
  boxShadow: theme.shadow.shadow1
});

export const MaxReports = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

export const PaginationText = styled.p({
  fontSize: theme.fontSize.small,
  color: theme.color.ink,
  fontWeight: theme.fontWeight.regular,
  ' > span:nth-of-type(1)': {
    fontWeight: theme.fontWeight.bold
  }
});

export const Pagination = styled.div({
  backgroundColor: theme.color.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing.spaceM,
  borderRadius: '0 0 8px 8px',
  boxShadow: theme.shadow.shadow1
});

export const BackToSearch = styled(ButtonNoStyles)({
  paddingTop: '4px',
  textDecoration: 'underline',
  fontWeight: theme.fontWeight.bold,
  fontSize: theme.fontSize.small,

  '&:focus': {
    outline: `1px dotted ${theme.color.slate}`
  },

  '&:hover': {
    textDecoration: 'none'
  }
});

export const PaginationButton = styled(Button)<{ showButton?: boolean }>(({ showButton = true }) => ({
  visibility: showButton ? 'visible' : 'hidden'
}));
