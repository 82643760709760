import React from 'react';
import * as Styled from './styled';
import { APLogo, MenuIcon, useOnPopupClose } from '../..';
import Sidebar from '../../components/Sidebar/Sidebar';
import Menu from 'pvoi-ui/src/components/Menu/Menu';
import { MenuItem } from 'pvoi-ui/src/components/Menu/types';
import { useAppContext } from 'pvoi-ui/src/context';

interface Props {
  logoTitle: string;
  logoHref: string;
  onLogoLinkClickCallBack?: () => void;
  children?: React.ReactNode;
  menuItems?: MenuItem[];
  onMenuLinkCallback?: () => void;
}

const Header: React.FC<Props> = ({
  logoTitle,
  logoHref,
  onLogoLinkClickCallBack,
  onMenuLinkCallback,
  children,
  menuItems
}: Props): React.ReactElement => {
  const [pageLoad, setPageLoad] = React.useState<boolean>(true);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const headerRef = React.useRef<HTMLHeadElement>(null);
  const showMenu = menuItems?.length > 0;
  const appState = useAppContext();

  const onMenuClick = (): void => {
    onMenuLinkCallback();
    setPageLoad(false);
    setOpenMenu(!openMenu);
    setPageScrolling(openMenu);
  };

  const setPageScrolling = (isScrollable: boolean): void => {
    document.body.style.overflow = isScrollable ? 'unset' : 'hidden';
  };

  const onMenuClose = (): void => {
    setOpenMenu(false);
    setPageScrolling(true);
  };

  const onLogoLinkClick = (): void => {
    onLogoLinkClickCallBack();
  };

  React.useEffect(() => {
    return () => {
      setPageLoad(true);
      setOpenMenu(false);
    };
  }, []);

  useOnPopupClose(headerRef, onMenuClose);

  return (
    <>
      <Styled.Header ref={headerRef}>
        <Styled.HeaderMenu>
          <Styled.MenuLogo>
            {showMenu && (
              <Styled.MenuButton
                id="burgerMenuButton"
                type="button"
                onClick={onMenuClick}
                aria-expanded={openMenu}
                aria-haspopup={true}
              >
                <MenuIcon />
              </Styled.MenuButton>
            )}
            <Styled.LogoLink id="logoLink" href={logoHref} onClick={onLogoLinkClick}>
              <APLogo />
              <span>{logoTitle}</span>
            </Styled.LogoLink>
          </Styled.MenuLogo>
          {children}
        </Styled.HeaderMenu>
        {!pageLoad && showMenu && (
          <Styled.Menu isOpen={openMenu}>
            <Sidebar>
              <Menu items={menuItems} onClose={onMenuClose} appState={appState} />
            </Sidebar>
          </Styled.Menu>
        )}
      </Styled.Header>
      {openMenu && showMenu && <Styled.Overlay />}
    </>
  );
};

export default Header;
