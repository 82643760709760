import React from 'react';
import { analyticsService } from '../../services';
import { PAGE_LOAD_ANALYTICS } from '../../constants/analytics';
import { Outage as OutageBase } from '@auspost/pvoi-components';
import { useAppContext } from '../../context';
import { Page } from '../../components';
import * as Styled from './styled';

const Outage: React.FC = (): React.ReactElement => {
  const { error } = useAppContext();
  const { status, url, header } = error;

  const onPageLoadCallback = () => {
    analyticsService.trackPageError(
      PAGE_LOAD_ANALYTICS.OUTAGE.CATEGORY,
      PAGE_LOAD_ANALYTICS.OUTAGE.DESCRIPTION,
      `${status}:${url}:${header}`
    );
  };

  return (
    <Page showProfile={false} showMenu={false}>
      <Styled.Outage>
        <OutageBase header={error.header} content={error.content} onPageLoadCallback={onPageLoadCallback} />
      </Styled.Outage>
    </Page>
  );
};

export default Outage;
