import { AnalyticsData } from './types';
import { ANALYTICS } from './constants';

declare global {
  interface Window {
    analytics: AnalyticsData;
    _satellite: {
      track: (trackId: string) => void;
    };
    adobeAnalyticsFailed: boolean;
  }
}

export class AnalyticsService {
  private readonly loginStatus: string = 'authenticated';
  private readonly categoryPrefix: string = '';
  private apcn = '';

  constructor(categoryPrefix: string) {
    // to distinguish between UIs with the same sitePrefix
    this.categoryPrefix = categoryPrefix;
  }

  trackPage(
    pageCategory: string,
    pageDescription: string,
    formName?: string,
    formStep?: string,
    formStage?: string
  ): void {
    const analytics: AnalyticsData = this.getDefaultAnalyticsData();

    analytics.page.pageData.pageCategory = `${this.categoryPrefix}${pageCategory}`;
    analytics.page.pageData.pageDescription = pageDescription;

    if (formName) {
      analytics.component.form.name = formName;
    }

    if (formStep) {
      analytics.component.form.step = formStep;
    }

    if (formStage) {
      analytics.component.form.stage = formStage;
    }

    this.track(ANALYTICS.TRACK_ID.PAGE_NAVIGATE, analytics);
  }

  trackPageError(pageCategory: string, pageDescription: string, error: string): void {
    const analytics: AnalyticsData = this.getDefaultAnalyticsData();

    analytics.page.pageData.pageCategory = pageCategory;
    analytics.page.pageData.pageDescription = pageDescription;
    analytics.component.form.error = error;

    this.track(ANALYTICS.TRACK_ID.PAGE_NAVIGATE, analytics);
  }

  trackClick(interactionCategory: string, interactionDescription: string): void {
    const analytics: AnalyticsData = window.analytics;

    analytics.page.pageData.interactionCategory = `${this.categoryPrefix}${interactionCategory}`;
    analytics.page.pageData.interactionDescription = interactionDescription;

    this.track(ANALYTICS.TRACK_ID.SITE_INTERACT, analytics);
  }

  setApcn(apcn: string): void {
    this.apcn = apcn;
  }

  private track(trackId: string, analytics: AnalyticsData): void {
    try {
      if (!window.adobeAnalyticsFailed) {
        window.analytics = analytics;
        window._satellite.track(trackId);
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(`error tracking page event. ${error}`);
    }
  }

  private getDefaultAnalyticsData(): AnalyticsData {
    return {
      page: {
        pageData: {
          sitePrefix: window.analytics.page.pageData.sitePrefix,
          pageAbort: window.analytics.page.pageData.pageAbort,
          pageCategory: '',
          pageDescription: '',
          interactionCategory: '',
          interactionDescription: ''
        }
      },
      component: {
        form: {
          name: '',
          step: '',
          stage: '',
          error: ''
        }
      },
      user: {
        userData: {
          apcn: this.apcn,
          loginStatus: this.loginStatus
        }
      }
    };
  }
}
