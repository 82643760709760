import React from 'react';
import { theme } from '../../theme/theme';

interface Props {
  color?: string;
}

const SearchIcon: React.FC<Props> = ({ color = theme.color.slate }): React.ReactElement => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.80609 0C10.5638 0 13.6122 3.00078 13.6122 6.7078C13.6122 8.23673 13.086 9.68328 12.142 10.8564L15.7166 14.3755C16.0944 14.7487 16.0944 15.3591 15.717 15.7225C15.3435 16.0923 14.7401 16.0923 14.3667 15.7235L10.7522 12.1663C9.6109 12.9696 8.24246 13.4109 6.80609 13.4109C3.04919 13.4109 0 10.4109 0 6.7078C0 3.00078 3.04839 0 6.80609 0ZM1.91595 6.7078C1.91595 9.35923 4.10283 11.5094 6.80609 11.5094C9.50945 11.5094 11.6967 9.35913 11.6967 6.7078C11.6967 4.05545 9.50841 1.90147 6.80609 1.90147C4.10387 1.90147 1.91595 4.05534 1.91595 6.7078Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
