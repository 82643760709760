import React from 'react';

interface Props {
  strokeColor: string;
  fillColor: string;
}

const CircleIcon: React.FC<Props> = ({ strokeColor, fillColor }): React.ReactElement => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
      <circle cx="7" cy="7" r="6" fill={fillColor} stroke={strokeColor} strokeWidth="2" />
    </svg>
  );
};

export default CircleIcon;
