import React from 'react';

const APLogo: React.FC = (): React.ReactElement => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
      <path
        d="M0 18C0 25.7075 4.82115 32.2731 11.6247 34.8423V1.15771C4.82115 3.72688 0 10.2925 0 18Z"
        fill="#DC1928"
      />
      <path
        d="M17.9998 0C17.5082 0 17.0007 0.015859 16.5249 0.0475771V1.3163H16.6201C23.4712 1.36388 28.9901 6.59736 28.9425 13.0044C28.8949 19.3797 23.3443 24.5181 16.5249 24.5022V35.9366C17.0165 35.9841 17.5082 36 17.9998 36C27.9434 36 35.9998 27.9436 35.9998 18C35.9998 8.05639 27.9434 0 17.9998 0Z"
        fill="#DC1928"
      />
    </svg>
  );
};

export default APLogo;
