import dompurify from 'dompurify';

export class DomService {
  static focusOn(selector: string, scrollIntoView = true): void {
    const element = document.querySelector(selector);

    if (element) {
      if (scrollIntoView) {
        element.scrollIntoView();
      }

      (element as HTMLElement).focus();
    }
  }

  static setInnerHTML = (markup: string, config?: { ADD_ATTR?: string[] }): { __html: string } => {
    return {
      __html: dompurify.sanitize(markup, config)
    };
  };

  static getUrlPath(): string {
    const path = window.location.pathname;
    return `/${path.split('/').pop()}` || '';
  }

  static truncateText(input: string, charCount: number): string {
    return input.length > charCount ? `${input.substring(0, charCount)}...` : input;
  }

  static isVisible = (element: HTMLElement, leftPixelOffset = 0, bottomPixelOffset = 0): boolean => {
    // Gets the DOMRect of the provided element which provides it's position on the page
    const boundingRect = element.getBoundingClientRect();

    /*
     By using two points (one horizontal and vertical) we can make a rectangle to find an
     element in a given space from the points provided

      The target points are reduced by an offset provided if needed for increased accuracy
      */
    const leftPoint = boundingRect.left - leftPixelOffset;
    const bottomPoint = boundingRect.bottom - bottomPixelOffset;

    // Get the element found at the given area
    const foundElement = document.elementFromPoint(leftPoint, bottomPoint);
    /*
     Return true if the found element is equal to the one to compare to
     or if the found element at the given position is a child of the
     element to be compared to
     */
    return foundElement && (foundElement === element || element.contains(foundElement));
  };
}
