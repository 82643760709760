import React from 'react';

export enum Auth2FAStatus {
  DEFAULT = 'DEFAULT',
  CODE_SENT_FINAL_ATTEMPT = 'FINAL_ATTEMPT',
  CODE_SENT_SUCCESS = 'SUCCESS',
  CODE_INCORRECT = 'CODE_INCORRECT',
  CODE_EXP = 'CODE_EXP',
  CODE_SENDING = 'CODE_SENDING',
  CODE_SUBMITTING = 'CODE_SUBMITTING',
  ONE_MORE_ATTEMPT = 'ONE_MORE_ATTEMPT',
  AC_LOCKED_TOO_MANY_RESENT = 'AC_LOCKED_TOO_MANY_RESENT',
  AC_LOCKED_CODE_INCORRECT = 'AC_LOCKED_CODE_INCORRECT',
  AC_LOCKED_WHEN_LOG_IN = 'AC_LOCKED_WHEN_LOG_IN',
  OUTAGE = 'OUTAGE'
}

export type AuthState = {
  heading: string;
  status: Auth2FAStatus;
  subHeading?: string;
  alertHeading?: string | React.ReactNode;
  alertBody?: string | React.ReactNode;
  reminderPrimary?: string;
  reminderSecondary?: string;
  logoSize?: number;
};
