import React from 'react';
import { AxiosError, AxiosRequestConfig, AxiosStatic } from 'axios';
import { storageService } from '../../services/storage/storageService';
import { fingerprintService } from '../../services/fingerprintService/fingerprintService';
import { LOCAL_STORAGE_KEY } from '../../constants/storageKeys.enum';
import { authenticationService } from '../../services/authenticationService/authenticationService';

interface Props {
  httpService: AxiosStatic;
  redirectToOutage: (status: number, url: string) => void;
  redirectToMfa?: (status: number, url: string, error?: AxiosError[]) => boolean;
  logoutCallback: () => Promise<void> | void;
  withCredentials?: boolean;
  bearerToken?: string;
}

interface ErrorResponse {
  errors: AxiosError[];
}

const Interceptor: React.FC<Props> = ({
  httpService,
  redirectToOutage,
  redirectToMfa,
  logoutCallback,
  withCredentials,
  bearerToken
}): React.ReactElement => {
  React.useEffect(() => {
    httpService.interceptors.request.use(
      async configuration => {
        configuration.withCredentials = !!withCredentials;

        if (bearerToken) {
          configuration.headers['Authorization'] = `Bearer ${bearerToken}`;
        } else {
          configuration.headers['Authorization'] = `Bearer ${await authenticationService.getAccessTokenSilently()}`;
        }
        configuration.headers.common['device'] = await getFingerPrint();

        return configuration;
      },
      (error: AxiosError): Promise<AxiosError> => {
        return Promise.reject(error);
      }
    );

    httpService.interceptors.response.use(
      response => {
        return response;
      },
      async (error: AxiosError): Promise<AxiosError> => {
        if (!!redirectToMfa && await handleMfaError(error)) {
          return Promise.reject(error);
        }
        return !isWhiteList(error.config) &&
          handleRedirection(error.response?.status || 500, error.request?.responseURL || '')
          ? Promise.reject()
          : Promise.reject(error);
      }
    );
  }, []);

  const handleMfaError = async (error: AxiosError) => {
    let { status, data } = error.response;
    const url = error.request.responseURL;

    // Handle MFA errors based on axios responseType
    if (error.config.responseType === 'arraybuffer' && data instanceof ArrayBuffer) {
      data = JSON.parse(new TextDecoder().decode(data));
    } else if (
      error.config.responseType === 'blob' &&
      data instanceof Blob &&
      data.type
    ) {
      const text = await data.text();
      data = data.type.includes('json') ? JSON.parse(text) : text;
    }
    return redirectToMfa(status, url, (data as ErrorResponse)?.errors);
  };

  const handleRedirection = (status: number, url: string): boolean => {
    if (status === 401) {
      storageService.clear('session');
      void logoutCallback();
      return true;
    } else if (status === 403 || status === 404 || status >= 500) {
      redirectToOutage(status, url);
      return true;
    }

    return false;
  };

  const isWhiteList = (config: AxiosRequestConfig): boolean => {
    return (
      config.method === 'put' ||
      config.method === 'post' ||
      config.method === 'patch' ||
      (config.method === 'get' &&
        (config.headers.Accept === 'application/xls' ||
          config.headers.Accept === 'application/xlsx' ||
          config.headers.Accept === 'application/pdf'))
    );
  };

  const getFingerPrint = (): Promise<string> => {
    const did = storageService.get(LOCAL_STORAGE_KEY.DEVICE_ID, 'local') as string;

    if (did) {
      return Promise.resolve(did);
    }

    return fingerprintService.getFingerPrint().then(fingerPrint => {
      storageService.set(LOCAL_STORAGE_KEY.DEVICE_ID, fingerPrint, 'local');
      return fingerPrint;
    });
  };

  return null;
};

export default Interceptor;
