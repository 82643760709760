import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../../theme/theme';
import ButtonNoStyles from '../ButtonNoStyles/ButtonNoStyles';
import Notification from '../Notification/Notification';

export const tableStyle: React.CSSProperties = {
  borderRadius: '8px 8px 0 0',
  // note: 2nd value is 0px because we don't want the bottom shadow
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)'
};

export const getHeaderStyle = (width: string, paddingLeft?: string): React.CSSProperties => {
  return {
    color: theme.color.inkSubheading,
    padding: `0 0 0 ${paddingLeft ? paddingLeft : '12px'}`,
    fontFamily: theme.fontFamily.default,
    fontSize: theme.fontSize.subtitle,
    fontWeight: 'normal',
    textAlign: 'left',
    textTransform: 'uppercase',
    borderBottom: `1px solid ${theme.color.smoke}`,
    boxSizing: 'border-box',
    width: width,
    height: '48px'
  };
};

const leftContentPadding = '12px';

// generic row styling
export const rowStyle: React.CSSProperties = {
  color: theme.color.ink,
  fontSize: theme.fontSize.default
};

// generic column styling
export const getCellStyle = (width: string): React.CSSProperties => {
  return {
    whiteSpace: 'nowrap',
    padding: '20px 0 20px 12px',
    width: `calc(${width} - ${leftContentPadding})`
  };
};

export const surnameColumnStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
  width: `calc(16% - ${leftContentPadding})`,
  padding: '20px 0 20px 24px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const ellipsisColumnStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
  width: '80px',
  paddingRight: '24px',
  paddingLeft: '8px'
};

// custom row styling
export const Surname = styled.div({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: 0,

  '@media (max-width: 1439px)': {
    width: '100px'
  },

  '@media (min-width: 1440px)': {
    width: '90%'
  }
});

export const GivenName = styled.div({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: 0,

  '@media (max-width: 1439px)': {
    width: '120px'
  },

  '@media (min-width: 1440px)': {
    width: '90%'
  }
});

export const emailColumnStyle: React.CSSProperties = {
  whiteSpace: 'nowrap',
  width: `calc(17% - ${leftContentPadding})`,
  padding: '20px 0 20px 12px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const Email = styled.div({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: 0,

  '@media (max-width: 1439px)': {
    width: '120px'
  },

  '@media (min-width: 1440px)': {
    width: '90%'
  }
});

// override breakpoints / states here
// @ts-ignore
export const ReportsList = styled.div({
  '.MuiTableCell-root': {
    padding: 0
  },
  'tbody > tr:hover': {
    backgroundColor: '#FAFAFA',
    cursor: 'pointer',
    fontSize: theme.fontSize.default
  },
  '.MuiPaper-root > div': {
    overflowX: 'unset !important'
  },

  '.MuiPaper-root > div > div > div': {
    overflowY: 'unset !important'
  }
});

export const EmptyState = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: theme.color.white,
  height: '240px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  position: 'relative',
  fontSize: theme.fontSize.large,
  fontWeight: theme.fontWeight.medium
});

export const Loading = styled.div({
  background: theme.color.white,
  margin: '24px 0',
  height: '240px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  position: 'relative',

  div: {
    position: 'absolute',
    top: '44%',
    left: '50%'
  },

  'div::before': {
    height: '24px',
    width: '24px'
  }
});

export const PdfNotification = styled(Notification)({
  fontWeight: theme.fontWeight.regular,
  fontSize: theme.fontSize.small,
  color: theme.color.ink,
  marginBottom: '16px'
});

export const Button = styled(ButtonNoStyles)({
  width: '112px',
  fontSize: theme.fontSize.small,
  padding: '6px 16px',

  '&:focus': {
    outline: `1px dotted ${theme.color.slate}`,
    outlineOffset: '2px'
  },

  '&:focus:not(:focus-visible)': {
    outline: 'none'
  }
});
