import {
  AnalyticsService,
  ReportDetailsService,
  ReportsService,
  SendInvitationService,
  windowService,
  storageService
} from '@auspost/pvoi-components';
import { httpService } from './http/httpService';

const analyticsService = new AnalyticsService('');
const reportsService = new ReportsService(httpService);
const reportDetailsService = new ReportDetailsService(httpService);
const sendInvitationService = new SendInvitationService(httpService);

export {
  analyticsService,
  storageService,
  windowService,
  httpService,
  reportsService,
  reportDetailsService,
  sendInvitationService
};
