import React from 'react';
import * as Styled from './styled';
import { MetadataItem, ReportStatus } from './types';
import { METADATA_ITEMS } from './constants';
import { Metadata } from './Metadata/Metadata';
import { PdfViewer, Link, AppStateModel, ReportDetailsService, Sidebar } from '../../';

interface Props {
  appState: AppStateModel;
  reportId: string;
  reportDetailsService: ReportDetailsService;
  onBackButtonCallback: () => void;
  onError: () => void;
  onReportPatchError?: () => void;
  patchReport?: boolean;
  onOpenInNewTabCallback?: () => void;
  onEditMetaData?: () => void;
  onSaveEditMetaData?: () => void;
  onCancelEditMetaData?: () => void;
  onCloseEditMetaData?: () => void;
}

const ReportDetails: React.FC<Props> = ({
  appState,
  reportId,
  reportDetailsService,
  onBackButtonCallback,
  onError,
  onReportPatchError,
  patchReport = true,
  onOpenInNewTabCallback,
  onEditMetaData,
  onSaveEditMetaData,
  onCancelEditMetaData,
  onCloseEditMetaData
}): React.ReactElement => {
  const [isLoading, setLoading] = React.useState(true);
  const [metadata, setMetadata] = React.useState<MetadataItem[]>([]);
  const [pdfData, setPdfData] = React.useState<string>('');

  let reportStatus: string;

  React.useEffect(() => {
    getMetadata()
      .then(setMetadata)
      .then(() => {
        if (patchReport && reportStatus === ReportStatus.NEW) {
          patchReportStatus();
        }
        return getPdfData();
      })
      .then(setPdfData)
      .finally(() => setLoading(false));

    return () => {
      setLoading(true);
      setMetadata([]);
      setPdfData('');
    };
  }, []);

  const mapMetadata = detail => {
    const fullNameArray = [detail.familyName, detail.givenName];

    reportStatus = detail.status;

    return METADATA_ITEMS(
      fullNameArray.join(fullNameArray.includes('') ? '' : ', '),
      detail.phone,
      detail.date,
      detail.referenceNumber
    );
  };

  const getMetadata = (): Promise<MetadataItem[]> => {
    return reportDetailsService.getReportDetails(appState.selectedClm.id, reportId).then(mapMetadata);
  };

  const getPdfData = (): Promise<string> => {
    return reportDetailsService.getPdf(appState.selectedClm.id, reportId).catch(onError) as Promise<string>;
  };

  const patchReportStatus = (): void => {
    void reportDetailsService.patchReport(appState.selectedClm.id, reportId).catch(onReportPatchError);
  };

  const onSaveEditMetadata = (data): Promise<MetadataItem[]> => {
    onSaveEditMetaData();

    return reportDetailsService.patchReport(appState.selectedClm.id, reportId, data).then(response => {
      setMetadata(mapMetadata(response));
      return mapMetadata(response);
    });
  };

  return (
    <Styled.Container>
      {isLoading ? (
        <Styled.FullPageSpinner colour="secondary" variant="full-page" />
      ) : (
        <>
          <Styled.MetadataContainer>
            <Sidebar>
              <Metadata
                items={metadata}
                onBackButtonCallback={onBackButtonCallback}
                onEditMetaData={onEditMetaData}
                onSaveEditMetaData={onSaveEditMetadata}
                onCancelEditMetaData={onCancelEditMetaData}
                onCloseEditMetaData={onCloseEditMetaData}
              />
            </Sidebar>
          </Styled.MetadataContainer>
          {appState.isTablet ? (
            <Styled.TabletView>
              <Link id="pdf_link" isExternal={true} href={pdfData} onClick={onOpenInNewTabCallback} target="_blank">
                Open report in a new tab
              </Link>
            </Styled.TabletView>
          ) : (
            <Styled.PdfContainer>
              <PdfViewer src={pdfData} />
            </Styled.PdfContainer>
          )}
        </>
      )}
    </Styled.Container>
  );
};

export default ReportDetails;
