import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const Container = styled.div({
  minHeight: '100vh',
  minWidth: '960px',
  backgroundColor: theme.color.shell,
  margin: 0,
  padding: 0,
  fontFamily: theme.fontFamily.default,

  'h1, h2, h3, h4, h5, h6': {
    fontWeight: theme.fontWeight.regular,
    margin: 0
  }
});

export const Content = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%'
});

export const Menu = styled.div({
  display: 'flex',
  borderTop: `1px solid ${theme.color.shell}`,

  '@media (max-width:1199px)': {
    display: 'none'
  }
});
