import React from 'react';
import { DomService, HeaderDropdownButton, LinkNoStyles, Select } from '@auspost/pvoi-components';
import { analyticsService } from '../../../services';
import * as Styled from '../styled';
import { envConfig } from '../../../config/env.config';
import { CLICK_ANALYTICS } from '../../../constants/analytics';
import { ClmListItem } from '../types';

interface Props {
  id: string;
  isAdmin: boolean;
  clmList: ClmListItem[];
  selectedClm: string;
  onSelect: (clmId: string) => void;
}

const CompanyDetailsSelect: React.FC<Props> = ({ id, isAdmin, clmList, selectedClm, onSelect }): React.ReactElement => {
  return (
    <Select
      id={id}
      renderButton={(props, isClicked) => {
        const clmDetailsArray = (props.children as string).split('-');
        return (
          <HeaderDropdownButton {...props} isClicked={isClicked}>
            <Styled.Span>{`${DomService.truncateText(clmDetailsArray[0], 42)} - ${clmDetailsArray[1]}`}</Styled.Span>
          </HeaderDropdownButton>
        );
      }}
      listItems={clmList.map(clm => `${clm.clmName} - ${clm.clmId}`)}
      width="auto"
      hasTriangleTip={true}
      otherSection={
        isAdmin ? (
          <LinkNoStyles
            id="manageUsers-link"
            isExternal={true}
            href={envConfig.ui.merchantPortal}
            target="_blank"
            onClick={() => {
              analyticsService.trackClick(
                CLICK_ANALYTICS.CATEGORY.HEADER,
                CLICK_ANALYTICS.INTERACTION.HEADER.MANAGE_USERS
              );
            }}
          >
            Manage users
          </LinkNoStyles>
        ) : null
      }
      onSelect={onSelect}
      selectedValue={selectedClm}
    />
  );
};

export default CompanyDetailsSelect;
