import styled from '@emotion/styled';
import { theme } from '../../theme/theme';

export const CrossBrowserCompatibility = styled('div')({
  // to push the footer at the bottom of the screen for IE
  display: 'flex',
  msFlexDirection: 'column',
  minHeight: '100vh'
});

export const Wrapper = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  minHeight: '100vh',

  input: {
    maxWidth: '312px'
  }
});

export const StyledBody = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px 0',
  background: theme.color.shell
});

export const FlexContainer = styled('div')({
  height: '100%',
  width: '100%',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});

export const AuthLogo = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  svg: {
    marginRight: '23px'
  }
});

export const BannerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

export const BannerTitle = styled('h1')({
  fontSize: '24px',
  fontFamily: theme.fontFamily.heading,
  color: theme.color.ink,
  fontWeight: theme.fontWeight.bold,
  lineHeight: '28px',
  width: '300px',
  margin: 0
});

export const BannerText = styled('p')({
  margin: '6px 0 0 0',
  lineHeight: '24px',
  color: theme.color.slate
});

export const Card = styled('div')({
  backgroundColor: theme.color.white,
  padding: '40px',
  marginTop: '32px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  width: '464px',
  borderRadius: '6px'
});

export const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '22px'
});

export const CardText = styled('p')({
  margin: 0,
  color: theme.color.ink,
  lineHeight: '24px',
  fontSize: '16px'
});

export const CardLabel = styled('label')({
  margin: '22px 0px 8px 0px',
  color: theme.color.ink,
  fontWeight: theme.fontWeight.bold
});

export const InputContainer = styled('div')({
  input: {
    width: '120px',
    height: '48px',
    borderRadius: '4px',
    border: `1px solid ${theme.color.inkBorder}`,
    paddingLeft: '16px'
  }
});

export const ButtonContainer = styled('div')({
  '> button': {
    width: '100%'
  },
  '> div': {
    display: 'flex',
    width: '120px',
    height: '24px',
    justifyContent: 'center',
    margin: 'auto'
  }
});

export const SpinnerContainer = styled('div')({
  width: '120px',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  margin: '16px 0 0 0'
});

export const HeadsUpContainer = styled('div')({
  margin: '0 0 32px 0',
  display: 'flex'
});

export const SendCodeLink = styled('button')({
  background: 'none!important',
  border: 'none',
  padding: 0,
  textDecoration: 'underline',
  cursor: 'pointer',
  lineHeight: '24px',
  color: theme.color.ink,
  margin: '16px 0 0 0'
});

export const Error = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '8px',
  fontSize: '14px',
  color: theme.color.errorRed,
  svg: {
    marginRight: '8px'
  }
});

export const AlertWrapper = styled('div')({
  color: theme.color.inkText
});

export const AlertTitle = styled('h4')({
  margin: 0,
  fontFamily: theme.fontFamily.heading,
  fontSize: theme.fontSize.default,
  lineHeight: '24px',
  fontWeight: `${theme.fontWeight.bold} !important`
});

export const AlertBody = styled('p')({
  margin: 0,
  fontFamily: theme.fontFamily.default,
  fontSize: theme.fontSize.default,
  lineHeight: '24px',
  a: {
    color: theme.color.ink
  }
});
