import { Heading, Link, Text } from '@auspost/pvoi-components';
import * as Styled from './styled';
import React from 'react';
import { LOCATIONS } from './constants';
import { analyticsService } from '../../../services';
import { CLICK_ANALYTICS } from '../../../constants/analytics';

export const Locations: React.FC = (): React.ReactElement => {
  return (
    <Styled.Container>
      <Styled.Header>
        <Heading level="h3" as="h2" id="header-locations">
          {LOCATIONS.HEADING}
        </Heading>
      </Styled.Header>
      <Styled.Content>
        <Styled.TextContainer>
          <Text variant="body" weight="regular" as="span">
            {LOCATIONS.PARAGRAPH}
          </Text>
          <Text variant="body" weight="bold" as="span">
            <Link
              id={'voi-location-link'}
              href={LOCATIONS.LINK}
              isExternal={true}
              onClick={() => analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.HELP_CENTRE, LOCATIONS.ANALYTICS)}
            >
              {LOCATIONS.LINK_TEXT}
            </Link>
          </Text>
        </Styled.TextContainer>
      </Styled.Content>
    </Styled.Container>
  );
};
