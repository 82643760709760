export enum Status {
  AnyStatus = 'Any status',
  New = 'New',
  Viewed = 'Viewed'
  // Checked = 'Checked',
  // Flagged = 'Flagged'
}

export enum IDVStatus {
  AnyStatus = 'Any status',
  Successful = 'Successful',
  Failed = 'Failed',
  Invited = 'Invited'
}
