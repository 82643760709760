import React, { MutableRefObject } from 'react';
import MaterialTable, { Column, Query, QueryResult, Options, Components } from '@material-table/core';

interface Props {
  options: Options<object>;
  column: Column<object>[];
  data: object[] | ((query: Query<object>) => Promise<QueryResult<object>>);
  onRowClick?: (id: string) => void;
  components?: Components;
  pageSize?: number;
  tableStyle?: React.CSSProperties;
  tableRef?: MutableRefObject<unknown>;
}

const Table: React.FC<Props> = ({
  options,
  column,
  data,
  onRowClick,
  tableStyle,
  components,
  tableRef
}): React.ReactElement => {
  return (
    <MaterialTable
      tableRef={tableRef}
      style={tableStyle}
      options={options}
      columns={column}
      data={data}
      components={components}
      onRowClick={(event, rowData) => {
        onRowClick(rowData['id']);
      }}
    />
  );
};

export default Table;
