import { Heading, Link, KeyboardKey } from '@auspost/pvoi-components';
import { Divider } from '@auspost/postmaster-react';
import * as Styled from './styled';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem } from './types';
import { HELP_LINK, MENU_HEADING, HELP_LINK_IDV } from '../../pages/Home/constants';
import { AppStateModel } from '@auspost/pvoi-components';

interface Props {
  items: MenuItem[];
  onClose?: () => void;
  appState: AppStateModel;
}

const Menu: React.FC<Props> = ({ items, onClose, appState }): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();

  const onTabKeyPress = (event, isLastItem) => {
    if (event.key === KeyboardKey.TAB && onClose && isLastItem) {
      onClose();
    }
  };

  const onClick = (event, onClick: () => void, url: string, isSelected: boolean) => {
    event.preventDefault();
    onClose && onClose();

    if (!isSelected) {
      onClick();
      navigate(url);
    }
  };

  const isCurrentPage = (targetUrl, nestedUrl = null) => {
    return targetUrl === location.pathname || location.pathname.includes(nestedUrl?.split(':')[0]);
  };

  const isIDV = appState.isIDV;

  return (
    <Styled.Nav>
      <Heading level="h4" as="h1">
        {MENU_HEADING}
      </Heading>
      <Divider />
      <Styled.Menu role="menu">
        {items.map((item, index) => {
          if (appState.type === item.type || !item.type) {
            return (
              <Styled.MenuItem key={item.id} isSelected={isCurrentPage(item.url, item.nestedUrl)} role="menuitem">
                <Link
                  id={item.id}
                  href={item.url}
                  onClick={event => onClick(event, item.onClick, item.url, isCurrentPage(item.url, item.nestedUrl))}
                  aria-current={isCurrentPage(item.url, item.nestedUrl) ? 'page' : null}
                  onKeyDown={event => onTabKeyPress(event, index === items.length - 1)}
                >
                  {item.icon}
                  {item.label}
                </Link>
              </Styled.MenuItem>
            );
          } else {
            return null;
          }
        })}
      </Styled.Menu>
      <Styled.LinkGroup isSelected={isCurrentPage(HELP_LINK.url)}>
        {isIDV ? (
          <>
            <Link id={HELP_LINK_IDV.id} href={HELP_LINK_IDV.url} isExternal={true}>
              {HELP_LINK_IDV.label}
            </Link>
          </>
        ) : (
          <>
            <Link
              id={HELP_LINK.id}
              href={HELP_LINK.url}
              onClick={e => onClick(e, HELP_LINK.onClick, HELP_LINK.url, isCurrentPage(HELP_LINK.url))}
              aria-current={isCurrentPage(HELP_LINK.url) ? 'page' : null}
            >
              {HELP_LINK.label}
            </Link>
          </>
        )}
      </Styled.LinkGroup>
    </Styled.Nav>
  );
};

export default Menu;
