import styled from '@emotion/styled';
import { theme } from '../../theme/theme';
import ButtonNoStyles from '../ButtonNoStyles/ButtonNoStyles';

export const HeaderDropdownButton = styled(ButtonNoStyles)({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.fontSize.xsmall,
  color: theme.color.ink,

  '&:focus': {
    outlineOffset: '8px',
    outline: `1px dotted ${theme.color.slate}`
  },

  '&:focus:not(:focus-visible)': {
    outline: 'none'
  }
});
