import * as Styled from './styled';
import { TableColumn, TableHeader, TableOptions } from './types';
import { theme } from '../../theme/theme';
import { Status, IDVStatus } from './TableStatus/types';

export const STATUS = {
  NEW: {
    label: Status.New,
    color: theme.color.feedback01
  },
  VIEWED: {
    label: Status.Viewed,
    color: theme.color.grey
  },
  SUCCESSFUL: {
    label: IDVStatus.Successful,
    color: theme.color.feedback02
  },
  FAILED: {
    label: IDVStatus.Failed,
    color: theme.color.yellow
  },
  INVITED: {
    label: IDVStatus.Invited,
    color: theme.color.purple
  }
  // CHECKED: {
  //   label: Status.Checked,
  //   color: theme.color.feedback02
  // },
  // FLAGGED: {
  //   label: Status.Flagged,
  //   color: theme.color.feedback03
  // }
};

// material table config
export const column: TableColumn[] = [
  { id: 'surname', title: 'Surname', field: 'surname', cellStyle: Styled.surnameColumnStyle },
  { id: 'givenNames', title: 'Given names', field: 'givenNames', cellStyle: Styled.getCellStyle('20%') },
  { id: 'contact', title: 'Phone no.', field: 'contact', cellStyle: Styled.getCellStyle('17%') },
  { id: 'date', title: 'Date', field: 'date', cellStyle: Styled.getCellStyle('15%') },
  { id: 'referenceNumber', title: 'Reference no.', field: 'referenceNumber', cellStyle: Styled.getCellStyle('15%') },
  { id: 'status', title: 'Status', field: 'status', cellStyle: Styled.getCellStyle('17%') },
  { id: 'actionButton', title: '', field: 'actionButton', cellStyle: Styled.ellipsisColumnStyle }
];

export const columnIDV: TableColumn[] = [
  { id: 'surname', title: 'Surname', field: 'surname', cellStyle: Styled.surnameColumnStyle },
  { id: 'givenNames', title: 'Given names', field: 'givenNames', cellStyle: Styled.getCellStyle('20%') },
  { id: 'contact', title: 'Email', field: 'contact', cellStyle: Styled.emailColumnStyle },
  { id: 'date', title: 'Date', field: 'date', cellStyle: Styled.getCellStyle('15%') },
  { id: 'referenceNumber', title: 'Reference no.', field: 'referenceNumber', cellStyle: Styled.getCellStyle('15%') },
  { id: 'status', title: 'Status', field: 'status', cellStyle: Styled.getCellStyle('17%') },
  { id: 'actionButton', title: '', field: 'actionButton', cellStyle: Styled.ellipsisColumnStyle }
];

export const options: TableOptions = {
  header: true,
  draggable: false,
  search: false,
  sorting: false,
  selection: false,
  toolbar: false,
  paging: false,
  rowStyle: Styled.rowStyle,
  pageSize: 10,
  tableLayout: 'auto'
};

// custom header component config
export const headerList: TableHeader[] = [
  { title: 'Surname', style: Styled.getHeaderStyle('16%', '24px') },
  { title: 'Given names', style: Styled.getHeaderStyle('20%') },
  { title: 'Phone no.', style: Styled.getHeaderStyle('17%'), screenReaderText: 'Phone number' },
  { title: 'Date', style: Styled.getHeaderStyle('15%') },
  { title: 'Reference no.', style: Styled.getHeaderStyle('15%'), screenReaderText: 'Reference number' },
  { title: 'Status', style: Styled.getHeaderStyle('17%') },
  { title: '', style: Styled.ellipsisColumnStyle, screenReaderText: 'More options' }
];

export const headerListIDV: TableHeader[] = [
  { title: 'Surname', style: Styled.getHeaderStyle('16%', '24px') },
  { title: 'Given names', style: Styled.getHeaderStyle('20%') },
  { title: 'Email', style: Styled.getHeaderStyle('17%'), screenReaderText: 'Email' },
  { title: 'Date', style: Styled.getHeaderStyle('15%') },
  { title: 'Reference no.', style: Styled.getHeaderStyle('15%'), screenReaderText: 'Reference number' },
  { title: 'Status', style: Styled.getHeaderStyle('17%') },
  { title: '', style: Styled.ellipsisColumnStyle, screenReaderText: 'More options' }
];
