import styled from '@emotion/styled';
import { theme } from '../../theme/theme';

export const Label = styled.div<{ hasValue: boolean }>(({ hasValue }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: hasValue ? theme.color.ink : theme.color.white,
  svg: {
    fill: hasValue ? theme.color.ink : theme.color.white
  },
  span: {
    marginLeft: '10px',
    marginRight: '37px'
  }
}));

export const Container = styled.div({
  margin: '0 8px',
  position: 'relative'
});

export const Popup = styled.div({
  position: 'absolute',
  zIndex: 1,
  marginTop: '4px'
});
