import React from 'react';
import { DropdownListItem } from '../Dropdown/types';
import Dropdown from '../Dropdown/Dropdown';
import MoreButton from '../MoreButton/MoreButton';
import { DomService } from '../../services/dom/domService';

interface Props {
  id: string;
  getListItems: (onListItemClick: () => void) => DropdownListItem[];
}

const MoreDropdown: React.FC<Props> = ({ id, getListItems }): React.ReactElement => {
  const renderListItems = (onListItemClick: () => void) => getListItems(onListItemClick);
  const moreDropdownID = `${id}-moreDropdown`; // Create the ID to be passed into the dropdown object
  const moreDropdownListID = `${moreDropdownID}-list`; // Use this to get the list by ID

  const scrollToDropdown = () => {
    const moreDropdownElement = document.getElementById(moreDropdownListID);
    if (moreDropdownElement && !DomService.isVisible(moreDropdownElement, 0, 7.5)) {
      moreDropdownElement.scrollIntoView();
    }
  };

  return (
    <Dropdown
      id={moreDropdownID}
      renderButton={props => (
        <MoreButton
          aria-label="more options"
          {...props}
          onClick={e => {
            e.stopPropagation();
            props.onClick(e);
            setTimeout(scrollToDropdown);
          }}
        />
      )}
      renderListItems={renderListItems}
      width="120px"
    />
  );
};

export default MoreDropdown;
