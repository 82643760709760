import React from 'react';
import * as Styled from './styled';
import { Stack, AppStateModel, Button, Heading, Text, Notification, Divider, SendInvitationService } from '../../';
import {
  REVIEW_PAGE_HEADING,
  CP_HINT,
  SURNAME,
  GIVEN_NAME,
  EMAIL,
  INVITATION_DETAILS_HEADING,
  INVITATION_SENT_HEADING,
  INVITATION_SENT_HINT,
  INVITATION_SENT_DETAILS,
  INVITATION_SENT_SUBTITLE,
  INVITATION_UNABLE_SEND,
  SEND_ANOTHER_HEADING,
  GO_TO_IDENTITY_CHECK_HEADING,
  BACK_ARIA_LABEL,
  BACK,
  EDIT_ARIA_LABEL,
  CANCEL_ARIA_LABEL
} from './constants';
import { EditIcon, Link } from '@auspost/pvoi-components';
import { mfaErrorCodes } from "pvoi-ui/src/services/mfaService/constants";
interface Props {
  appState: AppStateModel;
  sendInvitationService: SendInvitationService;
  onBack: () => void;
  onSendAnother: () => void;
  onGoToDashboard: () => void;
  onCancel: () => void;
}

const InvitationDetailsPage: React.FC<Props> = ({
  appState,
  onBack,
  onSendAnother,
  onGoToDashboard,
  sendInvitationService,
  onCancel
}): React.ReactElement => {
  const [sendInvitationError, setSendInvitationError] = React.useState(false);
  const [invitationSent, setInvitationSent] = React.useState(false);
  const [clmName, setClmName] = React.useState('');
  const [isSaveButtonLoading, setSaveButtonLoading] = React.useState<boolean>(false);
  const [surname, setSurname] = React.useState('');
  const [givenName, setGivenName] = React.useState('');
  const [email, setEmail] = React.useState('');

  React.useEffect(() => {
    setClmName(appState.selectedClm.clmName);
    setSurname(appState.invitationDetails.surname);
    setGivenName(appState.invitationDetails.firstName);
    setEmail(appState.invitationDetails.email);
  }, []);

  const onClick = event => {
    event.preventDefault();
    onBack && onBack();
  };

  const resetInvitationDetails = () => {
    appState.invitationDetails.email = '';
    appState.invitationDetails.surname = '';
    appState.invitationDetails.firstName = '';
  }

  const handleSend = (): void => {
    setSaveButtonLoading(true);
    void sendInvitationService
      .sendInvitation(appState.selectedClm.id, appState.selectedClm.defaultClientId, givenName, surname, email)
      .then(() => {
        setInvitationSent(true);
        resetInvitationDetails();
        setSaveButtonLoading(false);
      })
      .catch((err) => {
        if (mfaErrorCodes.includes(err?.[0]?.code)) {
          resetInvitationDetails();
          return;
        }
        setSendInvitationError(true);
        setSaveButtonLoading(false);
      });
  };

  return (
    <Styled.Container>
      {invitationSent ? (
        <>
          <Heading level="h3">{INVITATION_SENT_HEADING}</Heading>
          <Notification
            type={'notice'}
            status={'success'}
            message={INVITATION_SENT_HINT + givenName + ' ' + surname + ' from ' + clmName}
            isDismissible={false}
          />
          <Styled.DetailsContainer>
            <Stack space="large">
              <Heading level="h4">{INVITATION_SENT_SUBTITLE}</Heading>
              <Text variant="body" weight="regular" as="p">
                {INVITATION_SENT_DETAILS}
              </Text>
            </Stack>
          </Styled.DetailsContainer>
          <Styled.ButtonGroup>
            <Button id="sendAnother-button" variant="primary" size="medium" type="submit" onClick={onSendAnother}>
              {SEND_ANOTHER_HEADING}
            </Button>
            <Button id="checkDetails-button" variant="secondary" size="medium" type="reset" onClick={onGoToDashboard}>
              {GO_TO_IDENTITY_CHECK_HEADING}
            </Button>
          </Styled.ButtonGroup>
        </>
      ) : (
        <>
          <Link id="backToLink" href="" variant="back" onClick={onClick} aria-label={BACK_ARIA_LABEL}>
            {BACK}
          </Link>
          <Heading level="h3">{REVIEW_PAGE_HEADING}</Heading>
          {sendInvitationError ? (
            <>
              <Notification
                type={'alert'}
                status={'error'}
                message={INVITATION_UNABLE_SEND + givenName + ' ' + surname + ' from ' + clmName}
                isDismissible={false}
              />
            </>
          ) : (
            <>
              <Notification type={'notice'} status={'info'} message={CP_HINT + clmName} isDismissible={false} />
            </>
          )}
          <Styled.DetailsContainer>
            <Styled.HeadingContainer>
              <Heading level="h4">{INVITATION_DETAILS_HEADING}</Heading>
              <Styled.EditButton
                id="edit-button"
                type="button"
                onClick={onBack}
                role="button"
                aria-label={EDIT_ARIA_LABEL}
              >
                <EditIcon />
              </Styled.EditButton>
            </Styled.HeadingContainer>
            <Styled.Divider>
              <Divider />
            </Styled.Divider>
            <Stack space="medium">
              <Styled.DetailsRow>
                <Text variant="body" weight="regular" as="p">
                  {SURNAME}
                </Text>
                <Text variant="body" weight="medium" as="strong">
                  {surname}
                </Text>
              </Styled.DetailsRow>
              <Styled.DetailsRow>
                <Text variant="body" weight="regular" as="p">
                  {GIVEN_NAME}
                </Text>
                <Text variant="body" weight="medium" as="strong">
                  {givenName}
                </Text>
              </Styled.DetailsRow>
              <Styled.DetailsRow>
                <Text variant="body" weight="regular" as="p">
                  {EMAIL}
                </Text>
                <Text variant="body" weight="medium" as="strong">
                  {email}
                </Text>
              </Styled.DetailsRow>
            </Stack>
          </Styled.DetailsContainer>
          <Styled.ButtonGroup>
            <Button
              id="send-button"
              variant="primary"
              size="medium"
              type="submit"
              isLoading={isSaveButtonLoading}
              onClick={() => handleSend()}
            >
              Send invite
            </Button>
            <Button
              id="cancel-button"
              variant="secondary"
              size="medium"
              type="reset"
              onClick={onCancel}
              aria-label={CANCEL_ARIA_LABEL}
            >
              Cancel
            </Button>
          </Styled.ButtonGroup>
        </>
      )}
    </Styled.Container>
  );
};

export default InvitationDetailsPage;
