import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../constants/routes.enum';
import { Input } from '@auspost/pvoi-components';

const StubPage: React.FC = () => {
  const navigate = useNavigate();
  const [jwtSubject, setJwtSubject] = React.useState('');

  React.useEffect(() => {
    if (window.location.href.indexOf('-pdev.npe') < 0 && window.location.href.indexOf('localhost') < 0) {
      navigate(AppRoute.Home);
    }
  }, []);

  const onClick = (event, jwtSubject?: string) => {
    event.preventDefault();
    sessionStorage.setItem('mock-jwt-subject', jwtSubject);
    navigate(AppRoute.Home);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h1>Choose a user to continue</h1>
        <a href="" onClick={event => onClick(event, '200-200-C1000')}>
          One CLM - Admin
        </a>
        <br />
        <a href="" onClick={event => onClick(event, '200-200-C1001')}>
          One CLM - Normal User
        </a>
        <br />
        <a href="" onClick={event => onClick(event, '200-200-C2001')}>
          Two CLMs - Normal Users
        </a>
        <br />
        <a href="" onClick={event => onClick(event, '200-200-C3001')}>
          One IDV - Normal User
        </a>
      </div>
      <br />
      <br />
      or you can
      <br />
      <br />
      <div style={{ display: 'flex', flexDirection: 'column', width: '200px' }}>
        <Input
          id="cookie-input"
          type="text"
          placeholder="Enter a jwt value"
          autoComplete="off"
          onChange={e => setJwtSubject(e.target.value)}
        />
        <br />
        <button onClick={event => onClick(event, jwtSubject)}>Set Pvoi Role</button>
      </div>
    </>
  );
};

export default StubPage;
