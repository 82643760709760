import { envConfig } from '../../../config/env.config';
import { CLICK_ANALYTICS } from '../../../constants/analytics';

export const USER_GUIDES = {
  HEADING: 'User guides',
  PARAGRAPH: 'For step-by-step instructions and helpful information, our User guides are a great place to start.',
  CONTENT: [
    {
      LINK: envConfig.pdf.userGuide,
      LINK_ARIA: 'Property V O I Report User Guides',
      LINK_TEXT: 'Property VOI Report User Guides',
      TEXT: `A detailed guide designed to help you log in to the Property VOI portal and use it to locate, view and
              manage your client's VOI reports.`,
      ANALYTICS: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.USER_GUIDE.VOI_USER_GUIDE
    },
    {
      LINK: envConfig.pdf.diyUserGuide,
      LINK_ARIA: 'Do it yourself V O I User Guides',
      LINK_TEXT: 'Do it yourself VOI User Guide',
      TEXT: `A detailed guide designed to help you use the DIY verification tool to record, upload and manage identity verification reports and data.`,
      ANALYTICS: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.USER_GUIDE.DIY_USER_GUIDE
    },
    {
      LINK: 'https://auspost.com.au/content/dam/auspost_corp/media/documents/how-to-verify-your-identity-with-australia-post.pdf',
      LINK_ARIA: 'How to verify your identity with Australia Post for your clients',
      LINK_TEXT: 'How to verify your identity with Australia Post (for your clients)',
      TEXT: 'A handy one pager to give your clients on how they can do their own VOI check at their local post office.',
      ANALYTICS: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.USER_GUIDE.CLIENT_VOI
    },
    {
      LINK: envConfig.pdf.searchTips,
      LINK_ARIA: 'Search tips for V O I reports',
      LINK_TEXT: 'Search tips for VOI reports',
      TEXT: 'A guide to help you find your reports in the Property VOI portal.',
      ANALYTICS: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.USER_GUIDE.SEARCH_TIPS
    }
  ],
  BANNER: {
    TEXT_1: `If you can’t find your client’s VOI report, <a id="userGuideContactUs" href="${envConfig.baseUrl}help#header-contact-us">email us</a> with your unique 5-digit client number (found in the barcode of your VOI form).`,
    TEXT_2: 'Don’t tell your client to return to the Post Office as this will delay the process.'
  }
};

export const FREQUENTLY_ASKED = {
  SETUP: {
    HEADING: 'Password management',
    CONTENTS: [
      {
        QUESTION: 'How do I reset my password?',
        ANSWER: `If you can log in to your portal, you can change your password in account settings.
          <ol>
          <li property="123">In the portal, navigate to the top right of the header.</li>
          <li key="reset-pw-2">Select your profile icon and choose <b>Account settings</b>.</li>
          <li key="reset-pw-3">Select <b>Edit</b> in the password section.</li>
          <li key="reset-pw-4">Follow the prompts to reset your password.</li></ol>`
      },
      {
        QUESTION: 'What if I forgot my password?',
        ANSWER: `If you forget your password, you won’t be able to access the portal.
Go to the Property VOI portal login page, select <b>Forgotten your password?</b> and follow the prompts.`
      }
    ]
  },
  USE: {
    HEADING: 'Using the portal',
    CONTENTS: [
      {
        QUESTION: 'How do I manage team members in the portal?',
        ANSWER: `Only users with administrator access can add, remove and edit team member access.
          <ol>
          <li>In the portal, navigate to the top right of the header and select your organisation name.</li>
          <li>From the dropdown menu, select <b>Manage users</b>.</li>
          <li>Choose a team member and manage their access with the options available.</li>
</ol>
Refer to <b>Section 3</b> of the <a id="voiUserGuide-1" target="_blank" href='${envConfig.pdf.userGuide}' aria-label='Property V O I Report User Guide'>Property VOI portal User Guide</a> (PDF) for more details.`
      },
      {
        QUESTION: 'How do I edit client details?',
        ANSWER: `Email us at <a id="landTitleEmail-1" href="mailto:PropertyVOI@auspost.com.au" aria-label='Property V O I @ aus post dot com dot A U'>PropertyVOI@auspost.com.au</a>. Let us know your 5-digit client number and the details of your requested edits.`
      },
      {
        QUESTION: `How do I find the report I’m looking for?`,
        ANSWER: `Your most recent reports will show up first. Use the search and filter functions to find specific reports. You can search by:
    <ul>
    <li>name</li>
    <li>phone number</li>
    <li>reference number</li>
    <li>dates</li>
    <li>status</li>
    </ul>
    Refer to <b>Section 8</b> of the <a id="voiUserGuide-2" target="_blank" href='${envConfig.pdf.userGuide}' aria-label='Property V O I portal User Guide'>Property VOI portal User Guide</a> (PDF) for more details.`
      },
      {
        QUESTION: 'Why can’t I export the VOI report list?',
        ANSWER: `You can only export VOI reports from search results. Use the search and filter functionalities to narrow down your report list for exporting.<br/><br/>
You can only export a maximum of <b>200</b> records at a time.`
      },
      {
        QUESTION: 'I’ve unsubscribed from email notifications. How do I opt back in?',
        ANSWER: `Email us at <a id="landTitleEmail-2" href="mailto:PropertyVOI@auspost.com.au" aria-label='Property V O I @ aus post dot com dot A U'>PropertyVOI@auspost.com.au</a>. Let us know your 5-digit client number and we can update your notification preferences.`
      }
    ]
  }
};

export const LOCATIONS = {
  HEADING: 'VOI check locations',
  PARAGRAPH: 'Use our locator tool to check which Post Offices offer VOI checks.',
  LINK: 'https://auspost.com.au/locate/',
  LINK_TEXT: 'Post Office VOI locations',
  ANALYTICS: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.LOCATIONS
};

export const CONTACT_US = {
  HEADING: 'Contact us',
  PARAGRAPH: `We're here to help.`,
  PHONE: {
    HEADING: 'Phone',
    NUMBER: '1300 364 543',
    TIME: `9am - 6pm (AEST) Monday - Friday`,
    ANALYTICS: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.CONTACT_US.PHONE
  },
  EMAIL: {
    HEADING: 'Email',
    EMAIL_ADDRESS: 'PropertyVOI@auspost.com.au',
    ANALYTICS: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.CONTACT_US.EMAIL
  }
};

export const SR_TIME = 'contact time Monday to Friday 9am-6pm Australia Eastern Standard Time';
export const SR_EMAIL = 'property V O I @ aus post dot com dot A U';
