import { CheckDetailsItem } from './types';
import {
  CheckType,
  Watchlist,
  WatchlistSource,
  WatchlistSourceCategory
} from '../../../../../pvoi-components/src/services/reportsBaseService/types';

export const INVITE_DETAILS_ITEMS = (
  refNumber: string,
  inviteDate: string,
  surname: string,
  givenName: string,
  email: string
): CheckDetailsItem[] => {
  return [
    {
      id: 'reference-number',
      label: 'Reference number',
      value: refNumber
    },
    {
      id: 'invite-date',
      label: 'Invite date',
      value: inviteDate
    },
    {
      id: 'surname',
      label: 'Surname',
      value: surname
    },
    {
      id: 'given-name',
      label: 'Given name(s)',
      value: givenName
    },
    {
      id: 'email',
      label: 'Email',
      value: email
    }
  ];
};

export const CHECK_DETAILS_ITEMS = (
  verificationDate: string,
  result: string,
  checkTypes: CheckType[]
): CheckDetailsItem[] => {
  const checkDetailItems: CheckDetailsItem[] = [];

  checkDetailItems.push({
    id: 'verification-date',
    label: 'Verification date',
    value: verificationDate
  });

  checkDetailItems.push({
    id: 'result',
    label: 'Result',
    value: result
  });

  if (checkTypes && checkTypes.length > 0) {
    checkDetailItems.push({
      id: 'checkType',
      label: 'Check Type',
      value: checkTypes.join(',')
    });
  }

  return checkDetailItems;
};

const getCategoryValues = (category: WatchlistSourceCategory, watchlistSources?: WatchlistSource[]): string[] => {
  return watchlistSources
    ? watchlistSources.filter(source => source.category === category).map(source => source.name)
    : [];
};

export const VERIFIED_DETAILS_ITEMS = (
  surname: string | undefined,
  givenName: string | undefined,
  dob: string | undefined,
  addressVerified: boolean | undefined,
  formattedAddress: string | undefined,
  watchlist: Watchlist | undefined,
  isOver18: boolean | undefined
): CheckDetailsItem[] => {
  const detailsItems: CheckDetailsItem[] = [];

  if (surname) {
    detailsItems.push({
      id: 'verified-surname',
      label: 'Surname',
      value: surname
    });
  }

  if (givenName) {
    detailsItems.push({
      id: 'verified-given-name',
      label: 'Given name(s)',
      value: givenName
    });
  }

  if (dob) {
    detailsItems.push({
      id: 'verified-dob',
      label: 'Date of birth',
      value: dob
    });
  }

  if (isOver18 !== undefined) {
    detailsItems.push({
      id: 'age-over-18',
      label: 'Age over 18',
      value: isOver18 ? 'SUCCESSFUL' : 'FAILED'
    });
  }

  if (formattedAddress !== undefined) {
    detailsItems.push({
      id: 'verified-address',
      label: addressVerified ? 'Verified address' : 'Unverified address',
      value: formattedAddress
    });
  }

  if (watchlist?.check_performed) {
    const pepListValues = getCategoryValues(WatchlistSourceCategory.PEP, watchlist?.found_sources);
    const sanctionListValues = getCategoryValues(WatchlistSourceCategory.SANCTIONS, watchlist?.found_sources);
    const pepAndSanctionsListValues = getCategoryValues(
      WatchlistSourceCategory.PEP_AND_SANCTIONS,
      watchlist?.found_sources
    );

    if (pepListValues.length || sanctionListValues.length || pepAndSanctionsListValues.length) {
      if (pepListValues.length) {
        detailsItems.push({
          id: 'pep-list',
          label: 'PEP',
          listValues: pepListValues
        });
      }
      if (sanctionListValues.length) {
        detailsItems.push({
          id: 'sanctions-list',
          label: 'Sanctions',
          listValues: sanctionListValues
        });
      }
      if (pepAndSanctionsListValues.length) {
        detailsItems.push({
          id: 'pep-and-sanctions-list',
          label: 'PEP and Sanctions',
          listValues: pepAndSanctionsListValues
        });
      }
    } else {
      detailsItems.push({
        id: 'pep-and-sanctions-not-found',
        label: 'PEP and Sanctions',
        value: 'Not Found'
      });
    }
  }
  return detailsItems;
};

export const DETAILS_HEADING = 'Results';
export const BACK = 'BACK';
export const BACK_ARIA_LABEL = 'back to results list';

export const ADDRESS_NOTE = 'What does ‘Verified’ and ‘Unverified’ mean as a label next to address?';
export const ADDRESS_NOTE_ITEM1 =
  'If the address provided by the customer is found in the data sources it will be marked as verified in the portal.';
export const ADDRESS_NOTE_ITEM2 =
  'If the address provided by the customer is NOT found in the data sources it will be marked as unverified in the portal.';
export const PEP_AND_SANCTIONS_NOTE = 'What will I see next to the PEP and Sanctions check?';
export const PEP_AND_SANCTIONS_DESC =
  'If a PEP and Sanctions check has taken place you will see either ‘Not found’ or if ‘Found’ ' +
  'the specific watch list name(s) and the associated categories alongside.';
