import React from 'react';
import * as Styled from './styled';
import { useOnPopupClose } from '../../hooks/useOnPopupClose';
import { KeyboardKey } from '../../constants/keyboardKeys.enum';
import { DropdownListItem } from './types';

interface Props {
  id: string;
  renderButton: (buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>, isClicked: boolean) => React.ReactElement;
  renderListItems: (onListItemClick: () => void) => DropdownListItem[];
  width?: string;
  hasTriangleTip?: boolean;
}

const Dropdown: React.FC<Props> = ({
  id,
  renderListItems,
  renderButton,
  width,
  hasTriangleTip = false
}): React.ReactElement => {
  const [isClicked, setClicked] = React.useState<boolean>(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const ButtonComponent: React.ReactElement = renderButton(
    {
      id: `${id}-button`,
      onClick: () => {
        setClicked(!isClicked);
      },
      'aria-expanded': isClicked,
      'aria-haspopup': true
    },
    isClicked
  );

  const listItems = renderListItems(() => setClicked(false));

  // TODO add shift tab handling for first item in the list
  const onTabKeyPress = (event: React.KeyboardEvent, itemIndex: number): void => {
    if (!event.shiftKey && event.key === KeyboardKey.TAB && itemIndex === listItems.length - 1) {
      setClicked(false);
    }
  };

  useOnPopupClose(dropdownRef, (): void => setClicked(false));

  return (
    <Styled.Container id={id} ref={dropdownRef}>
      {ButtonComponent}
      {isClicked && (
        <Styled.ListContainer id={`${id}-list`} width={width} hasTriangleTip={hasTriangleTip}>
          {listItems.map((item, index) => (
            <Styled.ListItem id={`${id}-listItem-${item.id}`} key={item.id} onKeyDown={e => onTabKeyPress(e, index)}>
              {item.element}
            </Styled.ListItem>
          ))}
        </Styled.ListContainer>
      )}
    </Styled.Container>
  );
};

export default Dropdown;
