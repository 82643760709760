import styled from '@emotion/styled';
import React from 'react';
import { theme } from '../../theme/theme';

// inline styles to avoid creating a div wrapper just for styling
export const getButtonCustomStyle = (selected: boolean): React.CSSProperties => {
  return {
    minWidth: '142px',
    padding: '10px 14px',
    backgroundColor: selected ? theme.color.slate : theme.color.white
  };
};

export const Label = styled.span<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  color: selected ? theme.color.ink : theme.color.white,
  svg: {
    fill: selected ? theme.color.ink : theme.color.white
  },
  '> span': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    span: {
      marginLeft: '10px',
      marginRight: '18px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}));
