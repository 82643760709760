export enum MfaSuccessCodes {
  SUCCESS = 'SUCCESS',
  FINAL_ATTEMPT = 'FINAL_ATTEMPT'
}

export enum MfaErrorCodes {
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  NEW_ACCOUNT_LOCKED = 'NEW_ACCOUNT_LOCKED',
  MFA_EXPIRED = 'MFA_EXPIRED',
  MFA_REQUIRED = 'MFA_REQUIRED',
  FINAL_ATTEMPT = 'FINAL_ATTEMPT'
}

export const mfaErrorCodes = Object.values(MfaErrorCodes) as string[];
