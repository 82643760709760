import React from 'react';
import * as Styled from './styled';

interface Props {
  id: string;
  type: string;
  placeholder?: string;
  autoComplete?: 'on' | 'off';
  onChange?: (event) => void;
  onKeyDown?: (event) => void;
  max?: string;
  min?: string;
  onClick?: (event) => void;
  required?: boolean;
  ariaInvalid?: boolean;
  value?: string;
  onBlur?: (event) => void;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
}

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => (
  <Styled.Input
    name={props.id}
    {...props}
    value={props.value}
    aria-invalid={props.ariaInvalid}
    isInvalid={props.ariaInvalid}
    ref={ref}
  />
));

Input.displayName = 'Input';
