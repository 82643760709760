import React from 'react';
import * as Styled from './styled';
import CompanyDetailsSelect from './CompanyDetailsSelect/CompanyDetailsSelect';
import CompanyDetailsDropdown from './CompanyDetailsDropdown/CompanyDetailsDropdown';
import { ClmListItem } from './types';

interface Props {
  clmList: ClmListItem[];
  selectedClm: string;
  onSelect: (clmId: string) => void;
  isAdmin: boolean;
}

const CompanyDetails: React.FC<Props> = ({ clmList, selectedClm, onSelect, isAdmin }): React.ReactElement => {
  const isArrayEmpty = array => {
    return array.length === 0;
  };

  if (isArrayEmpty(clmList)) {
    return (
      <div id="companyDetails">
        <Styled.Text data-testid="companyDetailText" id="companyDetailText"></Styled.Text>
      </div>
    );
  }
  return (
    <div id="companyDetails">
      {clmList.length > 1 ? (
        <CompanyDetailsSelect
          id="companyDetailsSelect"
          isAdmin={isAdmin}
          clmList={clmList}
          selectedClm={selectedClm}
          onSelect={onSelect}
        />
      ) : isAdmin ? (
        <CompanyDetailsDropdown id="companyDetailsDropdown" clmListItem={clmList[0]} />
      ) : (
        <Styled.Text data-testid="companyDetailText" id="companyDetailText">{`${clmList[0].clmName || ''} - ${
          clmList[0].clmId || ''
        }`}</Styled.Text>
      )}
    </div>
  );
};

export default CompanyDetails;
