import React from 'react';
import { Spinner, Interceptor, OUTAGE_CONTENT, authenticationService } from '@auspost/pvoi-components';
import { profileService } from '../services/profileService/profileService';
import { useAppContext } from '../context';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../constants/routes.enum';
import { httpService } from '../services';
import { mfaErrorCodes, MfaErrorCodes } from '../services/mfaService/constants';
import { AxiosError } from 'axios';

interface Props {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<Props> = ({ element }): React.ReactElement => {
  const appState = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (
      location.pathname === AppRoute.Outage ||
      !!location.pathname.match(AppRoute.ReportDetails.replace(/\/:id/, ''))
    ) {
      navigate(AppRoute.Home);
    }

    if (!appState.organisations.length) {
      setLoading(true);

      profileService
        .getProfile()
        .then(({ organisations }) => {
          if (!organisations?.length) {
            navigate(AppRoute.Outage, { replace: true });
          }

          appState.organisations = organisations;
          appState.setSelectedClm(organisations[0].clmId);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // check if touch screen is enabled for the device
    // used to detect if user is using tablet - show different view on Report Details page
    appState.isTablet = 'ontouchstart' in window;

    return () => {
      setLoading(true);
    };
  }, []);

  const redirectToOutage = (status: number, url: string): void => {
    appState.error = {
      status: status,
      url: url.split('/')[3],
      header: OUTAGE_CONTENT.INTERNAL_SERVER.HEADER,
      content: OUTAGE_CONTENT.INTERNAL_SERVER.CONTENT
    };

    if (status === 403) {
      appState.error.header = OUTAGE_CONTENT.FORBIDDEN.HEADER;
      appState.error.content = OUTAGE_CONTENT.FORBIDDEN.CONTENT;
    }

    navigate(AppRoute.Outage);
  };

  const redirectToMfa = (status: number, url?: string, errors?: AxiosError[]) => {
    const mfaError = errors?.find(error => mfaErrorCodes.includes(error.code));
    if (status === 401 && MfaErrorCodes[mfaError?.code]) {
      const accountLocked = mfaError?.code === MfaErrorCodes.ACCOUNT_LOCKED;
      navigate(AppRoute.MultiFactorAuth, { replace: true, state: { accountLocked } });
      return true;
    }
    return false;
  };

  return (
    <>
      <Interceptor
        httpService={httpService}
        redirectToOutage={redirectToOutage}
        redirectToMfa={redirectToMfa}
        logoutCallback={() => authenticationService.logout()}
        withCredentials={true}
      />
      {isLoading ? <Spinner colour="secondary" variant="full-page" /> : element}
    </>
  );
};

export default PrivateRoute;
