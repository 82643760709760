export const theme = {
  color: {
    primary: '#DC1928',
    white: '#FFFFFF',
    grey: '#ACACAF',
    smoke: '#E2E2E2',
    cloudy: '#EEEEEE',
    shell: '#F5F5F5',
    ink: '#212129',
    inkSecondary: '#4D4D54',
    inkSubheading: '#6D6D72',
    inkBorder: '#736D6C',
    inkText: '313131',
    ash: '#919194',
    slate: '#31313D',
    feedback01: '#3587DA',
    feedback02: '#1D964F',
    feedback03: '#EDA51F',
    feedback04: '#D61834',
    feedbackAa04: '#BD152E',
    errorRed: '#B61827',
    purple: '#7D5FDE',
    yellow: '#EDA51F',
    pageBackground: '#F3F3F3'
  },
  fontFamily: {
    heading: 'ap-type-pro-display',
    default: 'ap-type-pro-text'
  },
  fontSize: {
    large: '18px',
    default: '16px',
    small: '14px',
    xsmall: '12px',
    subtitle: '10px'
  },
  fontWeight: {
    regular: 400,
    medium: 600,
    bold: 800
  },
  spacing: {
    spaceM: '24px'
  },
  shadow: {
    shadow1: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    shadow2: '0px -1px 25px rgba(0, 0, 0, 0.12)',
    shadow3: '0px 9px 40px rgba(0, 0, 0, 0.16)',
    shadow4: '0px 10px 15px rgba(0, 0, 0, 0.1)',
    shadow5: '0px 10px 30px rgba(0, 0, 0, 0.3)',
    shadow6: '0 8px 24px rgba(0, 0, 0, 0.15)'
  }
};
