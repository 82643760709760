import React from 'react';
import { ListItem as PostmasterListItem} from '@auspost/postmaster-react';
import { ListItemProps } from './ListItem.types';

const ListItem: React.FC<ListItemProps> = ({ children, ...props }): React.ReactElement => {
    return (
        <PostmasterListItem {...props}>
            {children}
        </PostmasterListItem>
    );
};
export default ListItem;
