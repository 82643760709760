import React from 'react';
import * as Styled from './styled';

interface Props {
  children: React.ReactElement;
}

const Sidebar: React.FC<Props> = ({ children }): React.ReactElement => {
  return <Styled.Container>{children}</Styled.Container>;
};

export default Sidebar;
