import { GetReportsResponse } from './types';
import { format } from 'date-fns';
import { ReportList, Reports, ReportMeta } from '../reportsBaseService/types';
import { ReportsBaseService } from '../reportsBaseService/reportsBaseService';
import { AxiosStatic } from 'axios';
import { Status } from '../../components';

export class ReportsService extends ReportsBaseService {
  private readonly reportsLimit: number = 200;

  constructor(httpService: AxiosStatic) {
    super(httpService);
  }

  getReports(
    clmId: string,
    searchTerm?: string,
    dateFrom?: Date,
    dateTo?: Date,
    status?: Status,
    limit = this.reportsLimit
  ): Promise<ReportList> {
    const headers = this.getReportsFilter(searchTerm, dateFrom, dateTo, status);
    return this.httpService
      .get(this.reportsEndpoint, {
        params: {
          limit: limit
        },
        headers: {
          clm_id: clmId,
          ...headers
        },
        responseType: 'json'
      })
      .then(response => this.mapGetReports(response.data))
      .catch(error => Promise.reject(error.response.data.errors));
  }

  exportReports(
    clmId: string,
    searchTerm?: string,
    dateFrom?: Date,
    dateTo?: Date,
    status?: Status,
    limit = this.reportsLimit
  ): Promise<void> {
    const headers = this.getReportsFilter(searchTerm, dateFrom, dateTo, status);
    headers.Accept = 'application/xlsx';

    return this.httpService
      .get(this.reportsEndpoint, {
        params: {
          limit: limit
        },
        headers: {
          clm_id: clmId,
          ...headers
        },
        responseType: 'blob'
      })
      .then(response => void this.handleDownload(response, 'xlsx'))
      .catch(error => Promise.reject(error.response.data.errors));
  }

  private getReportsFilter(searchTerm?: string, dateFrom?: Date, dateTo?: Date, status?: Status) {
    const headers: { filter?: string; Accept: string } = {
      filter: '',
      Accept: ''
    };

    if (searchTerm?.trim()) {
      headers.filter = `search=${searchTerm.trim()}`;
    }

    if (dateFrom && dateTo) {
      const dateRange = `from=${format(this.convertToAValidMinDateForDynamoDb(dateFrom), 'yyyy-MM-dd')};to=${format(
        this.convertToAValidMaxDateForDynamoDb(dateTo),
        'yyyy-MM-dd'
      )}`;
      headers.filter = headers.filter ? `${headers.filter};${dateRange}` : dateRange;
    }

    if (status) {
      const statuses = `status=${status.toLocaleUpperCase()}`;
      headers.filter = headers.filter ? `${headers.filter};${statuses}` : statuses;
    }

    if (!headers.filter) {
      delete headers.filter;
    }

    return headers;
  }

  private mapGetReports(response: GetReportsResponse): ReportList {
    const reports = response.data.reports.map(report => {
      return this.mapReport(report);
    }) as Reports[];

    const meta = {
      availableItems: response.data.meta.items_available,
      returnedItems: response.data.meta.items_returned,
      orgName: response.data.meta.organisation_name || ''
    } as ReportMeta;

    return {
      meta,
      reports
    };
  }

  private convertToAValidMinDateForDynamoDb(date: Date): Date {
    const minDate = new Date(2014, 4, 15);
    return date.getTime() < minDate.getTime() ? minDate : date;
  }

  private convertToAValidMaxDateForDynamoDb(date: Date): Date {
    const maxDate = new Date(2150, 5, 19);
    return date.getTime() > maxDate.getTime() ? maxDate : date;
  }
}
