import { analyticsService } from '../../../services';
import { CLICK_ANALYTICS } from '../../../constants/analytics';
import React from 'react';

export const useHelpAnalytics = (trackList: { id: string; interactionDescription: string }[]): void => {
  React.useLayoutEffect(() => {
    trackList.map(({ id, interactionDescription }) => {
      document.getElementById(id).onclick = () =>
        analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.HELP_CENTRE, interactionDescription);
    });
  }, []);
};
