import React from 'react';
import * as Styled from './styled';
import ResetIcon from '../icons/ResetIcon';
import { Status } from '../ReportsList/TableStatus/types';
import DateRangeWithCustomButton from '../DateRangeWithCustomButton/DateRangWithCustomButton';
import * as tableConfig from '../ReportsList/tableConfig';
import SearchInput from '../SearchInput/SearchInput';
import StatusDropdown from '../StatusDropdown/StatusDropdown';
import { CLICK_ANALYTICS } from 'pvoi-ui/src/constants/analytics';
import { AppStateModel } from '../../models/appState.model';
import { AnalyticsService } from '../../services/analytics/analyticsService';

interface Props {
  onSearch: () => void;
  analyticsService: AnalyticsService;
  appState: AppStateModel;
  showStatusFilter?: boolean;
  placeHolder?: string;
}

const SearchBar: React.FC<Props> = ({
  onSearch,
  analyticsService,
  appState,
  placeHolder,
  showStatusFilter = true
}): React.ReactElement => {
  const [showResetButton, setShowResetButton] = React.useState<boolean>(appState.isSearchApplied);
  const [searchInputValue, setSearchInputValue] = React.useState<string>(appState.searchTerm);
  const inputRef = React.useRef<HTMLInputElement>();

  const onResetButtonClick = (): void => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.SEARCH, CLICK_ANALYTICS.INTERACTION.SEARCH_FILTER.RESET);

    appState.searchTerm = '';
    appState.searchDateRange = {
      from: null,
      to: null
    };
    appState.searchStatus = null;

    setSearchInputValue('');

    inputRef.current?.focus();
    performSearch('', null, null, null);
  };

  const onInputChange = (newValue: string): void => {
    appState.searchTerm = isValidInputValue(newValue) ? newValue : '';
    setSearchInputValue(newValue);
    setShowResetButton(isValidInputValue(newValue));
  };

  const onSearchClick = (): void => {
    analyticsService.trackClick(
      CLICK_ANALYTICS.CATEGORY.SEARCH,
      CLICK_ANALYTICS.INTERACTION.SEARCH_FILTER.SEARCH_BUTTON
    );

    performSearch(
      appState.searchTerm,
      appState.searchDateRange.from,
      appState.searchDateRange.to,
      appState.searchStatus
    );
  };

  const onDateFilterApply = (dateFrom: Date, dateTo: Date): void => {
    analyticsService.trackClick(
      CLICK_ANALYTICS.CATEGORY.SEARCH,
      CLICK_ANALYTICS.INTERACTION.SEARCH_FILTER.DATE_RANGE_APPLY
    );

    appState.searchDateRange.from = dateFrom;
    appState.searchDateRange.to = dateTo;

    performSearch(
      appState.searchTerm,
      appState.searchDateRange.from,
      appState.searchDateRange.to,
      appState.searchStatus
    );
  };

  const onDateFilterCancel = (): void => {
    analyticsService.trackClick(
      CLICK_ANALYTICS.CATEGORY.SEARCH,
      CLICK_ANALYTICS.INTERACTION.SEARCH_FILTER.DATE_RANGE_CANCEL
    );
  };

  const onStatusFilterSelect = (value: string): void => {
    const isValidStatus = value !== Status.AnyStatus;

    appState.searchStatus = isValidStatus ? (value as Status) : null;

    analyticsService.trackClick(
      CLICK_ANALYTICS.CATEGORY.SEARCH,
      CLICK_ANALYTICS.INTERACTION.SEARCH_FILTER.STATUS[isValidStatus ? value.toUpperCase() : 'ANY']
    );

    performSearch(
      appState.searchTerm,
      appState.searchDateRange.from,
      appState.searchDateRange.to,
      appState.searchStatus
    );
  };

  const performSearch = (searchTerm: string, searchDateFrom: Date, searchDateTo: Date, searchStatus: Status): void => {
    appState.isSearchApplied = !!searchTerm || (!!searchDateFrom && !!searchDateTo) || !!searchStatus;
    appState.numOfDisplayedReports = tableConfig.options.pageSize;

    setSearchInputValue(searchTerm);
    setShowResetButton(appState.isSearchApplied);
    onSearch();
  };

  const isValidInputValue = (value: string): boolean => {
    return value !== '+';
  };

  React.useEffect(() => {
    // check if search has been applied on page load
    appState.isSearchApplied =
      !!appState.searchTerm ||
      (!!appState.searchDateRange.from && !!appState.searchDateRange.to) ||
      !!appState.searchStatus;

    setShowResetButton(appState.isSearchApplied);

    return () => {
      setShowResetButton(appState.isSearchApplied);
      setSearchInputValue(appState.searchTerm);
    };
  }, []);

  // when switching to another CLM
  React.useEffect(() => {
    setSearchInputValue(appState.searchTerm);
    setShowResetButton(appState.isSearchApplied);
  }, [appState.selectedClm.id]);

  return (
    <Styled.Container>
      <SearchInput
        id="searchInput"
        value={searchInputValue}
        onChange={onInputChange}
        onSearch={onSearchClick}
        inputRef={inputRef}
        validate={isValidInputValue}
        onChangeRegex={/^[a-zA-Z\d\s'\-\+]+$/}
        invalidFirstCharacters={[' ', '-', "'", '+ ']}
        placeHolder={placeHolder}
      />
      <DateRangeWithCustomButton
        id="dateFilter"
        dateFrom={appState.searchDateRange?.from}
        dateTo={appState.searchDateRange?.to}
        maxDateFrom={new Date(Date.UTC(2999, 11, 30))}
        minDateFrom={new Date(Date.UTC(1900, 0, 1))}
        maxDateTo={new Date(Date.UTC(2999, 11, 30))}
        minDateTo={new Date(Date.UTC(1900, 0, 1))}
        onApply={onDateFilterApply}
        onCancel={onDateFilterCancel}
      />
      {showStatusFilter && <StatusDropdown value={appState.searchStatus} onSelect={onStatusFilterSelect} />}
      {showResetButton && (
        <Styled.ResetButton type="button" id="resetButton" onClick={onResetButtonClick} aria-label="Reset">
          <ResetIcon />
          Reset
        </Styled.ResetButton>
      )}
    </Styled.Container>
  );
};

export default SearchBar;
