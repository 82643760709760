import React from 'react';

const MenuIcon: React.FC = (): React.ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.50016C0 1.57969 0.747522 0.833496 1.67079 0.833496H18.3292C19.252 0.833496 20 1.57327 20 2.50016C20 3.42064 19.2525 4.16683 18.3292 4.16683H1.67079C0.748038 4.16683 0 3.42705 0 2.50016ZM0 10.0002C0 9.07969 0.747522 8.3335 1.67079 8.3335H18.3292C19.252 8.3335 20 9.07327 20 10.0002C20 10.9206 19.2525 11.6668 18.3292 11.6668H1.67079C0.748038 11.6668 0 10.9271 0 10.0002ZM0 17.5002C0 16.5797 0.747522 15.8335 1.67079 15.8335H18.3292C19.252 15.8335 20 16.5733 20 17.5002C20 18.4206 19.2525 19.1668 18.3292 19.1668H1.67079C0.748038 19.1668 0 18.4271 0 17.5002Z"
        fill="#212129"
      />
    </svg>
  );
};

export default MenuIcon;
