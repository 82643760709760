import React from 'react';

const CalendarIcon: React.FC = (): React.ReactElement => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" focusable="false" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 5.33333H1.33333V14.34C1.33333 14.524 1.49053 14.6667 1.68421 14.6667H14.3158C14.5095 14.6667 14.6667 14.524 14.6667 14.34V5.33333ZM16 1.99416V14.3393C16 15.2604 15.254 16 14.3333 16H1.66667C0.746 16 0 15.2604 0 14.3393V1.99416H2.66667V0.666945C2.66667 0.298791 2.96533 0 3.33333 0C3.70133 0 4 0.298791 4 0.666945V1.99416H12V0.666945C12 0.298791 12.2987 0 12.6667 0C13.0347 0 13.3333 0.298791 13.3333 0.666945V1.99416H16ZM10.6667 12.6652H12.6667V10.6644H10.6667V12.6652ZM7 12.6652H9V10.6644H7V12.6652ZM3.33333 12.6653H5.33333V10.6644H3.33333V12.6653ZM10.6667 9.33059H12.6667V7.32975H10.6667V9.33059ZM7 9.33059H9V7.32975H7V9.33059ZM3.33333 9.33055H5.33333V7.32972H3.33333V9.33055Z"
        fill="#31313D"
      />
    </svg>
  );
};

export default CalendarIcon;
