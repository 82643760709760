export const PAGE_HEADING = 'New check invite';
export const PAGE_HINT = 'All fields are required.';
export const CP_HINT = 'This invite will be sent from ';
export const SURNAME = 'Surname';
export const GIVEN_NAME = 'Given name';
export const NAME_HINT = 'This name will appear in the invite that you send.';
export const MOBILE = 'Mobile number';
export const MOBILE_HINT = 'We’ll use this to remind the candidate via SMS to finish their application';
export const EMAIL = 'Email address';
const MAX_LENGTH = 'Maximum length is 50.';
export const REVIEW_PAGE_HEADING = 'Review check invite';
export const INVITATION_DETAILS_HEADING = 'Invite details';
export const INVITATION_SENT_HEADING = 'Check invite has been sent';
export const INVITATION_SENT_HINT = 'You’ve successfully sent this invite to ';
export const INVITATION_SENT_DETAILS = 'Track all checks from ‘Results’, or send a new invite.';
export const INVITATION_SENT_SUBTITLE = 'What happens next';
export const INVITATION_UNABLE_SEND = 'Unable to send this invite to ';
export const SEND_ANOTHER_HEADING = 'Send another invite';
export const GO_TO_IDENTITY_CHECK_HEADING = 'Go to Results';
export const BACK_ARIA_LABEL = 'back to Invite';
export const EDIT_ARIA_LABEL = 'edit invite';
export const CANCEL_ARIA_LABEL = 'cancel sending invite';
export const CLEAR_ARIA_LABEL = 'clear all form fields';
export const BACK = 'BACK';
export const FORM_VALIDATION = {
  GIVEN_NAME: {
    REQUIRED: 'Given name is required.',
    MAX_LENGTH
  },
  SURNAME: {
    REQUIRED: 'Surname is required.',
    MAX_LENGTH
  },
  PHONE_NUMBER: {
    REQUIRED: 'Mobile number is required.',
    MIN_LENGTH: 'Mobile number must have a minimum length of 10 digits.',
    MAX_LENGTH: 'Mobile number must have no more than 10 digits.'
  },
  EMAIL: {
    REQUIRED: 'Email address is required.',
    INVALID: 'Email address is invalid'
  }
};
