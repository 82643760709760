import React from 'react';
import { Notification as PostmasterNotification } from '@auspost/postmaster-react';

interface Props {
  type: 'alert' | 'notice';
  status: 'info' | 'success' | 'warning' | 'error';
  message: string | React.ReactNode;
  id?: string;
  isDismissible?: boolean;
  handleDismiss?: () => void;
}

const Notification: React.FC<Props> = (props): React.ReactElement => {
  return <PostmasterNotification {...props} />;
};

export default Notification;
