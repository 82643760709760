import React from 'react';
import { Page } from '../../components';
import { PAGE_LOAD_ANALYTICS } from '../../constants/analytics';
import { analyticsService } from '../../services';
import { useAppContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../constants/routes.enum';
import * as Styled from './styled';
import { HOME_MENU_ITEMS } from '../Home/constants';
import { InvitationForm as InvitationFormBase } from '@auspost/pvoi-components';

const Invitation: React.FC = (): React.ReactElement => {
  const appState = useAppContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    analyticsService.trackPage(PAGE_LOAD_ANALYTICS.INVITATION.CATEGORY, PAGE_LOAD_ANALYTICS.INVITATION.DESCRIPTION);
  }, []);

  const onFormSubmission = () => {
    navigate(AppRoute.InvitationConfirmation);
  };

  return (
    <Page menuItems={HOME_MENU_ITEMS()} showMenu={true}>
      <Styled.Invitation>
        <Styled.InvitationPanel>
          <InvitationFormBase appState={appState} onFormSubmission={onFormSubmission} />
        </Styled.InvitationPanel>
      </Styled.Invitation>
    </Page>
  );
};

export default Invitation;
