import React, { MutableRefObject } from 'react';
import * as Styled from './styled';
import SearchIcon from '../icons/SearchIcon';
import { KeyboardKey } from '../../constants/keyboardKeys.enum';
import { Input } from '../Input/Input';
import { theme } from '../../theme/theme';

interface Props {
  id: string;
  value?: string;
  onChange?: (newValue: string, prevValue?: string) => void;
  onSearch?: () => void;
  inputRef?: MutableRefObject<HTMLInputElement>;
  validate?: (value: string) => boolean;
  onChangeRegex?: RegExp;
  invalidFirstCharacters?: string[];
  placeHolder?: string;
}

const SearchInput: React.FC<Props> = ({
  id,
  value,
  onChange,
  onSearch,
  inputRef,
  validate,
  onChangeRegex,
  invalidFirstCharacters = [],
  placeHolder
}): React.ReactElement => {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const [isButtonActive, setButtonActive] = React.useState<boolean>(!!value);
  const [isValueEmpty, setValueEmpty] = React.useState<boolean>(!!value);

  React.useEffect(() => {
    return () => {
      setButtonActive(!!value);
      setValueEmpty(!!value);
    };
  }, []);

  React.useEffect(() => {
    validate ? setButtonActive(!!value && validate(value)) : setButtonActive(!!value);
  });

  // to keep Search button enabling if the input is dirty
  React.useEffect(() => {
    setButtonActive(true);
    setValueEmpty(false);
  }, [isValueEmpty]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const prevValue = event.target.defaultValue;

    // check for first entered char
    if (!invalidFirstCharacters.find(char => char === newValue)) {
      // regex for name and phone number or if the value is empty
      if (onChangeRegex?.test(newValue) || !newValue) {
        onChange(newValue, prevValue);

        // to keep Search button enabling if the input is dirty
        if (!newValue) {
          setValueEmpty(true);
        }
      }
    }
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (isButtonActive && event.key === KeyboardKey.RETURN) {
      rootRef.current.getElementsByTagName('button')[0].click();
    }
  };

  const onButtonClick = () => {
    isButtonActive && onSearch();
  };

  return (
    <Styled.Container ref={rootRef}>
      <Input
        id={`${id}-input`}
        type="text"
        placeholder={placeHolder}
        autoComplete="off"
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
        ref={inputRef}
        value={value}
        maxLength={120}
      />
      <Styled.SearchButton
        id={`${id}-button`}
        type="button"
        isButtonActive={isButtonActive}
        onClick={onButtonClick}
        aria-hidden={!isButtonActive}
        tabIndex={isButtonActive ? 0 : -1}
        aria-label="Search"
      >
        <SearchIcon color={isButtonActive ? theme.color.white : theme.color.slate} />
      </Styled.SearchButton>
    </Styled.Container>
  );
};

export default SearchInput;
