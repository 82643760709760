import React from 'react';
import * as Styled from './styled';
import { Heading } from '@auspost/pvoi-components';

interface OutageProps {
  header?: string;
  content?: string;
  onPageLoadCallback?: () => void;
}

const Outage: React.FC<OutageProps> = ({ header, content, onPageLoadCallback }): React.ReactElement => {
  React.useEffect(() => {
    onPageLoadCallback && onPageLoadCallback();
  }, []);

  return (
    <Styled.Container>
      <Styled.Content>
        <Heading level="h5" as="h1">
          {header}
        </Heading>
        <p>{content}</p>
      </Styled.Content>
    </Styled.Container>
  );
};

export default Outage;
