import { CaretIcon } from '../icons/CaretIcon';
import { theme } from '../../theme/theme';
import React from 'react';
import * as Styled from './styled';

interface Props {
  isClicked: boolean;
}

const HeaderDropdownButton: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props
): React.ReactElement => {
  return (
    <Styled.HeaderDropdownButton {...props} type="button">
      {props.children}
      <CaretIcon fill={theme.color.ash} transform={props.isClicked ? 'rotate(180deg)' : ''} />
    </Styled.HeaderDropdownButton>
  );
};

export default HeaderDropdownButton;
