import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';
import { Button } from '../../Button/styled';

export const Container = styled.div({
  h1: {
    fontWeight: theme.fontWeight.medium,
    fontFamily: theme.fontFamily.heading,
    letterSpacing: '0.8px',
    lineHeight: '26px',
    margin: `14px 0 12px ${theme.spacing.spaceM}`
  },
  a: {
    margin: `28px 0 0 ${theme.spacing.spaceM}`
  }
});

export const List = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  margin: '12px 0 0 24px',
  color: theme.color.ink
});

export const ListItem = styled.li({
  listStyleType: 'none',
  width: '100%',
  hr: {
    margin: `12px 0`
  }
});

export const Label = styled.div({
  lineHeight: '20px',
  fontSize: theme.fontSize.small,
  fontWeight: theme.fontWeight.bold,
  marginBottom: '4px'
});

export const Value = styled.div({
  lineHeight: '20px',
  fontSize: theme.fontSize.small,
  fontWeight: theme.fontWeight.regular,
  marginRight: '16px',
  wordBreak: 'break-all',
  speak: 'spell-out'
});

export const EditMetadataButton = styled(Button)({
  margin: '24px'
});
