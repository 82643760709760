import React, { useEffect } from 'react';
import { Page } from '../../components';
import { Auth2FA, Spinner } from '@auspost/pvoi-components';
import { AUTH2FA } from '@auspost/pvoi-components/src/components/Auth2FA/constants';
import { Auth2FAStatus, AuthState } from '@auspost/pvoi-components/src/components/Auth2FA/types';
import { mfaService } from '../../services/mfaService/mfaService';
import { useAppContext } from '../../context';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../constants/routes.enum';
import { MfaErrorResponse } from '../../services/mfaService/mfaResponseMapping';
import { analyticsService } from '../../services';
import { CLICK_ANALYTICS, PAGE_LOAD_ANALYTICS } from '../../constants/analytics';

const MultiFactorAuth = () => {
  const appState = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const buildAuthState = (status: Auth2FAStatus): AuthState => {
    return {
      heading: AUTH2FA.BANNER.TITLE,
      status: status,
      logoSize: 60
    };
  };

  const [authState, setAuthState] = React.useState<AuthState>(buildAuthState(Auth2FAStatus.DEFAULT));
  const [codeState, setCodeState] = React.useState<string>('');
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const handleValueChange = (newValue: string) => {
    setCodeState(newValue);
  };

  useEffect(() => {
    setLoading(true);
    if (location?.state?.accountLocked) {
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.AC_LOCKED_WHEN_LOG_IN
      );
      setAuthState(buildAuthState(Auth2FAStatus.AC_LOCKED_WHEN_LOG_IN));
      navigate(location.pathname, { state: { accountLocked: false } });
      setLoading(false);
    } else {
      analyticsService.trackPage(PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY, PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION);
      onSendACode().finally(() => setLoading(false));
    }
    return () => {
      setLoading(false);
    };
  }, []);

  const onSubmit = () => {
    setAuthState(buildAuthState(Auth2FAStatus.CODE_SUBMITTING));
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.AUTH_2FA, CLICK_ANALYTICS.INTERACTION.AUTH_2FA.SUBMIT);
    mfaService
      .submitCode(codeState, appState.selectedClm.id)
      .then(() => {
        navigate(AppRoute.Home, { replace: true, state: { mfaSuccess: true } });
      })
      .catch((error: MfaErrorResponse) => {
        setAuthState(buildAuthState(error.code));
      });
    return Promise.resolve();
  };

  const onSendACode = () => {
    setAuthState(buildAuthState(Auth2FAStatus.CODE_SENDING));
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.AUTH_2FA, CLICK_ANALYTICS.INTERACTION.AUTH_2FA.SEND_A_CODE);
    mfaService
      .sendCode(appState.selectedClm.id)
      .then(res => {
        setAuthState(buildAuthState(res));
      })
      .catch((error: MfaErrorResponse) => {
        setAuthState(buildAuthState(error.code));
      });
    return Promise.resolve();
  };

  const validateCodeInput = (value: string): string => {
    if (value.length === 0) {
      return AUTH2FA.VALIDATION_ERRORS.REQUIRED;
    }

    if (value.length < 6) {
      return AUTH2FA.VALIDATION_ERRORS.NOT_ENOUGH_DIGITS;
    }

    return '';
  };

  return (
    <Page showMenu={false} showHeaderMenuItems={false} showProfileLogoutOnly={true}>
      {isLoading ? (
        <Spinner colour="secondary" variant="full-page" />
      ) : (
        <Auth2FA
          onSubmit={onSubmit}
          onSendACode={onSendACode}
          onValidate={validateCodeInput}
          state={authState}
          onValueChange={handleValueChange}
        />
      )}
    </Page>
  );
};

export default MultiFactorAuth;
