export enum AppRoute {
  Home = '/',
  ReportDetails = '/report/:id',
  CheckDetails = '/check-details/:id',
  Invitation = '/invitation',
  InvitationConfirmation = '/invitationConfirmation',
  Help = '/help',
  Outage = '/outage',
  StubPage = '/stubpage',
  MultiFactorAuth = '/mfa'
}
