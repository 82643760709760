import React from 'react';
import { Card as PostmasterCard } from '@auspost/postmaster-react';

interface Props {
  paddingHorizontal?:
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge'
    | 'xxsmall'
    | 'xxxlarge'
    | 'xxxxlarge';
  paddingVertical?:
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge'
    | 'xxsmall'
    | 'xxxlarge'
    | 'xxxxlarge';
}

const Card: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  paddingHorizontal,
  paddingVertical,
  children
}): React.ReactElement => {
  return (
    <PostmasterCard paddingHorizontal={paddingHorizontal} paddingVertical={paddingVertical}>
      {children}
    </PostmasterCard>
  );
};

export default Card;
