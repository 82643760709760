import styled from '@emotion/styled';
import { theme } from '../../theme/theme';
import { Input } from '../Input/Input';

export const Container = styled.div({
  boxShadow: theme.shadow.shadow5,
  backgroundColor: theme.color.white,
  borderRadius: '8px',
  padding: '24px',

  'input[type="date"]': {
    marginTop: '8px',

    '&::-webkit-calendar-picker-indicator': {
      display: 'none'
    }
  }
});

export const DateRange = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-evenly',
  width: '100%',

  '> svg': {
    margin: '47px 5px 0'
  }
});

export const Date = styled.div({
  display: 'flex',
  flexDirection: 'column'
});

export const DateInput = styled(Input)({
  width: '160px',

  '@-moz-document url-prefix()': {
    width: '175px'
  }
});

export const Label = styled.label({
  fontWeight: theme.fontWeight.bold,
  color: theme.color.ink,
  lineHeight: '24px'
});

export const Divider = styled.div({
  margin: '24px 0'
});

export const ButtonGroup = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  button: {
    width: '170px',
    height: '40px'
  }
});

export const Error = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '8px',
  fontSize: theme.fontSize.small,
  color: theme.color.feedbackAa04,
  svg: {
    marginRight: '8px'
  }
});
