import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const DropdownContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.fontSize.xsmall,
  '#companyDetails': {
    marginRight: '32px'
  }
});
