import styled from '@emotion/styled';
import { PlainButton as PostmasterPlainButton } from '@auspost/postmaster-react';
import { theme } from '../../theme/theme';

export const PlainButton = styled(PostmasterPlainButton)({
  '&:focus': {
    outline: `1px dotted ${theme.color.slate}`,
    outlineOffset: '2px'
  },
  '&:focus:not(:focus-visible)': {
    outline: 'none'
  }
});
