import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

// Help Page

export const Help = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1
});

export const Cards = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '40px auto',
  h1: {
    fontFamily: theme.fontFamily.heading,
    color: theme.color.ink,
    lineHeight: '38px',
    letterSpacing: '1.3px',
    marginBottom: '24px'
  },

  section: {
    marginBottom: '40px',
    borderRadius: '8px',
    width: '640px'
  }
});
