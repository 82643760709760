import styled from '@emotion/styled';
import ButtonNoStyles from '../ButtonNoStyles/ButtonNoStyles';
import { theme } from '../../theme/theme';

export const Button = styled(ButtonNoStyles)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  borderRadius: '6px',
  boxSizing: 'border-box',

  '&:hover, &:focus': {
    border: `2px solid ${theme.color.slate}`,
    outline: 'none'
  },

  '&:focus': {
    outline: `1px dotted ${theme.color.slate}`
  },

  '&:hover': {
    backgroundColor: theme.color.white
  },

  '&:focus:not(:focus-visible)': {
    outline: 'none'
  }
});
