import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs';

class FingerprintService {
  private fpPromise: Promise<Agent>;

  constructor() {
    this.fpPromise = FingerprintJS.load({ delayFallback: 500, debug: false, monitoring: false });
  }
  getFingerPrint(): Promise<string> {
    return this.fpPromise.then(fp => fp.get()).then(result => result.visitorId);
  }
}

export const fingerprintService = new FingerprintService();
