class WindowService {
  scrollToTop(smooth?: boolean): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: smooth ? 'smooth' : 'auto'
    });
  }
}

export const windowService = new WindowService();
