import React from 'react';
import * as Styled from './styled';
import { analyticsService, windowService } from 'pvoi-ui/src/services';
import ExportModal from './ExportModal/ExportModal';
import { CLICK_ANALYTICS } from 'pvoi-ui/src/constants/analytics';
import { ReportsService } from '../../services/reportsService/reportsService';
import { useAppContext } from 'pvoi-ui/src/context';
import { DomService } from '../../services/dom/domService';
interface Props {
  onShowMoreClick: () => void;
  hasShowMoreButton: boolean;
  isShowMoreLoading: boolean;
  numOfDisplayedReports: number;
  numOfSearchMatches: number;
  maxRecordLimit: number;
  isSearchApplied: boolean;
  reportsService: ReportsService;
  showExportButton?: boolean;
}

const Pagination: React.FC<Props> = ({
  hasShowMoreButton,
  isShowMoreLoading,
  onShowMoreClick,
  numOfDisplayedReports,
  numOfSearchMatches,
  maxRecordLimit,
  isSearchApplied,
  reportsService,
  showExportButton
}: Props): React.ReactElement => {
  const appState = useAppContext();
  const isIDV = appState.isIDV;
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const reportString: string =
    numOfDisplayedReports === 1 && numOfSearchMatches === 1
      ? isIDV
        ? 'result'
        : 'report'
      : isIDV
      ? 'results'
      : 'reports';

  React.useEffect(() => {
    return () => {
      setModalOpen(false);
    };
  }, []);

  const onShowMoreReportsClick = (): void => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.REPORTS, CLICK_ANALYTICS.INTERACTION.PAGINATION.SHOW_MORE);
    onShowMoreClick();
  };

  const onExportReportsClick = (): void => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.REPORTS, CLICK_ANALYTICS.INTERACTION.PAGINATION.EXPORT);
    setModalOpen(true);
  };

  const onBackToSearchClick = (): void => {
    DomService.focusOn('#searchInput-input');
    windowService.scrollToTop(true);
  };

  const onCloseModal = (): void => {
    setModalOpen(false);
  };

  return (
    <Styled.Pagination>
      <Styled.PaginationButton
        id="showMoreReportsButton"
        variant="secondary"
        size="small"
        onClick={onShowMoreReportsClick}
        isLoading={isShowMoreLoading}
        showButton={hasShowMoreButton}
        type="button"
      >
        {isIDV ? 'Show more results' : 'Show more reports'}
      </Styled.PaginationButton>
      {numOfDisplayedReports === maxRecordLimit ? (
        <Styled.MaxReports>
          <Styled.PaginationText data-test="paginationText">
            Showing <span id="numberOfDisplayedReports">{numOfDisplayedReports} (max)</span> of{' '}
            <span id="numberOfSearchMatches">{numOfSearchMatches}</span>
            {isSearchApplied ? ' matching ' : ' '}
            {reportString}
          </Styled.PaginationText>
          <Styled.BackToSearch id="backToSearch" onClick={onBackToSearchClick}>
            Search VOI reports
          </Styled.BackToSearch>
        </Styled.MaxReports>
      ) : (
        <Styled.PaginationText data-test="paginationText">
          Showing <span id="numberOfDisplayedReports">{numOfDisplayedReports}</span> of{' '}
          <span id="numberOfSearchMatches">{numOfSearchMatches}</span>
          {isSearchApplied ? ' matching ' : ' '}
          {reportString}
        </Styled.PaginationText>
      )}
      <Styled.PaginationButton
        id="exportReportListButton"
        variant="secondary"
        size="small"
        onClick={onExportReportsClick}
        showButton={isSearchApplied && showExportButton && !isIDV}
        type="button"
      >
        Export report list
      </Styled.PaginationButton>
      <ExportModal
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        numberOfDisplayedReports={numOfDisplayedReports}
        numberOfSearchMatches={numOfSearchMatches > maxRecordLimit ? maxRecordLimit : numOfSearchMatches}
        reportsService={reportsService}
      />
    </Styled.Pagination>
  );
};

export default Pagination;
