import React from 'react';
import * as Styled from './styled';
import { UserIcon } from '../icons/UserIcon';
import HeaderDropdownButton from '../HeaderDropdownButton/HeaderDropdownButton';
import Dropdown from '../Dropdown/Dropdown';
import { AccountDropDownItem } from './types';
import LinkNoStyles from '../LinkNoStyles/LinkNoStyles';

interface AccountDropDownProps {
  userName: string;
  dropdownItems: AccountDropDownItem[];
  onClick: (e: React.MouseEvent<HTMLButtonElement>, props: React.ButtonHTMLAttributes<HTMLButtonElement>) => void;
}

const AccountDropdown: React.FC<AccountDropDownProps> = ({ dropdownItems, userName, onClick }): React.ReactElement => {
  const mapDropDownItems = (onListItemClick: () => void) => {
    return dropdownItems.map(item => {
      return {
        id: item.id,
        element: (
          <LinkNoStyles
            id={`${item.id}-link`}
            isExternal={!!item.isExternal}
            href={item.href}
            target={item.isExternal && '_blank'}
            onClick={e => item.onClick(onListItemClick, e)}
          >
            {item.text}
          </LinkNoStyles>
        )
      };
    });
  };

  return (
    <Styled.Dropdown>
      <Dropdown
        id="accountDropdown"
        renderButton={(props, isClicked) => (
          <HeaderDropdownButton {...props} isClicked={isClicked} onClick={e => onClick(e, props)}>
            <UserIcon />
            <Styled.Span>{userName}</Styled.Span>
          </HeaderDropdownButton>
        )}
        renderListItems={mapDropDownItems}
        hasTriangleTip={true}
      />
    </Styled.Dropdown>
  );
};

export default AccountDropdown;
