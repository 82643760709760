import React from 'react';
import { Text as PostmasterText } from '@auspost/postmaster-react';

interface Props {
  variant: 'subtitle' | 'caption' | 'body-small' | 'body' | 'body-large'; // px: 10/12/14/16/18
  weight: 'regular' | 'medium' | 'bold'; // 400/500/700
  as: 'span' | 'p' | 'b' | 'strong' | 'em' | 'i';
}

const Text: React.FC<Props> = ({ variant, children, weight, as }): React.ReactElement => {
  return (
    <PostmasterText variant={variant} weight={weight} as={as}>
      {children}
    </PostmasterText>
  );
};

export default Text;
