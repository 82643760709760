import React from 'react';
import * as Styled from './styled';

interface Props {
  id: string;
  variant: 'primary' | 'secondary' | 'secondary-filled' | 'tertiary';
  size: 'xsmall' | 'small' | 'medium' | 'large';
  isLoading?: boolean;
  icon?: React.ReactElement;
  children?: React.ReactElement | string;
  onClick?: (event?) => void;
  style?: React.CSSProperties;
  type: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <Styled.Button {...props} ref={ref} />
));

Button.displayName = 'Button';
