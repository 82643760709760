import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const Home = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1
});

export const ReportsList = styled.div({
  width: '100%',
  padding: '40px',

  h1: {
    fontWeight: theme.fontWeight.medium
  },

  '@media (max-width:1199px)': {
    padding: '24px'
  }
});

export const HomeMenu = styled.div({
  display: 'flex',
  borderTop: `1px solid ${theme.color.shell}`,

  '@media (max-width:1199px)': {
    display: 'none'
  }
});
