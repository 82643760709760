import React, { useEffect, useState } from 'react';
import { Page } from '../../components';
import { HOME_MENU_ITEMS } from '../Home/constants';
import * as Styled from './styled';
import { Divider, Heading, Link, List, ListItem, Spinner, Stack, Text } from '@auspost/pvoi-components';
import { CheckDetailsItems } from './types';
import {
  ADDRESS_NOTE,
  ADDRESS_NOTE_ITEM1,
  ADDRESS_NOTE_ITEM2,
  BACK,
  BACK_ARIA_LABEL,
  CHECK_DETAILS_ITEMS,
  DETAILS_HEADING,
  INVITE_DETAILS_ITEMS,
  PEP_AND_SANCTIONS_DESC,
  PEP_AND_SANCTIONS_NOTE,
  VERIFIED_DETAILS_ITEMS
} from './constants';
import { analyticsService, reportDetailsService } from '../../services';
import { useAppContext } from '../../context';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../constants/routes.enum';
import { Status } from '../../constants/status.enum';
import { PAGE_LOAD_ANALYTICS } from '../../constants/analytics';
import { Reports } from '@auspost/pvoi-components/src/services/reportsBaseService/types';

const CheckDetails: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const appState = useAppContext();
  const [checkDetailsData, setCheckDetailsData] = useState<CheckDetailsItems[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressExists, setIsAddressExists] = useState(false);
  const [isWatchlistCheckPerformed, setIsWatchlistCheckPerformed] = useState(false);
  const reportId = useLocation().pathname.substring(AppRoute.CheckDetails.lastIndexOf('/') + 1);

  useEffect(() => {
    setIsLoading(true);
    analyticsService.trackPage(
      PAGE_LOAD_ANALYTICS.CHECK_DETAILS.CATEGORY,
      PAGE_LOAD_ANALYTICS.CHECK_DETAILS.DESCRIPTION
    );
    getCheckDetailsData()
      .then(setCheckDetailsData)
      .finally(() => setIsLoading(false));

    return () => {
      setIsLoading(false);
    };
  }, []);

  const onBack = e => {
    e.preventDefault();
    navigate(AppRoute.Home);
  };

  const mapIdentityCheckDetails = (detail: Reports): CheckDetailsItems[] => {
    setIsAddressExists(!!detail.verifiedDetails?.address?.formatted);
    setIsWatchlistCheckPerformed(!!detail.verifiedDetails?.watchlist?.check_performed);
    return [
      {
        title: 'Invite details',
        items: INVITE_DETAILS_ITEMS(
          detail.referenceNumber,
          detail.date,
          detail.familyName,
          detail.givenName,
          detail.email || ''
        ),
        show: true
      },
      {
        title: 'Check details',
        items: CHECK_DETAILS_ITEMS(detail.verifiedAt || '', detail.status, detail.checkTypes),
        show: detail.status !== Status.INVITED
      },
      {
        title: 'Verification details',
        items: VERIFIED_DETAILS_ITEMS(
          detail.verifiedDetails?.familyName,
          detail.verifiedDetails?.givenName,
          detail.verifiedDetails?.birthDate,
          detail.verifiedDetails?.addressVerified,
          detail.verifiedDetails?.address?.formatted,
          detail.verifiedDetails?.watchlist,
          detail.verifiedDetails?.isOver18
        ),
        show: detail.status === Status.SUCCESSFUL
      }
    ];
  };

  const getCheckDetailsData = (): Promise<CheckDetailsItems[]> => {
    return reportDetailsService.getReportDetails(appState.selectedClm.id, reportId).then(mapIdentityCheckDetails);
  };

  const mapTextItems = (items): React.ReactNode => {
    return items.map(item => {
      if (item.show) {
        return (
          <Styled.DetailsContainer key={item.title}>
            <Styled.HeadingContainer>
              <Heading level="h4">{item.title}</Heading>
            </Styled.HeadingContainer>
            <Styled.Divider>
              <Divider />
            </Styled.Divider>
            <Stack space="medium">
              {item.items.map(item => {
                return (
                  <Styled.DetailsRow key={item.label} id={item.id + '-value'}>
                    <Text variant="body" weight="regular" as="p">
                      {item.label}
                    </Text>
                    {item.value && (
                      <Text variant="body" weight="medium" as="strong">
                        {item.value}
                      </Text>
                    )}
                    {item.listValues && (
                      <Stack space="small">
                        {item.listValues.map((value, index) => (
                          <Text key={index} variant="body-small" weight="medium" as="p">
                            {value}
                          </Text>
                        ))}
                      </Stack>
                    )}
                  </Styled.DetailsRow>
                );
              })}
            </Stack>
          </Styled.DetailsContainer>
        );
      } else {
        return null;
      }
    });
  };

  const renderNotes = (): React.ReactNode => {
    return (
      <Styled.NoteContainer>
        {(isWatchlistCheckPerformed || isAddressExists) && (
          <Text variant="body-small" weight="medium" as="span">
            NOTE:
          </Text>
        )}
        <Stack space="medium">
          {isAddressExists && (
            <Stack space="xxsmall">
              <Text variant="body-small" weight="medium" as="p">
                {ADDRESS_NOTE}
              </Text>
              <List>
                <Text variant="body-small" weight="regular" as="p">
                  <ListItem>{ADDRESS_NOTE_ITEM1}</ListItem>
                </Text>
                <Text variant="body-small" weight="regular" as="p">
                  <ListItem>{ADDRESS_NOTE_ITEM2}</ListItem>
                </Text>
              </List>
            </Stack>
          )}

          {isWatchlistCheckPerformed && (
            <Stack space="xxsmall">
              <Text variant="body-small" weight="medium" as="p">
                {PEP_AND_SANCTIONS_NOTE}
              </Text>
              <List>
                <Text variant="body-small" weight="regular" as="p">
                  <ListItem>{PEP_AND_SANCTIONS_DESC}</ListItem>
                </Text>
              </List>
            </Stack>
          )}
        </Stack>
      </Styled.NoteContainer>
    );
  };

  return (
    <Page menuItems={HOME_MENU_ITEMS()} showMenu={true}>
      <Styled.Container>
        <Link id="backToLink" href="" variant="back" onClick={e => onBack(e)} aria-label={BACK_ARIA_LABEL}>
          {BACK}
        </Link>
        <Heading level="h3">{DETAILS_HEADING}</Heading>
        {isLoading ? <Spinner colour={'secondary'} variant={'full-page'} /> : mapTextItems(checkDetailsData)}

        {renderNotes()}
      </Styled.Container>
    </Page>
  );
};

export default CheckDetails;
