import { EnvConfig } from './typed';

export const stestEnvConfig: EnvConfig = {
  baseUrl: '/',
  api: {
    apiBaseUrl: 'https://api.pvoi.identityservice-stest.npe.auspost.com.au',
    digitalApiBaseUrl: 'https://digitalapi-stest.npe.auspost.com.au'
  },
  ui: {
    loginUrl: 'https://stest.npe.auspost.com.au/auth/login?caller=PVOI&product=PVOI&next=',
    featureTogglesUrl: '',
    glamUrl: 'https://stest.npe.auspost.com.au/account-management/#/business',
    merchantPortal: 'https://stest.npe.merchant-portal.auspost.com.au/s/'
  },
  pdf: {
    userGuide: 'https://pvoi-document-stest.s3.ap-southeast-2.amazonaws.com/Property+VOI+Report+User+Guide.pdf',
    diyUserGuide: 'https://pvoi-document-stest.s3.ap-southeast-2.amazonaws.com/Do+It+Yourself+VOI+Tool+User+Guide.pdf',
    searchTips: 'https://pvoi-document-stest.s3.ap-southeast-2.amazonaws.com/Viewing+and+Searching+for+VOI+Reports.pdf'
  }
};
