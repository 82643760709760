import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '88vh'
});

export const Content = styled.div({
  backgroundColor: theme.color.white,
  boxShadow: theme.shadow.shadow1,
  width: '100%',
  maxWidth: '448px',
  borderRadius: '8px',
  padding: '96px 0',
  fontFamily: theme.fontFamily.heading,
  fontWeight: theme.fontWeight.medium,
  color: theme.color.ink,
  fontSize: '18px',
  letterSpacing: '0.8px',
  lineHeight: '24px',
  textAlign: 'center',
  p: {
    margin: 0
  },
  h1: {
    fontWeight: theme.fontWeight.medium
  }
});
