import React from 'react';
import { theme } from '../../theme/theme';

export const CaretIcon: React.FC<{ transform?: string; fill?: string }> = ({ transform, fill }): React.ReactElement => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: transform }}
      focusable="false"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.89518 5.46914C5.90022 5.46421 5.9019 5.45682 5.90779 5.45024L9.7534 1.69321C10.0822 1.37191 10.0822 0.851742 9.7534 0.53126L9.45654 0.240361C9.12774 -0.0801204 8.59543 -0.0801204 8.26746 0.240361L4.99958 3.43285L1.73254 0.240361C1.40457 -0.0801204 0.87142 -0.0801204 0.542614 0.240361L0.246604 0.53126C-0.082202 0.851742 -0.082202 1.37191 0.246604 1.69321L4.09137 5.45024C4.09725 5.456 4.09894 5.46421 4.10482 5.46914L4.40167 5.76004C4.5665 5.92111 4.78346 6.00082 4.99958 5.99999C5.21654 6.00082 5.43266 5.92111 5.59749 5.76004L5.89518 5.46914Z"
        fill={fill ? fill : theme.color.slate}
      />
    </svg>
  );
};
