import React from 'react';
import * as Styled from './styled';
import * as tableConfig from './tableConfig';
import TableHeader from './TableHeader/TableHeader';
import TableStatus from './TableStatus/TableStatus';
import { STATUS } from './tableConfig';
import { TableData } from './types';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from 'pvoi-ui/src/constants/routes.enum';
import { AppType } from 'pvoi-ui/src/constants/type.enum';
import { MaterialTableProps } from '@material-table/core';
import { CLICK_ANALYTICS, PAGE_LOAD_ANALYTICS } from 'pvoi-ui/src/constants/analytics';
import { ReportsListView } from './ReportsListView';
import { DropdownListItem } from '../Dropdown/types';
import MoreDropdown from '../MoreDropdown/MoreDropdown';
import Table from '../Table/Table';
import Pagination from '../Pagination/Pagination';
import { Reports } from '../../services/reportsBaseService/types';
import { ReportsService } from '../../services/reportsService/reportsService';
import { ReportDetailsService } from '../../services/reportDetailsService/reportDetailsService';
import { AppStateModel } from '../../models/appState.model';
import { UtilsService } from 'pvoi-ui/src/services/utils/utilsService';
import { windowService } from '../../services/windowService/windowService';
import { AnalyticsService } from '../../services/analytics/analyticsService';

interface Props {
  searchId: string;
  reportsService: ReportsService;
  reportDetailsService: ReportDetailsService;
  analyticsService: AnalyticsService;
  appState: AppStateModel;
  showExportButton?: boolean;
}

const ReportsList: React.FC<Props> = ({
  searchId,
  reportsService,
  reportDetailsService,
  analyticsService,
  appState,
  showExportButton = true
}): React.ReactElement => {
  const tableRef = React.useRef();

  const navigate = useNavigate();

  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isError, setError] = React.useState<boolean>(false);
  const [data, setData] = React.useState<TableData[]>([]);
  const [isPdfError, setPdfError] = React.useState<boolean>(false);
  const [isShowMoreLoading, setShowMoreLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    return () => {
      setLoading(true);
      setError(false);
      setData([]);
      setPdfError(false);
      setShowMoreLoading(false);
    };
  }, []);

  React.useEffect(() => {
    setError(false);
    setPdfError(false);
    setLoading(true);

    loadData()
      .then(data => {
        if (data.length === 0) {
          appState.isSearchApplied
            ? analyticsService.trackPage(
                PAGE_LOAD_ANALYTICS.HOME.CATEGORY,
                PAGE_LOAD_ANALYTICS.HOME.DESCRIPTION,
                PAGE_LOAD_ANALYTICS.HOME.FORM.NAME,
                PAGE_LOAD_ANALYTICS.HOME.FORM.STEP,
                PAGE_LOAD_ANALYTICS.HOME.FORM.STAGE.NO_MATCHING_REPORTS
              )
            : analyticsService.trackPage(
                PAGE_LOAD_ANALYTICS.HOME.CATEGORY,
                PAGE_LOAD_ANALYTICS.HOME.DESCRIPTION,
                PAGE_LOAD_ANALYTICS.HOME.FORM.NAME,
                PAGE_LOAD_ANALYTICS.HOME.FORM.STEP,
                PAGE_LOAD_ANALYTICS.HOME.FORM.STAGE.NO_REPORTS
              );
        }

        setData(data);
      })
      .catch(() => {
        analyticsService.trackPageError(
          PAGE_LOAD_ANALYTICS.HOME.CATEGORY,
          PAGE_LOAD_ANALYTICS.HOME.DESCRIPTION,
          PAGE_LOAD_ANALYTICS.HOME.FORM.STAGE.ERROR
        );
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [searchId]);

  const setPagination = (availableItems: number, returnedItems: number) => {
    if (returnedItems <= tableConfig.options.pageSize) {
      appState.numOfDisplayedReports = returnedItems;
    } else if (
      returnedItems > appState.numOfDisplayedReports &&
      appState.numOfDisplayedReports < tableConfig.options.pageSize
    ) {
      appState.numOfDisplayedReports = tableConfig.options.pageSize;
    } else if (returnedItems < appState.numOfDisplayedReports) {
      appState.numOfDisplayedReports = returnedItems;
    }

    appState.numOfSearchMatches = availableItems;
  };

  const onDownloadClick = (report: Reports) => {
    setPdfError(false);

    if (appState.isTablet) {
      const openPdf = window.open('', '_blank');
      reportDetailsService
        .getPdf(appState.selectedClm.id, report.id)
        .then(data => {
          openPdf.location.href = data as string;
        })
        .catch(() => {
          setPdfError(true);
          openPdf.close();
          windowService.scrollToTop(true);
        });
    } else {
      reportDetailsService
        .getPdf(appState.selectedClm.id, report.id, `${report.familyName}_${report.referenceNumber}`)
        .catch(() => {
          setPdfError(true);
          windowService.scrollToTop(true);
        });
    }
  };

  const getListItems = (report: Reports, onListItemClick: () => void): DropdownListItem[] => {
    const options = [
      {
        id: `view-${report.id}`,
        element: (
          <Styled.Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onRowClick(report.id, CLICK_ANALYTICS.INTERACTION.TABLE.VIEW);
              onListItemClick();
            }}
          >
            View
          </Styled.Button>
        ),
        type: [AppType.IDV, AppType.PVOI, AppType.AGENCY]
      },
      {
        id: `download-${report.id}`,
        element: (
          <Styled.Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setPdfError(false);
              analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.REPORTS, CLICK_ANALYTICS.INTERACTION.TABLE.DOWNLOAD);
              onDownloadClick(report);
              onListItemClick();
            }}
          >
            Download
          </Styled.Button>
        ),
        type: [AppType.PVOI, AppType.AGENCY]
      }
    ];
    // @ts-ignore
    const filteredOptions = options.filter(option => option.type.includes(appState.type));

    return filteredOptions;
  };

  const loadData = (): Promise<TableData[]> => {
    return reportsService
      .getReports(
        appState.selectedClm.id,
        appState.searchTerm,
        appState.searchDateRange?.from,
        appState.searchDateRange?.to,
        appState.searchStatus
      )
      .then(data => {
        setPagination(data.meta.availableItems, data.meta.returnedItems);
        return data.reports.map(report => {
          return {
            surname: <Styled.Surname>{UtilsService.truncateText(report.familyName, 25)}</Styled.Surname>,
            givenNames: <Styled.GivenName>{UtilsService.truncateText(report.givenName, 25)}</Styled.GivenName>,
            contact: report.phone ? report.phone : <Styled.Email>{report.email}</Styled.Email>,
            date: report.date,
            referenceNumber: report.referenceNumber,
            status: (
              <TableStatus
                id={`${report.id}-status`}
                status={STATUS[report.status]}
                hasSpeechIcon={report.comments && report.comments.length > 0}
              />
            ),
            actionButton: (
              <MoreDropdown id={report.id} getListItems={onListItemClick => getListItems(report, onListItemClick)} />
            ),
            id: report.id
          };
        });
      });
  };

  const onShowMoreClick = (): void => {
    const dataLength = data.length;
    const newNumOfDisplayedReports = appState.numOfDisplayedReports + tableConfig.options.pageSize;

    if (tableRef && tableRef.current) {
      (tableRef.current as MaterialTableProps<never>).onQueryChange();
    }

    appState.numOfDisplayedReports = dataLength <= newNumOfDisplayedReports ? dataLength : newNumOfDisplayedReports;

    setShowMoreLoading(true);
    setTimeout(() => {
      const nextElement = document.querySelectorAll('tbody tr')[appState.numOfDisplayedReports]?.querySelector('td');

      if (nextElement) {
        nextElement.setAttribute('tabIndex', '-1');
        document
          .querySelectorAll('tbody tr')
          [appState.numOfDisplayedReports - tableConfig.options.pageSize]?.scrollIntoView({ behavior: 'smooth' });
        nextElement.focus();
      }

      setShowMoreLoading(false);
    }, 500);
  };

  const onRowClick = (id: string, analytics: string) => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.REPORTS, analytics);
    switch (appState.type) {
      case AppType.IDV:
        return navigate(AppRoute.CheckDetails.replace(/:id/, id));
      default:
        navigate(AppRoute.ReportDetails.replace(/:id/, id));
    }
  };

  const onNewInviteClick = e => {
    e.preventDefault();
    navigate(AppRoute.Invitation);
  };

  const getView = (): React.ReactNode => {
    const isIDV = appState.isIDV;

    if (isLoading) {
      return ReportsListView.loading();
    }

    if (isError) {
      return ReportsListView.error(isIDV);
    }

    if (data.length === 0) {
      if (appState.isSearchApplied) {
        return ReportsListView.noMatchingReports(isIDV);
      } else {
        return ReportsListView.noReports(isIDV, e => onNewInviteClick(e));
      }
    }

    const renderColumn = (type: string) => {
      switch (type) {
        case AppType.IDV:
          return tableConfig.columnIDV;
        default:
          return tableConfig.column;
      }
    };

    const renderHeader = (type: string) => {
      switch (type) {
        case AppType.IDV:
          return tableConfig.headerListIDV;
        default:
          return tableConfig.headerList;
      }
    };

    return (
      <>
        {isPdfError && (
          <Styled.PdfNotification
            type="alert"
            status="error"
            message="Unable to download report. Please try again later."
            isDismissible={true}
          />
        )}
        <Table
          tableRef={tableRef}
          tableStyle={Styled.tableStyle}
          options={tableConfig.options}
          column={renderColumn(appState.type)}
          data={() => {
            return new Promise(resolve => {
              resolve({
                data: data.slice(0, appState.numOfDisplayedReports),
                page: 0,
                totalCount: appState.numOfDisplayedReports
              });
            });
          }}
          components={{
            Header: () => <TableHeader headerList={renderHeader(appState.type)} />,
            OverlayLoading: () => <div />
          }}
          onRowClick={(id: string) => onRowClick(id, CLICK_ANALYTICS.INTERACTION.TABLE.ROW_CLICK)}
        />
        <Pagination
          onShowMoreClick={onShowMoreClick}
          hasShowMoreButton={
            data.length > appState.numOfDisplayedReports && appState.numOfDisplayedReports < appState.maxRecordLimit
          }
          isShowMoreLoading={isShowMoreLoading}
          numOfDisplayedReports={appState.numOfDisplayedReports}
          numOfSearchMatches={appState.numOfSearchMatches}
          maxRecordLimit={appState.maxRecordLimit}
          isSearchApplied={appState.isSearchApplied}
          reportsService={reportsService}
          showExportButton={showExportButton}
        />
      </>
    );
  };

  return <Styled.ReportsList>{getView()}</Styled.ReportsList>;
};

export default ReportsList;
