import { Auth2FAStatus } from '@auspost/pvoi-components/src/components/Auth2FA/types';
import { MfaErrorCodes, MfaSuccessCodes } from './constants';
import { AxiosError, AxiosResponse } from 'axios';
import { analyticsService } from '../../services';
import { PAGE_LOAD_ANALYTICS } from '../../constants/analytics';

export const handleMfaSuccess = (data: AxiosResponse['data']): Auth2FAStatus => {
  const { status } = data;
  switch (status) {
    case MfaSuccessCodes.FINAL_ATTEMPT:
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.FINAL_ATTEMPT
      );
      return Auth2FAStatus.CODE_SENT_FINAL_ATTEMPT;
    default:
      return Auth2FAStatus.CODE_SENT_SUCCESS;
  }
};

export type MfaErrorResponse = {
  code: Auth2FAStatus;
};

export const handleMfaEmailError = (errors: AxiosError[]): MfaErrorResponse => {
  const mfaError = errors?.[0];
  let auth2FAStatus: Auth2FAStatus;

  switch (mfaError?.code) {
    case MfaErrorCodes.NEW_ACCOUNT_LOCKED:
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.AC_LOCKED_TOO_MANY_RESENT
      );
      auth2FAStatus = Auth2FAStatus.AC_LOCKED_TOO_MANY_RESENT;
      break;
    case MfaErrorCodes.ACCOUNT_LOCKED:
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.AC_LOCKED_WHEN_LOG_IN
      );
      auth2FAStatus = Auth2FAStatus.AC_LOCKED_WHEN_LOG_IN;
      break;
    default:
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.OUTAGE
      );
      auth2FAStatus = Auth2FAStatus.OUTAGE;
  }
  return {
    code: auth2FAStatus
  };
};

export const handleMfaValidateError = (errors: AxiosError[]): MfaErrorResponse => {
  const mfaError = errors?.[0];
  let auth2FAStatus: Auth2FAStatus;

  switch (mfaError?.code) {
    case MfaErrorCodes.MFA_REQUIRED:
      const isFinalAttempt = mfaError.message === 'FINAL_ATTEMPT';
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        isFinalAttempt
          ? PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.ONE_MORE_ATTEMPT
          : PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.CODE_INCORRECT
      );
      auth2FAStatus = isFinalAttempt ? Auth2FAStatus.ONE_MORE_ATTEMPT : Auth2FAStatus.CODE_INCORRECT;
      break;
    case MfaErrorCodes.MFA_EXPIRED:
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.CODE_EXP
      );
      auth2FAStatus = Auth2FAStatus.CODE_EXP;
      break;
    case MfaErrorCodes.ACCOUNT_LOCKED:
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.AC_LOCKED_WHEN_LOG_IN
      );
      auth2FAStatus = Auth2FAStatus.AC_LOCKED_WHEN_LOG_IN;
      break;
    case MfaErrorCodes.NEW_ACCOUNT_LOCKED:
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.AC_LOCKED_CODE_INCORRECT
      );
      auth2FAStatus = Auth2FAStatus.AC_LOCKED_CODE_INCORRECT;
      break;
    default:
      analyticsService.trackPageError(
        PAGE_LOAD_ANALYTICS.AUTH_2FA.CATEGORY,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.DESCRIPTION,
        PAGE_LOAD_ANALYTICS.AUTH_2FA.STAGE.OUTAGE
      );
      auth2FAStatus = Auth2FAStatus.OUTAGE;
  }
  return {
    code: auth2FAStatus
  };
};
