export type ReportList = {
  meta: ReportMeta;
  reports: Reports[];
};

export type ReportMeta = {
  availableItems: number;
  returnedItems: number;
  orgName: string;
};

// Response mapper to display (DigitalId) Verification details in UI
export type Reports = {
  id: string;
  givenName: string;
  familyName: string;
  date: string;
  status: string;
  checkTypes: CheckType[];
  phone?: string;
  email?: string;
  referenceNumber: string;
  comments: string[];
  reportType: ReportType;
  verifiedAt?: string;
  verificationMatchType?: VerificationMatchType;
  verifiedDetails?: CheckDetails;
};

// Response mapper to display (DigitalId) Verification details in UI
export type CheckDetails = {
  givenName: string | undefined;
  familyName: string | undefined;
  birthDate: string | undefined;
  address: Address | undefined;
  addressVerified: boolean | undefined;
  isOver18: boolean | undefined;
  watchlist: Watchlist | undefined;
};

// aligns with type OpenIDAddress defined in pvoi-serverless - common-utils/src/contants/constants.ts
export type Address = {
  formatted: string;
  street_address: string;
  locality: string;
  region: string;
  postal_code: string;
  country: string;
};

export type Watchlist = {
  check_performed_date: string;
  check_performed: boolean;
  found: boolean;
  found_sources: WatchlistSource[];
  sources_category: WatchlistSourceCategory;
};

export type WatchlistSource = {
  name: string;
  category: WatchlistSourceCategory;
};

// Response back from get-reports-lambda  (get-reports-lambda/src/service/reports-service.ts)
// aligns with type VerificationDetails defined in pvoi-serverless - common-utils/src/contants/constants.ts
export type CheckDetailsResponse = {
  given_name?: string;
  family_name?: string;
  birthdate?: string;
  middle_name?: string;
  email?: string;
  email_verified?: boolean;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: Address;
  address_verified?: boolean;
  is_over18?: boolean;
  watchlist?: Watchlist;
};

// Response from pvoi-serverless get-reports-lambda (get-reports-lambda/src/service/reports-service.ts)
export type ReportsResponse = {
  id: string;
  given_name: string;
  last_name: string;
  created_at: string;
  updated_at: string;
  status: string;
  scopes?: string;
  phone_number?: string;
  email?: string;
  reference_number: string;
  comments: string[];
  report_type: ReportType;
  verification_date?: string;
  verification_match_type?: VerificationMatchType;
  verification_details?: CheckDetailsResponse;
};

export enum ReportType {
  PVOI = 'PVOI',
  IDV = 'IDV'
}

export enum VerificationMatchType {
  EXACT = 'EXACT',
  PARTIAL = 'PARTIAL',
  MISMATCH = 'MISMATCH'
}

export enum WatchlistSourceCategory {
  PEP = 'pep',
  SANCTIONS = 'sanctions',
  PEP_AND_SANCTIONS = 'pep_and_sanctions'
}

export enum CheckType {
  STAR1 = '1 Star',
  STAR2 = '2 Star',
  WATCHLIST = 'Watchlist',
  PROOF_OF_AGE = 'Proof of Age',
  AML_PREMIUM = 'AML Premium'
}
export enum Scope {
  STAR1_SCOPE = 'aus.digitalid.stars1',
  STAR2_SCOPE = 'aus.digitalid.stars2',
  WATCHLIST = 'watchlist',
  KYC_PREMIUM = 'aus.digitalid.kycpremium',
  PROOF_OF_AGE = 'age.isOver18'
}
