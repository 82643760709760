import { EnvConfig } from './typed';
import { prodEnvConfig } from './prod.env.config';
import { stestEnvConfig } from './stest.env.config';
import { ptestEnvConfig } from './ptest.env.config';
import { pdevEnvConfig } from './pdev.env.config';
import { localhostEnvConfig } from './localhost.env.config';

const LOCALHOST_URL = 'localhost';
const PDEV_URL = 'pvoi.identityservice-pdev.npe.auspost.com.au';
const PTEST_URL = 'pvoi.identityservice-ptest.npe.auspost.com.au';
const STEST_URL = 'pvoi.identityservice-stest.npe.auspost.com.au';
const PROD_URL = 'pvoi.identityservice.auspost.com.au';

let envConfig: EnvConfig;

const hostName: string = window.location.hostname;

if (hostName.startsWith(PROD_URL)) {
  envConfig = prodEnvConfig;
} else if (hostName.startsWith(STEST_URL)) {
  envConfig = stestEnvConfig;
} else if (hostName.startsWith(PTEST_URL)) {
  envConfig = ptestEnvConfig;
} else if (hostName.startsWith(PDEV_URL)) {
  envConfig = pdevEnvConfig;
} else if (hostName.startsWith(LOCALHOST_URL)) {
  envConfig = localhostEnvConfig;
} else {
  throw 'Unrecognised environment.';
}

export { envConfig };
