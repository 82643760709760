import React from 'react';
import * as Styled from './styled';
import Spinner from '../Spinner/Spinner';
import { Link } from '@auspost/pvoi-components';

export const ReportsListView = {
  loading: () => (
    <Styled.Loading>
      <Spinner colour="secondary" variant="full-page" />
    </Styled.Loading>
  ),

  error: isIDV => (
    <Styled.EmptyState id="errorInLoadingReportsText">
      {isIDV ? (
        <div>Unable to load results. Please try again later.</div>
      ) : (
        <>
          <div>Unable to load VOI reports</div>
          <div>Please try again later</div>
        </>
      )}
    </Styled.EmptyState>
  ),
  noMatchingReports: isIDV => (
    <Styled.EmptyState id="noMatchedReportsText">
      {isIDV ? (
        <>
          <div>There are no results.</div>
        </>
      ) : (
        <div>There are no matching VOI reports</div>
      )}
    </Styled.EmptyState>
  ),
  noReports: (isIDV, onClick) => (
    <Styled.EmptyState id="noVoiReportsText">
      {isIDV ? (
        <>
          <div>There are currently no results.</div>
          <div>Once a check has been completed it will appear here.</div>
          <div>
            Send a{' '}
            <Link id="new-invite" onClick={e => onClick(e)}>
              new invite
            </Link>{' '}
            {`when you're ready.`}
          </div>
        </>
      ) : (
        <div>There are no VOI reports</div>
      )}
    </Styled.EmptyState>
  )
};
