import React from 'react';
import { theme } from '../../theme/theme';

export const UserIcon: React.FC = (): React.ReactElement => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
      <path
        d="M8.33333 8C5.80039 8 4.66667 5.99104 4.66667 3.9999C4.66667 1.23686 6.5082 0 8.33333 0C10.8663 0 12 2.00896 12 3.9999C12 6.76314 10.1585 8 8.33333 8Z"
        fill="#919194"
      />
      <path
        d="M0 15.3474V16H16V15.3474C16 13.3694 15.2792 11.608 13.9702 10.3878C12.3635 8.89024 9.54005 8.66667 8 8.66667C6.45988 8.66667 3.63655 8.89024 2.0298 10.3878C0.720711 11.608 0 13.3694 0 15.3474Z"
        fill={theme.color.ash}
      />
    </svg>
  );
};
