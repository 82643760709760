import { DomService, Heading, Link, Notification, Text } from '@auspost/pvoi-components';
import * as Styled from './styled';
import React from 'react';
import { USER_GUIDES } from './constants';
import { analyticsService } from '../../../services';
import { CLICK_ANALYTICS } from '../../../constants/analytics';
import { useHelpAnalytics } from './useHelpAnalytics';

export const UserGuides: React.FC = (): React.ReactElement => {
  useHelpAnalytics([
    { id: 'userGuideContactUs', interactionDescription: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.USER_GUIDE.EMAIL_US }
  ]);
  const bannerMessages: React.ReactNode = (
    <Styled.TextContainer>
      <p dangerouslySetInnerHTML={DomService.setInnerHTML(USER_GUIDES.BANNER.TEXT_1)} />
      <Text variant="body" weight="bold" as="p">
        {USER_GUIDES.BANNER.TEXT_2}
      </Text>
    </Styled.TextContainer>
  );

  return (
    <Styled.Container>
      <Styled.Header>
        <Heading id="header-user-guides" level="h3" as="h2">
          {USER_GUIDES.HEADING}
        </Heading>
      </Styled.Header>
      <Styled.Content>
        <Text variant="body" weight="regular" as="p">
          {USER_GUIDES.PARAGRAPH}
        </Text>
        <Styled.List>
          {USER_GUIDES.CONTENT.map((item, index) => {
            return (
              <Styled.ListItem key={`userGuide-${index}`}>
                <Text variant="body" weight="medium" as="span">
                  <Link
                    id={`userGuide-${index}`}
                    href={item.LINK}
                    isExternal={true}
                    aria-label={item.LINK_ARIA}
                    onClick={() =>
                      item.ANALYTICS &&
                      analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.HELP_CENTRE, item.ANALYTICS)
                    }
                  >
                    {item.LINK_TEXT}
                  </Link>
                </Text>
                <p>
                  <Text variant="body" weight="regular" as="span">
                    {item.TEXT}
                  </Text>
                </p>
              </Styled.ListItem>
            );
          })}
        </Styled.List>
        <Notification type="notice" status="info" message={bannerMessages} isDismissible={false} />
      </Styled.Content>
    </Styled.Container>
  );
};
