import React from 'react';
import * as Styled from './styled';
import { Button } from '@auspost/postmaster-react';
import { APLogoSquare, ErrorIcon } from '../icons';
import { KeyboardKey } from '../../constants/keyboardKeys.enum';
import { AUTH2FA } from './constants';
import Auth2FAAlert from './Auth2FAAlert';
import { Auth2FAStatus, AuthState } from './types';
import Spinner from '../Spinner/Spinner';

interface Auth2FAProps {
  onSubmit: () => Promise<void>;
  onSendACode: () => Promise<void>;
  onValidate: (value: string) => string | undefined;
  state: AuthState;
  onValueChange: (value: string) => void
}

const Auth2FA: React.FC<Auth2FAProps> = ({ onSubmit, onSendACode, onValidate, state, onValueChange }) => {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const codeRef = React.useRef<HTMLInputElement>();
  const [codeInputValue, setCodeInputValue] = React.useState<string>('');
  const [validateError, setValidateError] = React.useState<string>('');
  const [disableSend, setDisableSend] = React.useState<boolean>(false);

  const onSubmitButtonClick = (): void => {
    setValidateError('');

    const validateErr = onValidate(codeInputValue);

    if (!validateErr) {
      void onSubmit();
    }
    setValidateError(validateErr);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;

    if (value === '' || /^\d+$/.test(value)) {
      setCodeInputValue(value);
      onValueChange(value)
    }
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === KeyboardKey.RETURN) {
      const buttonElement = rootRef.current.getElementsByTagName('button');
      const buttonRef = buttonElement.length - 1
      buttonElement[buttonRef].click();
    }
  };

  const sendACode = (): void => {
    onSendACode().then(() => {
      onValueChange(codeInputValue)
      setCodeInputValue('');
    });
  };

  const ifState = (target: Auth2FAStatus): boolean => {
    if(state.status === (Auth2FAStatus.CODE_SENT_FINAL_ATTEMPT) && !disableSend) {
      setDisableSend(true)
    }
    return state.status === target;
  };

  const isAccountLocked =
    ifState(Auth2FAStatus.AC_LOCKED_CODE_INCORRECT) ||
    ifState(Auth2FAStatus.AC_LOCKED_WHEN_LOG_IN) ||
    ifState(Auth2FAStatus.AC_LOCKED_TOO_MANY_RESENT);

  const sendACodeLink = () => {
    return ifState(Auth2FAStatus.CODE_SENDING) ? (
      <Styled.SpinnerContainer id="spinner">
        <Spinner colour="primary" variant="inline" />
      </Styled.SpinnerContainer>
    ) : (
      <Styled.SendCodeLink id="sendCode-Button" onClick={sendACode} >
        {AUTH2FA.SEND_A_CODE.LABEL}
      </Styled.SendCodeLink>
    );
  };

  const setView = () => {
    return (
      <>
        <Auth2FAAlert authStatus={state.status} alertTitle={state.alertHeading} alertBody={state.alertBody} />
        {!isAccountLocked && (
          <>
            <Styled.CardContainer>
              {ifState(Auth2FAStatus.DEFAULT) && (
                <Styled.CardText>{state.reminderPrimary ?? AUTH2FA.EMAIL_SENT}</Styled.CardText>
              )}
              <Styled.CardLabel>
                <label aria-hidden htmlFor="codeInput">
                  {AUTH2FA.CODE_INPUT.LABEL}
                </label>
              </Styled.CardLabel>
              <Styled.InputContainer>
                <input
                  id="codeInput"
                  name="codeInput"
                  value={codeInputValue}
                  aria-label={AUTH2FA.CODE_INPUT.LABEL}
                  onChange={onInputChange}
                  ref={codeRef}
                  onKeyDown={onInputKeyDown}
                  autoComplete="off"
                  onClick={event => event.preventDefault()}
                  required
                  minLength={6}
                  maxLength={6}
                />
              </Styled.InputContainer>
              {validateError && (
                <Styled.Error role="alert">
                  <ErrorIcon />
                  <span id="validateError">{validateError}</span>
                </Styled.Error>
              )}
               {!disableSend && <div>{sendACodeLink()}</div>}
            </Styled.CardContainer>
            <div>
              <Styled.HeadsUpContainer>
                <Styled.CardText>{state.reminderSecondary ?? AUTH2FA.HEADS_UP}</Styled.CardText>
              </Styled.HeadsUpContainer>
              <Styled.ButtonContainer>
                {ifState(Auth2FAStatus.CODE_SUBMITTING) ? (
                  <Spinner colour="primary" variant="inline" />
                ) : (
                  <Button id='submitCode-button' variant="primary" size="medium" onClick={onSubmitButtonClick}>
                    Submit
                  </Button>
                )}
              </Styled.ButtonContainer>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Styled.StyledBody>
        <div ref={rootRef}>
          <Styled.AuthLogo>
            <APLogoSquare width={state.logoSize} height={state.logoSize} />
            <Styled.BannerWrapper>
              <Styled.BannerTitle>{state.heading}</Styled.BannerTitle>
              <Styled.BannerText>{state.subHeading ?? AUTH2FA.BANNER.SUBTITLE}</Styled.BannerText>
            </Styled.BannerWrapper>
          </Styled.AuthLogo>
          <Styled.Card>{setView()}</Styled.Card>
        </div>
      </Styled.StyledBody>
    </>
  );
};

export default Auth2FA;
