import React from 'react';
import * as Styled from './styled';
import { useNavigate } from 'react-router-dom';
import {
  AccountDropdown,
  AccountDropDownItem,
  authenticationService,
  Header as BaseHeader
} from '@auspost/pvoi-components';
import { useAppContext } from '../../context';
import { analyticsService } from '../../services';
import { CLICK_ANALYTICS } from '../../constants/analytics';
import { AppRoute } from '../../constants/routes.enum';
import { envConfig } from '../../config/env.config';
import CompanyDetails from '../CompanyDetails/CompanyDetails';
import { HOME_MENU_ITEMS } from '../../pages/Home/constants';

interface Props {
  onClmSelectCallBack: () => void;
  showProfile: boolean;
  showProfileLogoutOnly?: boolean;
  showMenuItems?: boolean;
}

const Header: React.FC<Props> = ({
  onClmSelectCallBack,
  showProfile,
  showProfileLogoutOnly = false,
  showMenuItems = true
}): React.ReactElement => {
  const appState = useAppContext();
  const navigate = useNavigate();
  const isIDV = appState.isIDV;
  const menuItems = showMenuItems ? HOME_MENU_ITEMS() : [];

  const onLogoLinkClick = (): void => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.HEADER, CLICK_ANALYTICS.INTERACTION.HEADER.LOGO);
  };

  const onMenuLinkCallback = (): void => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.HEADER, CLICK_ANALYTICS.INTERACTION.HEADER.HAMBURGER);
  };

  const onAccountDropDownClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    props: React.ButtonHTMLAttributes<HTMLButtonElement>
  ): void => {
    props.onClick(e);
  };

  const companyList = appState.organisations.map(org => {
    return { clmName: org.organisationName, clmId: org.clmId };
  });

  const onClmSelect = (clmItem: string): void => {
    analyticsService.trackClick(
      CLICK_ANALYTICS.CATEGORY.HEADER,
      CLICK_ANALYTICS.INTERACTION.HEADER.COMPANY_DETAILS_DROPDOWN
    );
    appState.onClmSelect(clmItem, onClmSelectCallBack);
    navigate(AppRoute.Home);
  };

  const accountDropDownItems: AccountDropDownItem[] = [
    !showProfileLogoutOnly && {
      id: 'accountSettings',
      href: envConfig.ui.glamUrl,
      text: 'Account settings',
      onClick: onListItemClick => {
        onListItemClick();
        analyticsService.trackClick(
          CLICK_ANALYTICS.CATEGORY.HEADER,
          CLICK_ANALYTICS.INTERACTION.HEADER.ACCOUNT_SETTINGS
        );
      },
      isExternal: true
    },
    {
      id: 'logout',
      href: '',
      text: 'Logout',
      onClick: (onListItemClick, event) => {
        event.preventDefault();
        onListItemClick();
        analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.HEADER, CLICK_ANALYTICS.INTERACTION.HEADER.LOGOUT);
        void authenticationService.logout();
      }
    }
  ];

  return (
    <>
      <BaseHeader
        logoTitle={isIDV ? 'Australia Post Identity Verification' : 'Property VOI'}
        logoHref={AppRoute.Home}
        onLogoLinkClickCallBack={onLogoLinkClick}
        onMenuLinkCallback={onMenuLinkCallback}
        menuItems={menuItems}
      >
        <>
          {showProfile && (
            <Styled.DropdownContainer>
              {!showProfileLogoutOnly && (
                <CompanyDetails
                  clmList={companyList}
                  selectedClm={`${appState.selectedClm.clmName} - ${appState.selectedClm.id}`}
                  onSelect={onClmSelect}
                  isAdmin={appState.selectedClm.isAdmin}
                />
              )}
              <AccountDropdown
                dropdownItems={accountDropDownItems}
                userName={appState.selectedClm.userName}
                onClick={onAccountDropDownClick}
              />
            </Styled.DropdownContainer>
          )}
        </>
      </BaseHeader>
    </>
  );
};

export default Header;
