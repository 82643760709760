const MAX_LENGTH = 'Maximum length is 120.';

export const FORM_VALIDATION = {
  GIVEN_NAME: {
    REQUIRED: 'Given names is required.',
    MAX_LENGTH
  },
  SURNAME: {
    REQUIRED: 'Surname is required.',
    MAX_LENGTH
  },
  PHONE_NUMBER: {
    REQUIRED: 'Phone number is required.',
    MIN_LENGTH: 'Phone number must have a minimum length of 8 digits.',
    MAX_LENGTH: 'Phone number must have no more than 20 digits.'
  }
};

export const ERRORS = {
  SAVE: 'Unable to save metadata. Please try again later.'
};

export const LABELS = {
  HEADING: 'Edit metadata'
};
