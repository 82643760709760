import { httpService } from '../';
import { envConfig } from '../../config/env.config';
import { Auth2FAStatus } from '@auspost/pvoi-components/src/components/Auth2FA/types';
import { handleMfaEmailError, handleMfaSuccess, handleMfaValidateError } from './mfaResponseMapping';

class MFAService {
  mfaEmailEndpoint: string;
  mfaValidateEndpoint: string;

  constructor() {
    this.mfaEmailEndpoint = `${envConfig.api.apiBaseUrl}/mfa/email`;
    this.mfaValidateEndpoint = `${envConfig.api.apiBaseUrl}/mfa/validate`;
  }

  sendCode(clmId: string): Promise<Auth2FAStatus> {
    const headers = {
      clm_id: clmId,
      Accept: 'application/json'
    };
    return httpService
      .post(this.mfaEmailEndpoint, null, { headers: headers })
      .then(res => handleMfaSuccess(res.data.data))
      .catch(e => Promise.reject(handleMfaEmailError(e.response?.data?.errors)));
  }

  submitCode(codeValue: string, clmId: string): Promise<Auth2FAStatus> {
    const headers = {
      clm_id: clmId,
      Accept: 'application/json'
    };
    return httpService
      .post(this.mfaValidateEndpoint, { code: codeValue }, { headers: headers })
      .then(res => handleMfaSuccess(res.data.data))
      .catch(e => Promise.reject(handleMfaValidateError(e.response?.data?.errors)));
  }
}

export const mfaService = new MFAService();
