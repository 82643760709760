import styled from '@emotion/styled';

export const InvitationPanel = styled.div({
  width: '100%'
});

export const Invitation = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1
});
