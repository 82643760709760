import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const Container = styled.div({
  minHeight: '100vh',
  backgroundColor: theme.color.white,
  width: '240px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
});
