import React from 'react';
import CircleIcon from '../../icons/CircleIcon';
import { Status } from '../../ReportsList/TableStatus/types';
import { STATUS } from '../../ReportsList/tableConfig';
import { theme } from '../../../theme/theme';
import ButtonCustom from '../../ButtonCustom/ButtonCustom';

interface Props {
  buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>;
  isClicked: boolean;
}

const StatusButton: React.FC<Props> = ({ buttonProps, isClicked }): React.ReactElement => {
  const { id, children, onClick } = buttonProps;
  const onStatusButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick(e);
  };
  const label = !children || children === Status.AnyStatus ? 'Status' : children;
  const isDefaultSelection = label === 'Status';

  const getAriaLabel = (): string => {
    return isDefaultSelection ? `Status filter ${Status.AnyStatus} selected` : `Status filter ${label} selected`;
  };

  const fillColor = isDefaultSelection ? theme.color.white : STATUS[(label as string).toUpperCase()]?.color;
  const strokeColor = isDefaultSelection ? theme.color.slate : theme.color.white;

  return (
    <ButtonCustom
      id={id}
      icon={<CircleIcon fillColor={fillColor} strokeColor={strokeColor} />}
      label={label}
      onClick={onStatusButtonClick}
      isOpen={isClicked}
      hasValue={!isDefaultSelection}
      ariaHasPopup="listbox"
      ariaLabel={getAriaLabel()}
    />
  );
};

export default StatusButton;
