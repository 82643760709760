import styled from '@emotion/styled';
import ButtonNoStyles from '../ButtonNoStyles/ButtonNoStyles';
import { theme } from '../../theme/theme';

export const Header = styled.header({
  width: '100%',
  boxShadow: theme.shadow.shadow1
});

export const HeaderMenu = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: 2,
  position: 'relative',
  height: '56px',
  backgroundColor: theme.color.white,
  padding: `0 ${theme.spacing.spaceM} 0 ${theme.spacing.spaceM}`
});

export const MenuLogo = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  span: {
    borderLeft: `1px solid ${theme.color.ink}`,
    paddingLeft: '12px',
    marginLeft: '12px'
  }
});

export const MenuButton = styled(ButtonNoStyles)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing.spaceM,

  '@media (min-width:1200px)': {
    display: 'none'
  },

  '&:focus': {
    outlineOffset: '8px',
    outline: `1px dotted ${theme.color.slate}`
  }
});

export const Menu = styled.div<{ isOpen: boolean }>(({ isOpen }) => ({
  display: 'flex',
  position: 'absolute',
  top: '56px',
  left: 0,
  minHeight: '100%',
  borderTop: `1px solid ${theme.color.shell}`,
  zIndex: 2,
  transform: 'translateX(-100%)',
  animation: `${isOpen ? 'slide-in' : 'slide-out'} 0.5s forwards`,

  '@media (min-width:1200px)': {
    animation: 'slide-out 0.5s forwards'
  },

  '@keyframes slide-in': {
    '100%': { transform: 'translateX(0%)' }
  },

  '@keyframes slide-out': {
    '0%': { transform: 'translateX(0%)' },
    '100%': { transform: 'translateX(-100%)' }
  }
}));

export const LogoLink = styled.a({
  textDecoration: 'none',
  color: theme.color.ink,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  '&:active, &:focus': {
    outline: `1px dotted ${theme.color.slate}`
  },

  span: {
    fontFamily: theme.fontFamily.heading,
    fontSize: '17px',
    fontWeight: theme.fontWeight.medium
  },

  '&:focus:not(:focus-visible)': {
    outline: 'none'
  }
});

export const Overlay = styled.div({
  position: 'fixed',
  display: 'block',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.7)',
  zIndex: 1,

  '@media (min-width:1200px)': {
    display: 'none'
  }
});
