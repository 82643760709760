import React from 'react';
import * as Styled from './styled';
import { Divider } from '@auspost/postmaster-react';
import { format } from 'date-fns';
import { KeyboardKey } from '../../constants/keyboardKeys.enum';
import ErrorIcon from '../icons/ErrorIcon';
import ArrowIcon from '../icons/ArrowIcon';
import { Button } from '../Button/Button';

interface Props {
  id: string;
  dateFromValue?: Date;
  dateToValue?: Date;
  minDateFrom?: Date;
  maxDateFrom?: Date;
  minDateTo?: Date;
  maxDateTo?: Date;
  onCancel: () => void;
  onApply: (dateFrom: Date, dateTo: Date) => void;
}

const DateRange: React.FC<Props> = ({
  id,
  dateFromValue = null,
  dateToValue = null,
  minDateFrom,
  maxDateFrom,
  minDateTo,
  maxDateTo,
  onCancel,
  onApply
}): React.ReactElement => {
  const dateFromRef = React.useRef<HTMLInputElement>();
  const dateToRef = React.useRef<HTMLInputElement>();
  const applyButtonRef = React.useRef<HTMLButtonElement>();

  const [fromError, setFromError] = React.useState('');
  const [toError, setToError] = React.useState('');

  React.useLayoutEffect(() => {
    dateFromRef.current.valueAsDate = dateFromValue;
    dateToRef.current.valueAsDate = dateToValue;

    return () => {
      setFromError('');
      setToError('');
    };
  }, []);

  const onApplyClick = (): void => {
    let hasNoError = true;

    setFromError('');
    setToError('');

    setTimeout(() => {
      const errorMessageDateFrom = validateDateInput(dateFromRef.current);
      const errorMessageDateTo = validateDateInput(dateToRef.current);

      if (errorMessageDateFrom) {
        setFromError(errorMessageDateFrom);
        dateFromRef.current.focus();
        hasNoError = false;
      } else {
        setFromError('');
      }

      if (errorMessageDateTo) {
        setToError(errorMessageDateTo);
        dateFromRef.current.focus();

        if (hasNoError) {
          dateToRef.current.focus();
        }

        hasNoError = false;
      } else {
        setToError('');
      }

      if (hasNoError) {
        dateFromRef.current.valueAsNumber < dateToRef.current.valueAsNumber
          ? onApply(dateFromRef.current.valueAsDate, dateToRef.current.valueAsDate)
          : onApply(dateToRef.current.valueAsDate, dateFromRef.current.valueAsDate);
      }
    });
  };

  const validateDateInput = (dateInput: HTMLInputElement): string => {
    const validity = dateInput.validity;

    if (validity.badInput || validity.rangeUnderflow || validity.rangeOverflow) {
      return 'Enter a valid date';
    }

    if (validity.valueMissing) {
      return 'Enter a date';
    }

    return '';
  };

  const onKeydownDateTo = (event): void => {
    if (event.key === KeyboardKey.RETURN) {
      applyButtonRef.current.click();
    }
  };

  return (
    <Styled.Container>
      <Styled.DateRange>
        <Styled.Date>
          <Styled.Label htmlFor={`${id}-dateFrom`}>From date</Styled.Label>
          <Styled.DateInput
            id={`${id}-dateFrom`}
            type="date"
            max={maxDateFrom && format(maxDateFrom, 'yyyy-MM-dd')}
            min={minDateFrom && format(minDateFrom, 'yyyy-MM-dd')}
            ref={dateFromRef}
            onClick={event => event.preventDefault()}
            ariaInvalid={!!fromError}
            required
            onBlur={() => setFromError(validateDateInput(dateFromRef.current))}
            onChange={() => setFromError(validateDateInput(dateFromRef.current))}
          />
          {fromError && (
            <Styled.Error>
              <ErrorIcon />
              <span id="fromDateError" role="alert">
                {fromError}
              </span>
            </Styled.Error>
          )}
        </Styled.Date>
        <ArrowIcon />
        <Styled.Date>
          <Styled.Label htmlFor={`${id}-dateTo`}>To date</Styled.Label>
          <Styled.DateInput
            id={`${id}-dateTo`}
            type="date"
            max={maxDateTo && format(maxDateTo, 'yyyy-MM-dd')}
            min={minDateTo && format(minDateTo, 'yyyy-MM-dd')}
            ref={dateToRef}
            onClick={event => event.preventDefault()}
            ariaInvalid={!!toError}
            required
            onKeyDown={onKeydownDateTo}
            onBlur={() => setToError(validateDateInput(dateToRef.current))}
            onChange={() => setToError(validateDateInput(dateToRef.current))}
          />
          {toError && (
            <Styled.Error>
              <ErrorIcon />
              <span id="toDateError" role="alert">
                {toError}
              </span>
            </Styled.Error>
          )}
        </Styled.Date>
      </Styled.DateRange>
      <Styled.Divider>
        <Divider />
      </Styled.Divider>
      <Styled.ButtonGroup>
        <Button id={`${id}-cancel`} type="button" variant="secondary" size="xsmall" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          id={`${id}-Apply`}
          type="button"
          variant="secondary-filled"
          size="xsmall"
          onClick={onApplyClick}
          ref={applyButtonRef}
        >
          Apply
        </Button>
      </Styled.ButtonGroup>
    </Styled.Container>
  );
};

export default DateRange;
