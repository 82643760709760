import React from 'react';
import { StyledInput } from './styled';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  required?: boolean;
  describedBy?: string;
  autoCompleteType?: string;
  autoCompletePopupType?: string;
  controls?: string;
  expanded?: boolean;
  /**
   * CLIPBOARD Events
   */
  disableCopy?: boolean;
  disablePaste?: boolean;
  disableCut?: boolean;
  handlePaste?: React.ClipboardEventHandler<HTMLInputElement>;
  handleCopy?: React.ClipboardEventHandler<HTMLInputElement>;
  handleCut?: React.ClipboardEventHandler<HTMLInputElement>;
  handleChange?: React.ChangeEventHandler<HTMLInputElement>;
  handleBlur?: React.FocusEventHandler<HTMLInputElement>;
  handleFocus?: React.FocusEventHandler<HTMLInputElement>;
  handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
  pattern?: string;
}

const PostmasterInput: React.FC<Props> = (props): React.ReactElement => {
  return <StyledInput {...props} />;
};

export default PostmasterInput;
