import { envConfig } from '../../config/env.config';
import { FeatureToggle } from '../../../../../pvoi-components/src/models/featureToggle.model';

const featureToggleService = async (featureUrl?: string): Promise<Array<FeatureToggle>> => {
  const endpoint = featureUrl || envConfig.ui.featureTogglesUrl;

  const handleError = (): FeatureToggle[] => [];
  const handleSuccess = (response: { json: () => unknown }): Array<FeatureToggle> =>
    response.json() as Array<FeatureToggle>;

  return await window.fetch(endpoint).then(handleSuccess).catch(handleError);
};

export default featureToggleService;
