import { envConfig } from 'pvoi-ui/src/config/env.config';
import { Reports, ReportsResponse } from './types';
import { AxiosResponse, AxiosStatic } from 'axios';
import { UtilsService } from 'pvoi-ui/src/services/utils/utilsService';

export class ReportsBaseService {
  reportsEndpoint: string;

  httpService: AxiosStatic;

  constructor(httpService: AxiosStatic) {
    this.httpService = httpService;
    this.reportsEndpoint = `${envConfig.api.apiBaseUrl}/reports`;
  }

  mapReport(report: ReportsResponse): Reports {
    return {
      id: report.id,
      givenName: report.given_name || '',
      familyName: report.last_name || '',
      date: report.created_at && UtilsService.formatTimestamp(report.created_at),
      status: report.status || '',
      checkTypes: report.scopes ? UtilsService.getCheckTypes(report.scopes) : [],
      phone: report.phone_number || '',
      email: report.email || '',
      referenceNumber: report.reference_number || '',
      comments: report.comments || [],
      reportType: report.report_type,
      verifiedAt: report.verification_date && UtilsService.formatTimestamp(report.verification_date),
      verificationMatchType: report.verification_match_type,
      verifiedDetails: {
        givenName: report.verification_details?.given_name,
        familyName: report.verification_details?.family_name,
        birthDate:
          (report.verification_details?.birthdate &&
            UtilsService.formatTimestamp(report.verification_details?.birthdate)) ||
          undefined,
        address: report.verification_details?.address,
        addressVerified: report.verification_details?.address_verified,
        isOver18: report.verification_details?.is_over18,
        watchlist: report.verification_details?.watchlist
      }
    };
  }

  handleDownload(response: AxiosResponse<BlobPart>, type: 'pdf' | 'xls' | 'xlsx', filename?: string): void | string {
    const fileTypes = {
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      xls: 'application/vnd.ms-excel',
      pdf: 'application/pdf'
    };
    const blob = new Blob([response.data], { type: fileTypes[type] });
    const objectUrl = window.URL.createObjectURL(blob);
    const contentDisposition = response.headers['content-disposition'];

    let downloadedFileName = filename;

    if (!downloadedFileName && contentDisposition?.startsWith('attachment')) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        downloadedFileName = matches[1].replace(/['"]/g, '');
      }
    }

    // for localhost testing purpose
    if (
      !downloadedFileName &&
      response.config?.headers &&
      (<string>response.config.headers['Accept']).includes('/xls')
    ) {
      const date = new Date();
      downloadedFileName = `Property_VOI_${date.toLocaleString().replaceAll(/:|,| |\//g, '')}`;
    }

    // 'Property_VOI_12082022124018433.xlsx'
    if (downloadedFileName) {
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = objectUrl;
      link.download = filename ?? downloadedFileName;
      link.click();
      document.body.removeChild(link);
    } else {
      return objectUrl;
    }
  }
}
