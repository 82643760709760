import React from 'react';
import {List as PostmasterList} from '@auspost/postmaster-react';
interface Props {
    type?: 'unordered'
    isIconList?: boolean
}

const List: React.FC<Props> = (props):  React.ReactElement => {
    return (
        <PostmasterList {...props} >
        </PostmasterList>
    );
};
export default List;
