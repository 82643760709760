import React from 'react';
import { Accordion as PostmasterAccordion } from '@auspost/postmaster-react';

interface Props {
  id: string;
  title: string;
  onToggle?: () => void;
  isInitiallyCollapsed?: boolean;
  icon?: React.ReactNode;
}

const Accordion: React.FC<Props> = ({
  id,
  title,
  onToggle,
  isInitiallyCollapsed,
  icon,
  children
}): React.ReactElement => {
  return (
    <PostmasterAccordion
      id={id}
      title={title}
      onToggle={onToggle}
      isInitiallyCollapsed={isInitiallyCollapsed}
      icon={icon}
    >
      {children}
    </PostmasterAccordion>
  );
};

export default Accordion;
