import { EnvConfig } from './typed';

export const pdevEnvConfig: EnvConfig = {
  baseUrl: '/',
  api: {
    apiBaseUrl: 'https://api.pvoi.identityservice-pdev.npe.auspost.com.au',
    digitalApiBaseUrl: 'MOCK_USER' // digitalApiBaseUrl left as MOCK_USER for pdev as it's using mocked user from backend
  },
  ui: {
    loginUrl: '/stubpage?caller=PVOI&product=PVOI&next=',
    featureTogglesUrl: '',
    glamUrl: 'https://ptest.npe.auspost.com.au/account-management/#/business',
    merchantPortal: 'https://ptest.npe.merchant-portal.auspost.com.au/s/'
  },
  pdf: {
    userGuide: 'https://pvoi-document-pdev.s3.ap-southeast-2.amazonaws.com/Property+VOI+Report+User+Guide.pdf',
    diyUserGuide: 'https://pvoi-document-pdev.s3.ap-southeast-2.amazonaws.com/Do+It+Yourself+VOI+Tool+User+Guide.pdf',
    searchTips: 'https://pvoi-document-pdev.s3.ap-southeast-2.amazonaws.com/Viewing+and+Searching+for+VOI+Reports.pdf'
  }
};
