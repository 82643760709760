export const AUTH2FA = {
  APP_NAME: 'VOI',
  HOME_LINK: '/',
  VALIDATION_ERRORS: {
    REQUIRED: 'Enter the security code',
    NOT_ENOUGH_DIGITS: 'Enter 6 digits'
  },
  CODE_INPUT: {
    LABEL: 'Security code'
  },
  SEND_A_CODE: {
    LABEL: 'Send a new code'
  },
  ALERT: {
    CODE_SENT: {
      TITLE: 'Code sent successfully',
      BODY: 'Check your inbox for your new security code.'
    },
    CODE_SENT_FINAL_ATTEMPT: {
      TITLE: 'No new codes remaining',
      BODY: `For security reasons, we cannot send you another code. Check your email for your most recent code.`
    },
    CODE_INCORRECT: {
      TITLE: 'Incorrect code entered',
      BODY: 'Check your security code and try again.'
    },
    CODE_EXP: {
      TITLE: 'Your code has expired',
      BODY: `Select 'Send a new code' below and try again.`
    },
    ONE_MORE_ATTEMPT: {
      TITLE: 'One attempt remaining',
      BODY: 'Your account will be locked for security reasons if your security code is entered incorrectly again.'
    },
    AC_LOCKED_TOO_MANY_RESENT: {
      TITLE: 'Account locked',
      BODY: `We've temporarily locked your account for security reasons, please try again later.`,
    },
    AC_LOCKED_WHEN_LOG_IN: {
      TITLE: 'Account locked',
      BODY_1: `We've temporarily locked your account for security reasons.`,
      BODY_2: `You can try again later.`
    },
    AC_LOCKED_CODE_INCORRECT: {
      TITLE: 'Account locked',
      BODY_1: `We've temporarily locked your account because an incorrect security code was entered too many times.`,
      BODY_2: 'Please try again later.'
    },
    OUTAGE: {
      BODY: 'We are unable to submit the security code at this time, please try again later.',
    }
  },
  EMAIL_SENT: "We've sent you an email with a 6-digit security code.",
  BANNER: {
    TITLE: 'Security check',
    SUBTITLE: 'Enter your security code.'
  },
  HEADS_UP:
    'We will occasionally do a security check when you log in, especially if you switch devices. This helps us keep your account safe.'
};
