import styled from '@emotion/styled';
import { theme } from '../../theme/theme';
import { Link as PostmasterLink } from '@auspost/postmaster-react';

export const LinkNoStyles = styled(PostmasterLink)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '6px 16px',
  textDecoration: 'none',
  color: theme.color.ink,
  fontSize: theme.fontSize.small,
  fontFamily: theme.fontFamily.default,
  '&:focus-visible': {
    outline: `1px dotted ${theme.color.slate}`,
    outlineOffset: '2px'
  },
  svg: {
    width: '16px',
    height: '18px',
    color: theme.color.ash
  }
});
