import React from 'react';
import { Status } from './types';
import * as Styled from './styled';
import { SpeechIcon } from '../../icons/SpeechIcon';
import Tooltip from '../../Tooltip/Tooltip';

interface Props {
  id: string;
  status: { label: Status; color: string };
  hasSpeechIcon?: boolean;
}

const TableStatus: React.FC<Props> = ({ id, status, hasSpeechIcon = false }): React.ReactElement => {
  const statusElement = (): React.ReactElement => (
    <>
      <Styled.Dot color={status.color} />
      <Styled.Text>{status.label}</Styled.Text>
    </>
  );

  return (
    <Styled.Status>
      {hasSpeechIcon ? (
        <>
          {statusElement()}
          <Tooltip id={id} title="Refer to Verifier comments in VOI report">
            <Styled.TooltipContent tabIndex={0} role="tooltip">
              <SpeechIcon />
            </Styled.TooltipContent>
          </Tooltip>
        </>
      ) : (
        statusElement()
      )}
    </Styled.Status>
  );
};

export default TableStatus;
