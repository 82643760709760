export const OUTAGE_CONTENT = {
  INTERNAL_SERVER: {
    HEADER: 'A system error has occurred',
    CONTENT: 'Please try again later'
  },
  FORBIDDEN: {
    HEADER: "Looks like you don't have access",
    CONTENT: 'Ask your administrator to grant you access'
  }
};
