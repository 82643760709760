import React from 'react';
import * as Styled from './styled';

interface Props {
  className?: string;
}

const ButtonNoStyles: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = (props): React.ReactElement => {
  return <Styled.PlainButton {...props}>{props.children}</Styled.PlainButton>;
};

export default ButtonNoStyles;
