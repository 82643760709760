import React from 'react';

const ResetIcon: React.FC = (): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 16.4183 7.58172 20 12 20V22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C15.7599 2 19.0352 4.07509 20.7431 7.14251V4H22.6403V9.53846C22.6403 10.0489 22.2163 10.4615 21.6917 10.4615H16V8.61538H19.2509C17.976 5.88891 15.2086 4 12 4C7.58172 4 4 7.58172 4 12Z"
        fill="#31313D"
      />
    </svg>
  );
};

export default ResetIcon;
