export type StorageType = 'session' | 'local';

class StorageService {
  private sessionStorage: Storage;
  private localStorage: Storage;

  constructor() {
    this.sessionStorage = sessionStorage;
    this.localStorage = localStorage;
  }

  get(key: string, storageType: StorageType): string | null {
    return storageType === 'local' ? this.localStorage.getItem(key) : this.sessionStorage.getItem(key);
  }

  set(key: string, value: string, storageType: StorageType): void {
    storageType === 'local' ? this.localStorage.setItem(key, value) : this.sessionStorage.setItem(key, value);
  }

  remove(key: string, storageType: StorageType): void {
    storageType === 'local' ? this.localStorage.removeItem(key) : this.sessionStorage.removeItem(key);
  }

  clear(storageType?: StorageType): void {
    if (storageType === 'local') {
      this.localStorage.clear();
    } else if (storageType === 'session') {
      this.sessionStorage.clear();
    } else {
      this.sessionStorage.clear();
      this.localStorage.clear();
    }
  }
}
export const storageService = new StorageService();
