import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column'
});

export const Header = styled.div({
  borderLeft: `4px solid ${theme.color.primary}`,
  paddingLeft: '28px',
  h2: {
    fontFamily: theme.fontFamily.heading,
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '0.8px'
  }
});

export const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '33px 32px 0',
  fontFamily: theme.fontFamily.default,
  color: theme.color.ink,
  lineHeight: '24px'
});

export const List = styled.ul({
  marginTop: '24px',
  paddingLeft: '16px'
});

export const ListItem = styled.li({
  marginBottom: '12px',
  '&::marker': {
    color: theme.color.inkSubheading
  },
  p: {
    color: theme.color.ash,
    marginTop: '8px'
  },
  svg: {
    verticalAlign: 'middle !important'
  }
});

export const PhoneAndEmail = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px',
  width: '190px'
});

export const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: theme.fontFamily.default,
  fontSize: '16px',
  lineHeight: '24px',
  p: {
    marginTop: '8px'
  },
  span: {
    marginTop: '8px'
  },
  wordBreak: 'break-word'
});

export const Columns = styled.div({
  display: 'flex'
});

export const Accordions = styled.div({
  ':not(:last-child)': {
    marginBottom: '48px'
  },
  h3: {
    marginBottom: '18px'
  },
  a: {
    color: theme.color.ink,
    '&:hover': {
      textDecoration: 'none'
    }
  }
});
