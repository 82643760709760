import React from 'react';
import { AppRoute } from '../constants/routes.enum';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { envConfig } from '../config/env.config';
import Home from '../pages/Home/Home';
import Outage from '../pages/Outage/Outage';
import ReportDetails from '../pages/ReportDetails/ReportDetails';
import Help from '../pages/Help/Help';
import StubPage from '../pages/StubPage/StubPage';
import Invitation from '../pages/Invitation/Invitation';
import InvitationConfirmation from '../pages/InvitationConfirmation/InvitationConfirmation';
import CheckDetails from '../pages/CheckDetails/CheckDetails';
import AuthenticationProvider from '@auspost/pvoi-components/src/components/AuthenticationProvider/AuthenticationProvider';
import MultiFactorAuth from '../pages/MultiFactorAuth/MultiFactorAuth';

const AppRouter: React.FC = () => {
  // TODO: StubPage route to be removed once the authentication part is implemented
  return (
    <BrowserRouter basename={envConfig.baseUrl}>
      <AuthenticationProvider>
        <Routes>
          <Route path={AppRoute.Home} element={<PrivateRoute element={<Home />} />} />
          <Route path={AppRoute.ReportDetails} element={<PrivateRoute element={<ReportDetails />} />} />
          <Route path={AppRoute.CheckDetails} element={<PrivateRoute element={<CheckDetails />} />} />
          <Route path={AppRoute.Invitation} element={<PrivateRoute element={<Invitation />} />} />
          <Route
            path={AppRoute.InvitationConfirmation}
            element={<PrivateRoute element={<InvitationConfirmation />} />}
          />
          <Route path={AppRoute.Outage} element={<PrivateRoute element={<Outage />} />} />
          <Route path={AppRoute.Help} element={<PrivateRoute element={<Help />} />} />
          <Route path={AppRoute.MultiFactorAuth} element={<PrivateRoute element={<MultiFactorAuth />} />} />
          <Route path={AppRoute.StubPage} element={<StubPage />} />
          <Route path="*" element={<Navigate to={AppRoute.Home} replace />} />
        </Routes>
      </AuthenticationProvider>
    </BrowserRouter>
  );
};

export default AppRouter;
