import React from 'react';
import { Modal as PostmasterModal } from '@auspost/postmaster-react';

interface Props {
  children?: React.ReactNode;
  isModalShowing: boolean;
  isContextual?: boolean;
  ariaDialogLabel: string;
  onHide: () => void;
  autoFocus?: boolean;
  focusLock?: boolean;
  restoreFocus?: boolean;
  preventPageScroll?: boolean;
  dialogId?: string;
  dialogClassName?: string;
  modalClassName?: string;
  containingElement?: HTMLElement;
  preventHideOnBackgroundSelect?: boolean;
}

const Modal: React.FC<Props> = (props): React.ReactElement => {
  // TODO accessibility issue - on tab keypress, focus doesn't stay within modal
  return <PostmasterModal {...props}>{props.children}</PostmasterModal>;
};

export default Modal;
