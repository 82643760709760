import React, { useState, ChangeEvent, FC, ReactElement } from 'react';
import * as Styled from './styled';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Stack, AppStateModel, Button, Heading, Text, Notification, PostmasterInputField as InputField } from '../../';
import {
  FORM_VALIDATION,
  PAGE_HEADING,
  PAGE_HINT,
  CP_HINT,
  SURNAME,
  GIVEN_NAME,
  EMAIL,
  NAME_HINT,
  CLEAR_ARIA_LABEL
} from './constants';

type Inputs = {
  surname: string;
  givenName: string;
  phoneNumber: string;
  email: string;
};

interface Props {
  appState: AppStateModel;
  onFormSubmission: () => void;
}

const InvitationForm: FC<Props> = ({ appState, onFormSubmission }): ReactElement => {
  const initialState = {
    surname: appState.invitationDetails.surname || '',
    givenName: appState.invitationDetails.firstName || '',
    email: appState.invitationDetails.email || ''
  };
  const [field, setField] = useState(() => initialState);
  const nameMaxLength = 50;

  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors }
  } = useForm<Inputs>({
    mode: 'onSubmit'
  });

  const onSubmit: SubmitHandler<Inputs> = data => {
    appState.invitationDetails.email = data.email;
    appState.invitationDetails.surname = data.surname;
    appState.invitationDetails.firstName = data.givenName;
    onFormSubmission();
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    // Mask non numbers
    event.target.value = value.replace(/[^a-zA-Z '-]/g, '');
  };

  const clearFields = () => {
    setFocus('surname');
    reset();
    appState.invitationDetails.email = '';
    appState.invitationDetails.surname = '';
    appState.invitationDetails.firstName = '';
    setField({
      surname: '',
      givenName: '',
      email: ''
    });
  };

  return (
    <Styled.Container>
      <Heading level="h3">{PAGE_HEADING}</Heading>
      <Text variant="caption" weight="regular" as="span">
        {PAGE_HINT}
      </Text>
      <Notification
        type={'notice'}
        status={'info'}
        message={CP_HINT + appState.selectedClm.clmName}
        isDismissible={false}
      />

      <Styled.formStyle onSubmit={handleSubmit(onSubmit)} noValidate>
        <Styled.FormContainer>
          <Stack space="large">
            <Stack space="small">
              <InputField
                required
                value={field.surname}
                label={SURNAME}
                variant="large"
                name="surname"
                id="surnameInput-input"
                autoComplete="off"
                error={errors.surname?.message}
                {...register('surname', {
                  onChange: e => {
                    onNameChange;
                    setField({ ...field, surname: e.target.value });
                  },
                  required: FORM_VALIDATION.SURNAME.REQUIRED,
                  maxLength: {
                    value: nameMaxLength,
                    message: FORM_VALIDATION.SURNAME.MAX_LENGTH
                  }
                })}
              />
              <Text variant="caption" weight="regular" as="p">
                {NAME_HINT}
              </Text>
            </Stack>
            <Stack space="small">
              <InputField
                required
                value={field.givenName}
                label={GIVEN_NAME}
                variant="large"
                name="givenNames"
                id="givenNamesInput-input"
                autoComplete="off"
                error={errors.givenName?.message}
                {...register('givenName', {
                  onChange: e => {
                    onNameChange;
                    setField({ ...field, givenName: e.target.value });
                  },
                  required: FORM_VALIDATION.GIVEN_NAME.REQUIRED,
                  maxLength: {
                    value: nameMaxLength,
                    message: FORM_VALIDATION.GIVEN_NAME.MAX_LENGTH
                  }
                })}
              />
              <Text variant="caption" weight="regular" as="p">
                {NAME_HINT}
              </Text>
            </Stack>
            <InputField
              required
              value={field.email}
              label={EMAIL}
              variant="large"
              name="email"
              id="emailInput-input"
              autoComplete="off"
              error={errors.email?.message}
              {...register('email', {
                onChange: e => setField({ ...field, email: e.target.value }),
                pattern: {
                  value: /^[a-zA-Z0-9_.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,64}/,
                  message: FORM_VALIDATION.EMAIL.INVALID
                },
                required: FORM_VALIDATION.EMAIL.REQUIRED
              })}
            />
          </Stack>
        </Styled.FormContainer>
        <Styled.ButtonGroup>
          <Button id="continue-button" variant="primary" size="medium" type="submit">
            Continue
          </Button>
          <Button
            id="clear-button"
            variant="secondary"
            size="medium"
            type="button"
            onClick={() => clearFields()}
            aria-label={CLEAR_ARIA_LABEL}
          >
            Clear
          </Button>
        </Styled.ButtonGroup>
      </Styled.formStyle>
    </Styled.Container>
  );
};

export default InvitationForm;
