import React from 'react';
import { Page } from '../../components';
import * as Styled from './styled';
import { HOME_MENU_ITEMS } from '../Home/constants';
import { PAGE_LOAD_ANALYTICS } from '../../constants/analytics';
import { analyticsService, sendInvitationService } from '../../services';
import { useAppContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../constants/routes.enum';
import { InvitationDetailsPage as InvitationDetailsBase } from '@auspost/pvoi-components';

const InvitationConfirmation: React.FC = (): React.ReactElement => {
  const appState = useAppContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    analyticsService.trackPage(
      PAGE_LOAD_ANALYTICS.INVITATION_CONFIRMATION.CATEGORY,
      PAGE_LOAD_ANALYTICS.INVITATION_CONFIRMATION.DESCRIPTION
    );
  }, []);

  const onBack = () => {
    navigate(AppRoute.Invitation);
  };

  const onGoToDashboard = () => {
    navigate(AppRoute.Home);
  };

  const onCancel = () => {
    appState.invitationDetails.email = '';
    appState.invitationDetails.surname = '';
    appState.invitationDetails.firstName = '';
    navigate(AppRoute.Invitation);
  };

  return (
    <Page menuItems={HOME_MENU_ITEMS()} showMenu={true}>
      <Styled.Invitation>
        <Styled.InvitationPanel>
          <InvitationDetailsBase
            sendInvitationService={sendInvitationService}
            appState={appState}
            onBack={() => onBack()}
            onSendAnother={() => onCancel()}
            onGoToDashboard={() => onGoToDashboard()}
            onCancel={() => onCancel()}
          />
        </Styled.InvitationPanel>
      </Styled.Invitation>
    </Page>
  );
};

export default InvitationConfirmation;
