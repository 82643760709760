import React from 'react';
import { theme } from '../../theme/theme';

const TickIcon: React.FC = (): React.ReactElement => {
  return (
    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.178876 3.83204C-0.0686001 4.08534 -0.0548515 4.59195 0.192624 4.83858L3.21045 7.80489C3.4648 8.05819 3.9735 8.06486 4.23473 7.82489L4.71593 7.37827L5.75395 6.41173L10.7997 1.74563C11.0609 1.50566 11.0678 1.01238 10.8135 0.765748L10.2291 0.185819C9.97479 -0.0608173 9.46609 -0.0608173 9.21174 0.179153L4.27597 4.95857C4.02162 5.20521 3.51292 5.19854 3.26545 4.9519L1.62248 3.34543C1.37501 3.09213 0.880057 3.0988 0.632581 3.35877L0.178876 3.83204Z"
        fill={theme.color.slate}
      />
    </svg>
  );
};

export default TickIcon;
