import { Heading, Link, Text } from '@auspost/pvoi-components';
import * as Styled from './styled';
import React from 'react';
import { CONTACT_US, SR_EMAIL, SR_TIME } from './constants';
import { analyticsService } from '../../../services';
import { CLICK_ANALYTICS } from '../../../constants/analytics';

export const ContactUs: React.FC = (): React.ReactElement => {
  return (
    <Styled.Container>
      <Styled.Header>
        <Heading level="h3" as="h2" id="header-contact-us">
          {CONTACT_US.HEADING}
        </Heading>
      </Styled.Header>
      <Styled.Content>
        <Text variant="body" weight="regular" as="span">
          {CONTACT_US.PARAGRAPH}
        </Text>
        <Styled.Columns>
          <Styled.PhoneAndEmail id="contactus-phone">
            <Text variant="body" weight="bold" as="span">
              {CONTACT_US.PHONE.HEADING}
            </Text>
            <Text variant="body" weight="regular" as="span">
              <Link
                id="contact-phone"
                href={`tel:${CONTACT_US.PHONE.NUMBER}`}
                onClick={() =>
                  analyticsService.trackClick(
                    CLICK_ANALYTICS.CATEGORY.HELP_CENTRE,
                    CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.CONTACT_US.PHONE
                  )
                }
              >
                {CONTACT_US.PHONE.NUMBER}
              </Link>
            </Text>
            <span aria-label={SR_TIME}>
              <span aria-hidden={true}>
                <Text variant="body" weight="regular" as="span">
                  {CONTACT_US.PHONE.TIME}
                </Text>
              </span>
            </span>
          </Styled.PhoneAndEmail>
          <Styled.PhoneAndEmail id="contactus-email">
            <Text variant="body" weight="bold" as="span">
              {CONTACT_US.EMAIL.HEADING}
            </Text>
            <Text variant="body" weight="regular" as="span">
              <Link
                id="contact-email"
                href={`mailto:${CONTACT_US.EMAIL.EMAIL_ADDRESS}`}
                aria-label={SR_EMAIL}
                onClick={() =>
                  analyticsService.trackClick(
                    CLICK_ANALYTICS.CATEGORY.HELP_CENTRE,
                    CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.CONTACT_US.PHONE
                  )
                }
              >
                {CONTACT_US.EMAIL.EMAIL_ADDRESS}
              </Link>
            </Text>
          </Styled.PhoneAndEmail>
        </Styled.Columns>
      </Styled.Content>
    </Styled.Container>
  );
};
