import React, { isValidElement } from 'react';
import { AUTH2FA } from './constants';
import * as Styled from './styled';
import { Notification } from '../index';
import { Auth2FAStatus } from './types';
import { DomService } from '../../services/dom/domService';

interface Props {
  authStatus: Auth2FAStatus;
  alertTitle: string | React.ReactNode;
  alertBody: string | React.ReactNode;
}

const Auth2FAAlert: React.FC<Props> = ({ authStatus, alertTitle, alertBody }): React.ReactElement => {
  const getTitle = defaultTitle => {
    return alertTitle ? (
      isValidElement(alertTitle) ? (
        alertTitle // alertTitle is a react element
      ) : (
        <Styled.AlertTitle>{alertTitle}</Styled.AlertTitle> // alertTitle is a string
      )
    ) : isValidElement(defaultTitle) ? (
      defaultTitle // default title is a react element
    ) : (
      <Styled.AlertTitle>{defaultTitle}</Styled.AlertTitle> // default title is a string
    );
  };

  const getBody = defaultBody => {
    return alertBody ? (
      isValidElement(alertBody) ? (
        alertBody // alertBody is a react element
      ) : (
        <Styled.AlertBody>{alertBody}</Styled.AlertBody> // alertBody is a string
      )
    ) : isValidElement(defaultBody) ? (
      defaultBody // default body is a react element
    ) : (
      <Styled.AlertBody>{defaultBody}</Styled.AlertBody> // default body is a string
    );
  };

  switch (authStatus) {
    case Auth2FAStatus.CODE_SENT_SUCCESS:
      return (
        <Styled.AlertWrapper>
          <Notification
            type="alert"
            status="success"
            id="codeSentAlert"
            message={
              <>
                {getTitle(AUTH2FA.ALERT.CODE_SENT.TITLE)}
                {getBody(AUTH2FA.ALERT.CODE_SENT.BODY)}
              </>
            }
            isDismissible={false}
          />
        </Styled.AlertWrapper>
      );

    case Auth2FAStatus.CODE_SENT_FINAL_ATTEMPT:
      return (
        <Styled.AlertWrapper>
          <Notification
            type="alert"
            status="error"
            id="codeSentFinalAttemptAlert"
            message={
              <>
                {getTitle(AUTH2FA.ALERT.CODE_SENT_FINAL_ATTEMPT.TITLE)}
                {getBody(AUTH2FA.ALERT.CODE_SENT_FINAL_ATTEMPT.BODY)}
              </>
            }
            isDismissible={false}
          />
        </Styled.AlertWrapper>
      );

    case Auth2FAStatus.CODE_INCORRECT:
      return (
        <Styled.AlertWrapper>
          <Notification
            type="alert"
            status="error"
            id="codeIncorrectAlert"
            message={
              <>
                {getTitle(AUTH2FA.ALERT.CODE_INCORRECT.TITLE)}
                {getBody(AUTH2FA.ALERT.CODE_INCORRECT.BODY)}
              </>
            }
            isDismissible={false}
          />
        </Styled.AlertWrapper>
      );

    case Auth2FAStatus.CODE_EXP:
      return (
        <Styled.AlertWrapper>
          <Notification
            type="alert"
            status="error"
            id="codeExpAlert"
            message={
              <>
                {getTitle(AUTH2FA.ALERT.CODE_EXP.TITLE)}
                {getBody(AUTH2FA.ALERT.CODE_EXP.BODY)}
              </>
            }
            isDismissible={false}
          />
        </Styled.AlertWrapper>
      );

    case Auth2FAStatus.ONE_MORE_ATTEMPT:
      return (
        <Styled.AlertWrapper>
          <Notification
            type="alert"
            status="error"
            id="oneMoreAttemptAlert"
            message={
              <>
                {getTitle(AUTH2FA.ALERT.ONE_MORE_ATTEMPT.TITLE)}
                {getBody(AUTH2FA.ALERT.ONE_MORE_ATTEMPT.BODY)}
              </>
            }
            isDismissible={false}
          />
        </Styled.AlertWrapper>
      );

    case Auth2FAStatus.AC_LOCKED_TOO_MANY_RESENT:
      return (
        <Styled.AlertWrapper>
          <Notification
            type="alert"
            status="error"
            id="acLockedTooManyAlert"
            message={
              <>
                {getTitle(AUTH2FA.ALERT.AC_LOCKED_TOO_MANY_RESENT.TITLE)}
                {alertBody ?? getBody(AUTH2FA.ALERT.AC_LOCKED_TOO_MANY_RESENT.BODY)}
              </>
            }
            isDismissible={false}
          />
        </Styled.AlertWrapper>
      );

    case Auth2FAStatus.AC_LOCKED_WHEN_LOG_IN:
      return (
        <Styled.AlertWrapper>
          <Notification
            type="alert"
            status="error"
            id="acLockedWhenLogin"
            message={
              <>
                {getTitle(AUTH2FA.ALERT.AC_LOCKED_WHEN_LOG_IN.TITLE)}
                {getBody(
                  <>
                    <Styled.AlertBody>{AUTH2FA.ALERT.AC_LOCKED_WHEN_LOG_IN.BODY_1}</Styled.AlertBody>
                    <Styled.AlertBody
                      dangerouslySetInnerHTML={DomService.setInnerHTML(AUTH2FA.ALERT.AC_LOCKED_WHEN_LOG_IN.BODY_2)}
                    />
                  </>
                )}
              </>
            }
            isDismissible={false}
          />
        </Styled.AlertWrapper>
      );

    case Auth2FAStatus.AC_LOCKED_CODE_INCORRECT:
      return (
        <Styled.AlertWrapper>
          <Notification
            type="alert"
            status="error"
            id="acLockedCodeIncorrectAlert"
            message={
              <>
                {getTitle(AUTH2FA.ALERT.AC_LOCKED_CODE_INCORRECT.TITLE)}
                {getBody(
                  <>
                    <Styled.AlertBody>{AUTH2FA.ALERT.AC_LOCKED_CODE_INCORRECT.BODY_1}</Styled.AlertBody>
                    <Styled.AlertBody>{AUTH2FA.ALERT.AC_LOCKED_CODE_INCORRECT.BODY_2}</Styled.AlertBody>
                  </>
                )}
              </>
            }
            isDismissible={false}
          />
        </Styled.AlertWrapper>
      );

    case Auth2FAStatus.OUTAGE:
      return (
        <Notification
          type="alert"
          status="error"
          id="authOutage"
          message={getBody(AUTH2FA.ALERT.OUTAGE.BODY)}
          isDismissible={false}
        />
      );

    default:
      return null;
  }
};

export default Auth2FAAlert;
