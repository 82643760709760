import { AppRoute } from '../../constants/routes.enum';
import React from 'react';
import { MenuItem } from '../../components/Menu/types';
import { analyticsService } from '../../services';
import { CLICK_ANALYTICS } from '../../constants/analytics';
import { VerifyIcon, SendIcon } from '@auspost/pvoi-components';
import { useAppContext } from '../../context';

export function HOME_MENU_ITEMS(): MenuItem[] {
  const appState = useAppContext();
  const isIDV = appState.isIDV;
  return [
    {
      id: 'voiReportsMenuLink',
      label: `${isIDV ? 'Results' : 'VOI reports'}`,
      icon: <VerifyIcon />,
      url: AppRoute.Home,
      nestedUrl: AppRoute.CheckDetails,
      onClick: () => {
        analyticsService.trackClick(
          CLICK_ANALYTICS.CATEGORY.SIDE_MENU,
          CLICK_ANALYTICS.INTERACTION.SIDE_MENU.VOI_REPORTS
        );
      }
    },
    {
      id: 'didInvitationMenuLink',
      label: 'New invite',
      icon: <SendIcon />,
      url: AppRoute.Invitation,
      onClick: () => {
        analyticsService.trackClick(
          CLICK_ANALYTICS.CATEGORY.SIDE_MENU,
          CLICK_ANALYTICS.INTERACTION.SIDE_MENU.DID_INVITATION
        );
      },
      type: 'IDV'
    }
  ];
}

export const HELP_LINK = {
  id: 'helpMenuLink',
  label: 'Help Centre',
  url: AppRoute.Help,
  onClick: () => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.SIDE_MENU, CLICK_ANALYTICS.INTERACTION.SIDE_MENU.HELP_CENTRE);
  }
};

export const HELP_LINK_IDV = {
  id: 'helpMenuLinkIDV',
  label: 'Help & support',
  url: 'https://survey.auspost.com.au/cgi-bin/qwebcorporate.dll?idx=5GQ6VH&Channel=Web',
  onClick: () => {
    analyticsService.trackClick(CLICK_ANALYTICS.CATEGORY.SIDE_MENU, CLICK_ANALYTICS.INTERACTION.SIDE_MENU.HELP_CENTRE);
  }
};

export const MENU_HEADING = 'Menu';
