import { EnvConfig } from './typed';

export const prodEnvConfig: EnvConfig = {
  ui: {
    loginUrl: 'https://pvoi.identityservice.auspost.com.au',
    authScriptUrl: '//auspost.com.au/auth0-ui-integration-module/module.js',
    authWebConfig: {
      clientId: 'qnoqZqlXu2zuJHhnR2wDYyFRGypt7Bel',
      env: 'prod',
      redirectUrl: 'https://pvoi.identityservice.auspost.com.au',
      auth0SessionRequired: true,
      audience: 'https://digitalapi.auspost.com.au/high',
      scope: 'https://scopes.auspost.com.au/auth/voi/user',
      caller: 'PVOI',
      product: 'PVOI',
      channel: 'WEB',
      cssoSessionConfig: {
        skipCheck: true,
        skipExchange: true,
        skipRefresh: true
      },
      verifyAuthenticationOnFocus: true
    }
  }
};
