import React from 'react';
import Ellipsis from '../icons/Ellipsis';
import * as Styled from './styled';

const MoreButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props): React.ReactElement => {
  return (
    <Styled.Button type="button" {...props}>
      <Ellipsis />
    </Styled.Button>
  );
};

export default MoreButton;
