import React from 'react';
import { envConfig } from '../../config/env.config';
import Spinner from '../Spinner/Spinner';
import { authenticationService } from '../../services/authenticationService/authenticationService';

const AuthenticationProvider: React.FC = ({ children }) => {
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // if no auth0 script provided then skip
    if (!envConfig.ui.authScriptUrl) {
      setLoading(false);
      return;
    }

    // remove auth0 mock script
    document.body.querySelectorAll('script').forEach(script => {
      if (script.src.indexOf('auth0-script-mock') >= 0) {
        document.body.removeChild(script);
      }
    });

    const script = document.createElement('script');
    script.src = envConfig.ui.authScriptUrl;
    script.onload = () => {
      authenticationService.initialise().then(() => {
        authenticationService.isAuthenticated().then(isAuthenticated => {
          // TEMP FIX: force login and set maxAge to 0 to prevent users being
          // stuck in a reauth-required loop on session timeout
          isAuthenticated ? setLoading(false) : authenticationService.login({forceLogin: true, maxAge: '0'});
        });
      });
    };
    script.onerror = () => {
      console.error('Error in authenticating!');
    };
    document.body.appendChild(script);
  }, []);

  return isLoading ? <Spinner colour="secondary" variant="full-page" /> : <>{children}</>;
};

export default AuthenticationProvider;
