import styled from '@emotion/styled';
import { theme } from '../../../theme/theme';

export const Status = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

export const Dot = styled.span(({ color }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  display: 'inline-block',
  backgroundColor: color
}));

export const Text = styled.span({
  padding: '0 8px'
});

export const TooltipContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '&:focus': {
    outline: `1px dotted ${theme.color.slate}`,
    outlineOffset: '2px'
  },
  '&:focus:not(:focus-visible)': {
    outline: 'none'
  }
});
