import { Heading, Accordion, DomService } from '@auspost/pvoi-components';
import * as Styled from './styled';
import React from 'react';
import { FREQUENTLY_ASKED } from './constants';
import { useHelpAnalytics } from './useHelpAnalytics';
import { CLICK_ANALYTICS } from '../../../constants/analytics';

export const FAQs: React.FC = (): React.ReactElement => {
  useHelpAnalytics([
    { id: 'voiUserGuide-1', interactionDescription: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.FAQ.VOI_USER_GUIDE_1 },
    { id: 'landTitleEmail-1', interactionDescription: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.FAQ.LAND_TITLE_EMAIL_1 },
    { id: 'voiUserGuide-2', interactionDescription: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.FAQ.VOI_USER_GUIDE_2 },
    { id: 'landTitleEmail-2', interactionDescription: CLICK_ANALYTICS.INTERACTION.HELP_CENTRE.FAQ.LAND_TITLE_EMAIL_2 }
  ]);
  const buildAccordion = section => {
    return (
      <Styled.Accordions>
        <Heading level="h4" as="h3">
          {section.HEADING}
        </Heading>
        {section.CONTENTS.map((item, index) => {
          return (
            <Accordion
              key={`faqs-${index}`}
              id={`faqs-${index}`}
              title={item.QUESTION}
              onToggle={() => {
                return;
              }}
            >
              <span dangerouslySetInnerHTML={DomService.setInnerHTML(item.ANSWER, { ADD_ATTR: ['target'] })} />
            </Accordion>
          );
        })}
      </Styled.Accordions>
    );
  };
  return (
    <Styled.Container>
      <Styled.Header>
        <Heading level="h3" as="h2" id="header-faqs">
          FAQs
        </Heading>
      </Styled.Header>
      <Styled.Content>
        {buildAccordion(FREQUENTLY_ASKED.SETUP)}
        {buildAccordion(FREQUENTLY_ASKED.USE)}
      </Styled.Content>
    </Styled.Container>
  );
};
