import styled from '@emotion/styled';
import { theme } from '../../theme/theme';
import Spinner from '../Spinner/Spinner';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
});

export const FullPageSpinner = styled(Spinner)({
  position: 'absolute',
  top: '44%',
  left: '50%',

  '&::before': {
    height: '36px',
    width: '36px'
  }
});

export const MetadataContainer = styled.div({
  display: 'flex',
  borderTop: `1px solid ${theme.color.shell}`
});

export const PdfContainer = styled.div({
  width: '100%'
});

export const TabletView = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  margin: '30vh 0 auto 0'
});
