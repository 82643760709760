import React from 'react';

const Ellipsis: React.FC = (): React.ReactElement => {
  return (
    <svg width="20" height="20" viewBox="0 0 21 5" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.06667 4.46659C0.925646 4.46659 0 3.54094 0 2.39992C0 1.2589 0.925646 0.333252 2.06667 0.333252C3.20769 0.333252 4.13333 1.2589 4.13333 2.39992C4.13333 3.54094 3.20769 4.46659 2.06667 4.46659ZM17.9354 4.46659C16.7944 4.46659 15.8687 3.54094 15.8687 2.39992C15.8687 1.2589 16.7944 0.333252 17.9354 0.333252C19.0764 0.333252 20.0021 1.2589 20.0021 2.39992C20.0021 3.54094 19.0764 4.46659 17.9354 4.46659ZM7.93436 2.39992C7.93436 3.54094 8.86001 4.46659 10.001 4.46659C11.142 4.46659 12.0677 3.54094 12.0677 2.39992C12.0677 1.2589 11.142 0.333252 10.001 0.333252C8.86001 0.333252 7.93436 1.2589 7.93436 2.39992Z"
        fill="#212129"
      />
    </svg>
  );
};

export default Ellipsis;
