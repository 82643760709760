import React from 'react';
import * as Styled from './styled';
import TickIcon from '../icons/TickIcon';
import { useOnPopupClose } from '../../hooks/useOnPopupClose';
import { KeyboardKey } from '../../constants/keyboardKeys.enum';
import Divider from '../Divider/Divider';

interface Props {
  id: string;
  renderButton: (buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>, isClicked: boolean) => React.ReactElement;
  listItems: string[];
  width?: string;
  otherSection?: React.ReactNode;
  hasTriangleTip?: boolean;
  onSelect: (value: string) => void;
  selectedValue?: string;
}

const Select: React.FC<Props> = ({
  id,
  listItems,
  renderButton,
  width = '100%',
  otherSection,
  hasTriangleTip = false,
  onSelect,
  selectedValue
}): React.ReactElement => {
  const defaultSelectedValue = listItems[0];
  const [isClicked, setClicked] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = React.useState<string>(selectedValue || defaultSelectedValue);
  const listItemRef = React.useRef<HTMLLIElement>(null);
  const selectRef = React.useRef<HTMLDivElement>(null);
  const ulRef = React.useRef<HTMLUListElement>(null);

  const ButtonComponent = renderButton(
    {
      id: `${id}-button`,
      onClick: () => setClicked(!isClicked),
      children: selectedOption
    },
    isClicked
  );

  const onOptionClicked = (
    event: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<HTMLLIElement>,
    option: string
  ) => {
    event.preventDefault();
    setSelectedOption(option);
    selectRef.current.querySelector('button').focus();
    onSelect(option);
    setClicked(false);
  };

  const focusOnOption = (index: number): void => {
    const li = ulRef.current?.getElementsByTagName('li')[index];
    li && li.focus();
  };

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLLIElement>, index: number): void => {
    if (event.key === KeyboardKey.RETURN || event.key === KeyboardKey.SPACE) {
      const option = event.target as HTMLElement;
      event.preventDefault();
      onOptionClicked(event, option.innerText);
    }

    if (event.key === KeyboardKey.DOWN) {
      focusOnOption(index + 1);
    }

    if (event.key === KeyboardKey.UP) {
      focusOnOption(index - 1);
    }

    if (!event.shiftKey && event.key === KeyboardKey.TAB && index === listItems.length - 1) {
      setClicked(false);
    }
  };

  const focusOnFirstElementInTheListFromButton = (event: KeyboardEvent) => {
    if (event.key === KeyboardKey.DOWN && (event.target as HTMLElement).id === `${id}-button`) {
      focusOnOption(0);
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', focusOnFirstElementInTheListFromButton);

    return () => {
      setClicked(false);
      setSelectedOption(selectedValue || defaultSelectedValue);
      document.removeEventListener('keydown', focusOnFirstElementInTheListFromButton);
    };
  }, []);

  React.useEffect(() => {
    // reset the list to original state
    if (!selectedValue && selectedOption !== defaultSelectedValue) {
      setSelectedOption(defaultSelectedValue);
    }
  }, [selectedValue]);

  useOnPopupClose(selectRef, () => setClicked(false));

  return (
    <Styled.Container id={id} ref={selectRef}>
      {ButtonComponent}
      {isClicked && (
        <Styled.ListContainer
          id={`${id}-list`}
          role="listbox"
          aria-activedescendant={selectedOption}
          width={width}
          ref={ulRef}
          hasTriangleTip={hasTriangleTip}
        >
          {listItems.map((item, index) => {
            const isSelected = selectedOption === item;
            return (
              <Styled.ListItem
                tabIndex={0}
                id={`${id}-listItem-${index + 1}`}
                ref={listItemRef}
                key={item}
                role="option"
                onClick={event => onOptionClicked(event, item)}
                aria-selected={isSelected}
                isSelected={isSelected}
                onKeyDown={event => handleKeyDownEvent(event, index)}
              >
                {item}
                {isSelected && <TickIcon />}
              </Styled.ListItem>
            );
          })}
          {/* voi specific - remove other section if moving component to Postmaster */}
          {otherSection && (
            <Styled.OtherSection>
              <Divider />
              {otherSection}
            </Styled.OtherSection>
          )}
        </Styled.ListContainer>
      )}
    </Styled.Container>
  );
};

export default Select;
