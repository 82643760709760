import { envConfig } from 'pvoi-ui/src/config/env.config';
import { AxiosStatic } from 'axios';

export class SendInvitationService {
  invitationsEndpoint: string;

  httpService: AxiosStatic;

  constructor(httpService: AxiosStatic) {
    this.httpService = httpService;
    this.invitationsEndpoint = `${envConfig.api.apiBaseUrl}/invitations`;
  }

  sendInvitation(clmId: string, clientId: string, firstName: string, lastName: string, email: string): Promise<void> {
    const header = {
      clm_id: clmId
    };

    const body = {
      clientId: clientId,
      recipient: {
        firstName: firstName,
        lastName: lastName,
        email: email
      }
    };

    return this.httpService
      .post(`${this.invitationsEndpoint}`, body, {
        headers: header
      })
      .then(res => console.log(res))
      .catch(error => Promise.reject(error.response.data.errors));
  }
}
