import React from 'react';

const VerifyIcon: React.FC = (): React.ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.56436 0H19.5492L24 4.58V24H4.56436V22.24L6.50793 20.24V22H22.0564V6H18.1693V2H6.50793V3.94C5.79853 4.3 5.14743 4.76 4.56436 5.3V0ZM17.1976 12C17.1976 15.859 14.1462 19 10.3952 19C8.86945 19 7.46426 18.475 6.32922 17.599L1.3741 22.697L0 21.283L4.95512 16.185C4.10384 15.016 3.59268 13.569 3.59268 12C3.59268 8.141 6.64407 5 10.3952 5C14.1462 5 17.1976 8.141 17.1976 12ZM10.3952 7C13.0744 7 15.2541 9.243 15.2541 12C15.2541 14.757 13.0744 17 10.3952 17C7.71595 17 5.53624 14.757 5.53624 12C5.53624 9.243 7.71595 7 10.3952 7ZM10.4748 14.707C10.2853 14.902 10.0366 15 9.78779 15C9.53901 15 9.29023 14.902 9.10074 14.707L6.98711 12.532L8.36121 11.118L9.78779 12.586L12.6234 9.668L13.9975 11.082L10.4748 14.707Z"
        fill="#919194"
      />
    </svg>
  );
};

export default VerifyIcon;
