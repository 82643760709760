import React from 'react';
import { Link as PostmasterLink } from '@auspost/postmaster-react';
import * as Styled from './styled';
import { LinkProps } from './types';

const Link: React.FC<LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = (props): React.ReactElement => (
  <Styled.Link>
    <PostmasterLink {...props} />
  </Styled.Link>
);

export default Link;
