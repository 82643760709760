import styled from '@emotion/styled';
import { Button as PostmasterButton } from '@auspost/postmaster-react';
import { theme } from '../../theme/theme';

export const Button = styled(PostmasterButton)({
  '&:focus': {
    outline: `1px dotted ${theme.color.slate}`,
    outlineOffset: '2px'
  },
  '&:focus:not(:focus-visible)': {
    outline: 'none'
  },
  padding: '8px'
});
