import { envConfig } from '../../config/env.config';
import { httpService } from '../';
import { ProfileResponse, ProfileOrganisationType, ProfileType } from './types';

class ProfileService {
  profileEndpoint: string;

  constructor() {
    this.profileEndpoint = `${envConfig.api.apiBaseUrl}/profile`;
  }

  getProfile(): Promise<ProfileType> {
    const headers = {
      Accept: 'application/json'
    };
    return httpService
      .get(this.profileEndpoint, { headers: headers })
      .then(res => this.mapProfileResponse(res.data.data))
      .catch(e => Promise.reject(e?.response?.data.errors));
  }

  private mapProfileResponse(response: ProfileResponse): ProfileType {
    const organisations: ProfileOrganisationType[] = response.organisations.map(org => {
      return {
        clmId: org.clm_id,
        role: org.role,
        organisationName: org.organisation_name,
        givenName: org.given_name,
        lastName: org.last_name,
        isIDV: org.is_idv,
        defaultClientId: org?.default_client_id
      };
    });

    return {
      customerNumber: response.customer_number,
      organisations: organisations
    };
  }
}

export const profileService = new ProfileService();
