import React from 'react';
import StatusButton from './StatusButton/StatusButton';
import { Status, IDVStatus } from '../ReportsList/TableStatus/types';
import Select from '../Select/Select';
import { useAppContext } from 'pvoi-ui/src/context';

interface Props {
  value: Status;
  onSelect: (value: string) => void;
}

const StatusDropdown: React.FC<Props> = ({ value, onSelect }): React.ReactElement => {
  const appState = useAppContext();
  const status = appState.isIDV ? IDVStatus : Status;
  const listItems = Object.keys(status).map(key => status[key]);

  return (
    <Select
      id="statusDropdown"
      renderButton={(buttonProps, isClicked) => <StatusButton buttonProps={buttonProps} isClicked={isClicked} />}
      width="142px"
      listItems={listItems}
      onSelect={onSelect}
      selectedValue={value}
    />
  );
};

export default StatusDropdown;
