import React from 'react';
import { Heading, Link } from '@auspost/pvoi-components';
import { Divider } from '@auspost/postmaster-react';
import * as Styled from './styled';
import { MetadataItem } from '../types';
import { BACK, BACK_ARIA_LABEL, DETAILS_HEADING } from '../constants';
import EditMetaData from '../../EditMetaData/EditMetaData';

interface Props {
  items: MetadataItem[];
  onBackButtonCallback?: () => void;
  onEditMetaData?: () => void;
  onSaveEditMetaData?: (data) => Promise<MetadataItem[]>;
  onCancelEditMetaData?: () => void;
  onCloseEditMetaData?: () => void;
}

export const Metadata: React.FC<Props> = ({
  items,
  onBackButtonCallback,
  onEditMetaData,
  onSaveEditMetaData,
  onCancelEditMetaData,
  onCloseEditMetaData
}): React.ReactElement => {
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const onClick = event => {
    event.preventDefault();
    onBackButtonCallback && onBackButtonCallback();
  };

  const onEditMetaDataButton = (): void => {
    onEditMetaData && onEditMetaData();
    setModalOpen(true);
  };

  const onCloseModal = (): void => {
    setModalOpen(false);
    buttonRef.current.focus();
  };

  const mapTextItems = (items: MetadataItem[]): React.ReactNode => {
    return items.map(item => {
      return (
        <Styled.ListItem key={item.id}>
          <Divider />
          <Styled.Label id={item.id + '-label'}>{item.label}</Styled.Label>
          <Styled.Value id={item.id + '-value'} aria-label={item.valueForScreenReader || item.value}>
            <span aria-hidden={true}>{item.value}</span>
          </Styled.Value>
        </Styled.ListItem>
      );
    });
  };

  return (
    <Styled.Container>
      <Link id="backToLink" href="" variant="back" onClick={onClick} aria-label={BACK_ARIA_LABEL}>
        {BACK}
      </Link>
      <Heading level="h4" as="h1">
        {DETAILS_HEADING}
      </Heading>
      <Styled.List>{mapTextItems(items)}</Styled.List>
      {onEditMetaData && (
        <>
          <Styled.EditMetadataButton
            id="editMetadataButton"
            variant="secondary"
            size="small"
            onClick={onEditMetaDataButton}
            type="button"
            ref={buttonRef}
          >
            Edit metadata
          </Styled.EditMetadataButton>
          <EditMetaData
            isModalOpen={isModalOpen}
            onCloseModal={onCloseModal}
            onSave={onSaveEditMetaData}
            items={items}
            onCancel={onCancelEditMetaData}
            onCloseButtonClick={onCloseEditMetaData}
          />
        </>
      )}
    </Styled.Container>
  );
};
