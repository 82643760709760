import styled from '@emotion/styled';
import { theme } from '../../theme/theme';

export const Input = styled.input<{ isInvalid: boolean }>(({ isInvalid }) => ({
  width: '100%',
  background: theme.color.white,
  border: `2px solid ${isInvalid ? theme.color.feedbackAa04 : theme.color.ash}`,
  boxSizing: 'border-box',
  borderRadius: '6px',
  padding: '10px 16px',
  height: '44px',
  fontSize: theme.fontSize.default,
  color: isInvalid ? theme.color.feedbackAa04 : theme.color.ink,
  lineHeight: '24px',
  fontFamily: theme.fontFamily.default,
  fontWeight: theme.fontWeight.regular,
  '&::placeholder': {
    color: theme.color.inkSubheading
  },
  '&:focus': {
    borderColor: isInvalid ? theme.color.feedbackAa04 : theme.color.slate,

    '&:invalid': {
      color: isInvalid ? theme.color.feedbackAa04 : 'inherit'
    }
  },
  '&:focus-visible': {
    outline: 'none'
  },
  '&:invalid': {
    color: isInvalid ? theme.color.feedbackAa04 : theme.color.inkSubheading
  }
}));
