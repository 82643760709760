import styled from '@emotion/styled';
import { theme } from '@auspost/pvoi-components';

export const Nav = styled.nav({
  minHeight: 'inherit',
  position: 'sticky',
  top: 0,
  h1: {
    fontWeight: theme.fontWeight.medium,
    fontFamily: theme.fontFamily.heading,
    letterSpacing: '0.8px',
    lineHeight: '26px',
    margin: `27px 0 20px ${theme.spacing.spaceM}`
  },

  hr: {
    marginLeft: theme.spacing.spaceM,
    boxShadow: theme.shadow.shadow1
  },

  // common styling for all links within the Navigation
  a: {
    lineHeight: '20px',
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 24px',
    outlineOffset: '0 !important'
  }
});

export const Menu = styled.ul({
  // minHeight: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  marginTop: '16px'
});

export const MenuItem = styled.li<{ isSelected?: boolean }>(({ isSelected }) => ({
  margin: 0,
  position: 'relative',
  listStyleType: 'none',
  width: '100%',
  a: {
    fontSize: theme.fontSize.default,
    fontWeight: isSelected ? theme.fontWeight.medium : theme.fontWeight.regular,
    color: isSelected ? theme.color.ink : theme.color.inkSecondary,
    borderLeft: `4px solid ${isSelected ? theme.color.primary : theme.color.white}`,
    backgroundColor: isSelected ? theme.color.cloudy : null,

    svg: {
      marginRight: '10px'
    },

    '&:hover': {
      backgroundColor: theme.color.shell,
      borderLeft: `4px solid ${isSelected ? theme.color.primary : theme.color.shell}`
    }
  },
  hr: {
    margin: `12px 0 12px ${theme.spacing.spaceM}`
  }
}));

export const LinkGroup = styled.div<{ isSelected: boolean }>(({ isSelected }) => ({
  // Having this LinkGroup just in case we have more links to place at the bottom of the menu
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  bottom: '32px',
  '@media (max-width:1199px)': {
    bottom: '90px'
  },
  width: '240px',
  a: {
    fontSize: theme.fontSize.small,
    fontWeight: isSelected ? theme.fontWeight.medium : theme.fontWeight.regular,
    color: isSelected ? theme.color.ink : theme.color.inkSecondary,
    borderLeft: `4px solid ${isSelected ? theme.color.primary : theme.color.white}`,
    backgroundColor: isSelected ? theme.color.cloudy : null,

    '&:hover': {
      backgroundColor: theme.color.shell,
      borderLeft: `4px solid ${isSelected ? theme.color.primary : theme.color.shell}`
    }
  }
}));
