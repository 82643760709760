import { EnvConfig } from './typed';

export const ptestEnvConfig: EnvConfig = {
  ui: {
    loginUrl: 'https://pvoi.identityservice-ptest.npe.auspost.com.au',
    authScriptUrl: '//ptest.npe.auspost.com.au/auth0-ui-integration-module/module.js',
    authWebConfig: {
      clientId: 'pUf5WPRObSCdcXfQZZo2PYh3xrLB0dJm',
      env: 'ptest',
      redirectUrl: 'https://pvoi.identityservice-ptest.npe.auspost.com.au',
      auth0SessionRequired: true,
      audience: 'https://digitalapi.auspost.com.au/high',
      scope: 'https://scopes.auspost.com.au/auth/voi/user',
      caller: 'PVOI',
      product: 'PVOI',
      channel: 'WEB',
      cssoSessionConfig: {
        skipCheck: true,
        skipExchange: true,
        skipRefresh: true
      },
      verifyAuthenticationOnFocus: true
    }
  }
};
