import styled from '@emotion/styled';
import { theme } from '../../theme/theme';

const triangleTip = {
  position: 'absolute',
  right: '30px',
  top: '-10px',
  width: 0,
  height: 0,
  content: '""',
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  borderBottom: `10px solid ${theme.color.white}`
};

export const Container = styled.div({
  position: 'relative',
  display: 'inline-block'
});

export const ListContainer = styled.ul<{ width: string; hasTriangleTip: boolean }>(({ width, hasTriangleTip }) => ({
  width: width,
  boxShadow: theme.shadow.shadow5,
  backgroundColor: theme.color.white,

  position: 'absolute',
  padding: '8px 0',
  marginTop: hasTriangleTip ? '16px' : '4px',
  listStyleType: 'none',
  zIndex: 1,
  borderRadius: '8px',
  whiteSpace: 'nowrap',
  right: hasTriangleTip ? '-10px' : 0,

  ':after': hasTriangleTip ? triangleTip : {}
}));

export const ListItem = styled.li({
  listStyleType: 'none',
  lineHeight: '20px',

  '&:hover': {
    backgroundColor: theme.color.shell
  }
});
