import { EditMetadataBody } from './types';
import { ReportStatus } from 'pvoi-ui/src/pages/ReportDetails/types';
import { Reports } from '../reportsBaseService/types';
import { ReportsBaseService } from '../reportsBaseService/reportsBaseService';
import { AxiosStatic } from 'axios';

export class ReportDetailsService extends ReportsBaseService {
  constructor(httpService: AxiosStatic) {
    super(httpService);
  }
  getReportDetails(clmId: string, reportId: string): Promise<Reports> {
    const headers = {
      clm_id: clmId,
      Accept: 'application/json'
    };

    return this.httpService
      .get(`${this.reportsEndpoint}/${reportId}`, {
        headers: headers
      })
      .then(res => this.mapReport(res.data.data))
      .catch(error => Promise.reject(error.response.data.errors));
  }

  getPdf(clmId: string, reportId: string, filename = ''): Promise<void | string> {
    const headers = {
      clm_id: clmId,
      Accept: 'application/pdf'
    };

    return this.httpService
      .get(`${this.reportsEndpoint}/${reportId}`, {
        headers: headers,
        responseType: 'arraybuffer'
      })
      .then(response => {
        return this.handleDownload(response, 'pdf', filename);
      })
      .catch(error => Promise.reject(error.response.data.errors));
  }

  // Add notes/flag/checked also uses this endpoint
  // Note: only agency support user is allowed to edit metadata
  patchReport(clmId: string, reportId: string, metadata?: EditMetadataBody): Promise<Reports> {
    const header = {
      clm_id: clmId
    };

    const body = metadata ?? {
      status: ReportStatus.VIEWED
    };

    return this.httpService
      .patch(`${this.reportsEndpoint}/${reportId}`, body, {
        headers: header
      })
      .then(res => this.mapReport(res.data.data))
      .catch(error => Promise.reject(error.response.data.errors));
  }
}
