import React from 'react';

const EditIcon: React.FC = (): React.ReactElement => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.291 5.54257L16.4577 3.70826L18.3194 1.84554L20.1536 3.67984L18.291 5.54257ZM4.8537 18.9795L4.11212 19.1518L2.84805 17.8877L3.02039 17.1461L15.1551 5.01089L16.9884 6.84519L4.8537 18.9795ZM21.4562 2.3543L19.6449 0.54383C18.9198 -0.181277 17.7419 -0.181277 17.0168 0.54383L1.34565 16.2166L0 22L5.78411 20.6543L21.4562 4.98247C22.1813 4.25736 22.1813 3.07941 21.4562 2.3543Z"
      fill="#31313D"
    />
  </svg>
);

export default EditIcon;
