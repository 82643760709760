import React from 'react';
import { Spinner as PostmasterSpinner } from '@auspost/postmaster-react';

interface Props {
  colour: 'primary' | 'secondary';
  variant: 'full-page' | 'inline';
}

const Spinner: React.FC<Props> = (props): React.ReactElement => <PostmasterSpinner {...props} />;

export default Spinner;
