import React from 'react';

interface Props {
  src: string;
  width?: string;
  height?: string;
}

const PdfViewer: React.FC<Props> = ({ src, width = '100%', height = '100%' }): React.ReactElement => {
  return (
    <object data={src} type="application/pdf" width={width} height={height}>
      <embed src={src} type="application/pdf" width={width} height={height} />
    </object>
  );
};

export default PdfViewer;
