import React from 'react';
import styled from '@emotion/styled';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip as MuiTooltip } from '@mui/material';
import { theme } from '../../theme/theme';

interface Props {
  id: string;
  title: string;
  children: React.ReactElement;
}

const Tooltip: React.FC<Props> = ({ id, title, children }) => {
  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} arrow placement="top-end" classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadow.shadow5,
      backgroundColor: theme.color.white,
      borderRadius: '6px',
      padding: '16px',
      color: theme.color.ink,
      fontSize: theme.fontSize.small
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.color.white
    }
  }));

  return (
    <StyledTooltip id={id} title={title}>
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
