import React from 'react';
import * as Styled from './styled';

interface Props {
  isExternal?: boolean;
}

const LinkNoStyles: React.FC<Props & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  isExternal,
  ...props
}): React.ReactElement => {
  return (
    <Styled.LinkNoStyles {...props} isExternal={isExternal}>
      {children}
    </Styled.LinkNoStyles>
  );
};

export default LinkNoStyles;
