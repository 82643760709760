import styled from '@emotion/styled';
import { Input } from '@auspost/postmaster-react';
import { theme } from '../../theme/theme';

export const StyledInput = styled(Input)({
  appearance: 'none',
  border: `2px solid ${theme.color.ink}`,
  borderRadius: '50%',
  width: '18px',
  height: '18px',
  padding: '0',

  '&&:before': {
    content: '""',
    display: 'block',
    width: '8px',
    height: '8px',
    margin: 'auto',
    borderRadius: '50%',
    position: 'relative',
    top: '3px'
  },

  '&&:checked:before': {
    background: theme.color.ink
  }
});
